<template>
  <div id="app">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      navData: [
        {
          id: 0,
          name: "首页",
          englishTitle: "HOME"
        }, {
          id: 1,
          name: "产品",
          englishTitle: "PRODUCT"
        }, {
          id: 2,
          name: "动态",
          englishTitle: "DYNAMIC"
        }, {
          id: 3,
          name: "关于",
          englishTitle: "ABOUT"
        }
      ],
      activeFont: 0
    }
  },
  methods: {
    toPage(event) {
      sessionStorage.setItem("navBar", event);
      this.activeFont = event;
      if (event == 0) {
        this.$router.push('/')
      } else if (event == 1) {
        this.$router.push('/product')
      } else if (event == 2) {
        this.$router.push('/dynamic')
      } else if (event == 3) {
        this.$router.push('/about')
      }
    }
  },
  created() {
  },
  mounted() {
  }
}
</script>
<style scoped lang="less">
#app {
  font-family: "songti";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #F9F9F9;
  overflow-x: hidden;
}

#active-font-wrap {
  color: #C2A16C;
}

//移动端
@media only screen and (max-width: 767px) {
  
}

// .headerTop{
//   height:158px; 
//   display:flex;
//   background: #F9F9F9;
//   align-items: center;
//   justify-content: center;
//   background: url("@/assets/img/headerBg.png") no-repeat;
//   background-size:100%;
//   .header-wrap{
//     width:1200px;
//     display:flex;
//     justify-content: space-between;
//     align-items:center;
//     .logo{
//       width:100px; 
//       height:100px;
//     }
//     .words-wrap{
//       flex:1;
//       // height:70px;
//       display: flex;
//       justify-content: space-between;
//     }
//     .blank-box{
//       width:100px;
//     }
//   }
//   .font-wrap{
//     display:flex;
//     color:#676767;
//     cursor:pointer;
//     .header-font{
//       font-size:24px; 
//       writing-mode: vertical-lr;
//       text-align: center;
//       // text-align: justify;
//       // text-align-last: justify;
//       // text-justify:distribute-all-lines; //兼容ie
//     }
//     .english{
//       font-size:16px;
//     }
//     .header-font-border{
//       border-right:1px solid #676767;
//       padding-right:3px;
//       margin-right:2px;
//     }
//     #active-header-font-border{
//       border-right:1px solid #C2A16C;
//     }
//     .hide-class{
//       display:none;
//     }
//   } 
// }
.footerTop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #3F3F3F;
  background-image: url("@/assets/img/footerBg.png");

  .footer-wrap:last-child {
    justify-content: center;
  }

  .footer-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    color: #fff;
    padding: 30px 14px;
    box-sizing: border-box;

    img {
      display: block;
      width: 121px;
      height: 53px;
      margin: 0 auto;
    }

    .code-wrap {
      display: block;
      width: 149px;
      height: 149px;
      background: yellow;
    }

    .words {
      margin-top: 20px;
    }

    .number {
      font-size: 24px;
    }

    .service-words {
      width: 100%;
      margin-top: 27px;

      .row-font {
        display: flex;
        justify-content: space-between;

        span:first-child {
          margin: 0 35px 20px 0;
        }
      }

      span {
        display: block;
      }
    }
  }

  .share-wrap {
    width: 100%;
    height: 120px;
    padding-right: 190px;
    background: #212020;
    display: flex;
    align-items: center;
    justify-content: right;

    img {
      display: block;
      width: 50px;
      height: 50px;
      background: red;
      margin-right: 30px;
    }

    img:last-child {
      margin-right: 0px;
    }
  }
}

// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  .headerTop .header-wrap .blank-box {
    width: 20px;
  }
}

// 手机端
@media only screen and (max-width: 767px) {
  .header-wrap {
    padding: 0 14px;
    box-sizing: border-box;
  }

  .headerTop .logo {
    width: 50px;
    height: 50px;

  }

  .headerTop .header-wrap .blank-box {
    width: 20px;
  }

  .headerTop .font-wrap .header-font {
    font-size: 16px;
    text-align: center;
  }

  .footerTop .share-wrap {
    padding-right: 0;
    justify-content: center;
  }
}
</style>
