<template>
  <div>
    <div class="footerTop">
      <div class="footer-wrap">
        <img src="../../assets/img/footerLogo.png" />
        <div class="words number">北京真源诚传科技有限责任公司</div>
        <div class="words"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> 备案号
            京ICP备2022001000号-1</a></div>
      </div>
      <div class="footer-wrap" style="width:280px;">
        <div class="words number">
          <b-icon icon="telephone"></b-icon>
          联系我们
        </div>
        <div class="words">
          <b-icon icon="geo-alt"></b-icon>
          北京市大兴区瀛海镇瀛坤路4号院1号楼一层105号114室
        </div>
      </div>
      <div class="footer-wrap">
        <div class="words number">产品与服务</div>
        <div class="service-words">
          <div class="row-font">
            <span>在线云医</span>
            <span>知悟商城</span>
          </div>
          <div class="row-font">
            <span>传统国学</span>
            <span>文化学习</span>
          </div>
          <div class="row-font">
            <span>会员旅行</span>
            <span>艺术鉴赏</span>
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <div class="words number">关于我们</div>
        <div class="service-words">
          <div class="row-font">
            <span>企业简介</span>
          </div>
          <div class="row-font">
            <span>企业动态</span>
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <img class="code-wrap" src="../../assets/img/code.png" />
        <div class="words" style="align-self: center;">知悟官方服务号</div>
      </div>
      <!-- <div class="share-wrap">
        <img src=""/>
        <img src=""/>
        <img src=""/>
        <img src=""/>
      </div> -->
    </div>
    <div class="phoneH5">
      <div class="phone-title">
        <h3>产品与服务</h3>
        <ul style="justify-content: space-between;">
          <li v-for="(item, index) in titleData" :key="index">{{ item.name }}</li>
        </ul>
      </div>
      <div class="phone-title" style="padding-bottom: 20px!important">
        <h3>关于我们</h3>
        <ul style="justify-content: start;">
          <li style="padding-right: 28px;" v-for="(itemAbout, indexAbout) in titleData2" :key="indexAbout">
            {{ itemAbout.name }}</li>
        </ul>
      </div>

      <div class="phone-title" style="border-top: 1px solid #555555; padding-top: 20px">
        <h3>联系我们</h3>
        <p>400-105-7698</p>
        <ul style="justify-content: start;">
          <li>北京市大兴区瀛海镇瀛坤路4号院1号楼一层105号114室</li>
        </ul>
      </div>
      <div class="pic">
        <div class="pic-left">
          <img src="../../assets/img/code.png" />
          <div class="pic-words" style="align-self: center;">知悟官方服务号</div>
        </div>
        <!-- logo -->
        <div class="pic-logo">
          <img src="../../assets/img/footerLogo.png" />
        </div>

      </div>
      <div class="py">
        <span>北京真源诚传责任有限公司</span>
        <span>备案号 京ICP备2022001000号-1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCom',
  components: {
  },
  data() {
    return {
      titleData: [
        {
          name: '在线云医',
        },
        {
          name: '知悟商城',
        },
        {
          name: '传统国学',
        },
        {
          name: '文化学习',
        },
        {
          name: '会员旅行',
        },
      ],
      titleData2: [
        {
          name: '企业简介'
        }, {
          name: '企业动态'
        },
      ],
    }
  },
  methods: {
    // goService(){
    //   let name = this.$route.name;
    //   if(name != 'index'){
    //     this.$router.push("/")
    //   }
    // },
  }
}
</script>
<style lang="less">
.phoneH5 {
  display: none;
}

.footerTop {
  width: 100%;
  // height:310px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #3F3F3F;

  .footer-wrap:last-child {
    justify-content: center;
  }

  .footer-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    color: #fff;
    padding: 30px 14px;
    box-sizing: border-box;

    img {
      display: block;
      width: 121px;
      height: 53px;
      margin: 0 auto;
    }

    .code-wrap {
      display: block;
      width: 149px;
      height: 149px;
      background: yellow;
    }

    .words {
      margin-top: 20px;
      cursor: default;

      a {
        color: white;
      }

      a:hover {
        text-decoration: none;
        color: #999;
      }
    }

    .number {
      font-size: 24px;
    }

    .service-words {
      width: 100%;
      margin-top: 27px;

      .row-font {
        display: flex;
        justify-content: space-between;

        span:first-child {
          margin: 0 35px 20px 0;
          cursor: default;
        }
      }

      span {
        display: block;
      }
    }
  }

  .share-wrap {
    width: 100%;
    height: 120px;
    padding-right: 190px;
    background: #212020;
    display: flex;
    align-items: center;
    justify-content: right;

    img {
      display: block;
      width: 50px;
      height: 50px;
      background: red;
      margin-right: 30px;
    }

    img:last-child {
      margin-right: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footerTop {
    display: none;
  }

  .phoneH5 {
    width: 100%;
    padding: 30px 0 0;
    background: #3F3F3F;
    display: flex;
    flex-direction: column;

    .phone-title {
      width: 92%;
      padding: 0 4% 30px 4%;

      p {
        color: #FFFFFF;
        font-size: 12px;
        margin-bottom: 5px;
      }

      h3 {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
      }

      ul {
        display: flex;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;

        li {
          color: #A5A5A5;
          font-size: 12px;
          list-style: none;
        }
      }
    }

    .pic {
      width: 92%;
      padding: 0 4% 30px 4%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .pic-left {
        img {
          width: 75px;
        }

        .pic-words {
          color: #FFFFFF;
          font-size: 12px;
          margin-top: 10px;
        }
      }

      .pic-logo {
        width: 73px;

        img {
          width: 100%;
        }
      }
    }

    .py {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #A5A5A5;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.34);
    }
  }
}
</style>