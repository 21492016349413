<template>
    <div id="function-phone-intro-wrap">
        <div class="fun-img"></div>
        <!-- <div class="fun-img2"></div> -->
        <!-- 手机展示介绍区域 手机在左边-->
        <div class="phone-show-intro-wrap">
            <div class="img-box">
                <img src="@/assets/img/product/phoneintro2.png" alt="" />
            </div>
            <div class="description-box">
                <div class="title wow animate__zoomIn" data-wow-delay="500" data-wow-duration="0.8s">解惑答疑零距离</div>
                <div class="line wow animate__zoomIn" data-wow-delay="1000" data-wow-duration="1.2s"></div>
                <div class="description wow animate__zoomIn" data-wow-delay="1500" data-wow-duration="1.8s">
                    ·用户订单智能推送，打通线上沟通闭环，线上服务秒触达 <br />·双平台订单交互系统，订单状态实时追踪，零距离交流互动<br />·语音视频高效咨询，提升服务信任感，沟通交互无界限
                </div>
            </div>
        </div>

        <!-- 手机展示介绍区域 手机在右边-->
        <div class="phone-show-intro-wrap" style="margin-top:18px">
            <div class="description-box">
                <div class="title wow animate__zoomIn" data-wow-delay="500" data-wow-duration="0.8s">大师形象更立体</div>
                <div class="line wow animate__zoomIn" data-wow-delay="1000" data-wow-duration="1.2s"></div>
                <div class="description wow animate__zoomIn" data-wow-delay="15000" data-wow-duration="1.8s">
                    ·个人动态随心发布，社交动态实时更新<br /> ·互动热度持续攀升，快速建立私域粉丝流量<br /> ·提升专业形象，树立个人优质口碑
                </div>
            </div>

            <div class="img-box">
                <img class="img-box-right" src="@/assets/img/product/phoneintro1.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    name: "FunctionIntroTwo",
    components: {

    },
    data() {
        return {

        }
    },
    methods: {
        showMaskContent(index) {
            this.funImgData[index].flag = true;
        },
        hideMaskContent(index) {
            this.funImgData[index].flag = false;
        }
    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow", ///动画元件css类（默认为wow）
                animateClass: "animated", //动画css类（默认为animated）
                offset: 100, //到元素距离触发动画（当默认为0）
                mobile: true, //在移动设备上触发动画（默认为true）
                live: false, //对异步加载的内容进行操作（默认为true）
            })
            wow.init()
        })
    },
}
</script>

<style scoped lang="less">
#function-phone-intro-wrap {
    width: 100%;
    background: #fff;
    // overflow:hidden;
    position: relative;
    padding-bottom: 106px;
    box-sizing: border-box;

    .fun-img {
        position: absolute;
        width: 604px;
        height: 606px;
        opacity: 0.3;
        background: url("@/assets/img/product/funMoutainBg.png") no-repeat center 0;
        z-index: 1;
    }
}

.phone-show-intro-wrap {
    width: 1300px;
    background: #fff;
    border: 2px solid #F7F7F7;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .img-box {
        width: 748px;
        height: 502px;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 100;

        img {
            display: block;
            width: 748px;
            height: 502px;
        }
    }

    .img-box .img-right {
        width: 468px;
        height: 355px;
    }

    .description-box {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        background: #FFFEFF;
        padding-left: 84px;
        box-sizing: border-box;
        z-index: 100;

        .title {
            font-size: 28px;
            color: #C2A16C;
        }

        .line {
            width: 37px;
            height: 6px;
            background: #C2A16C;
            margin: 19px 0 54px;
        }

        .description {
            color: #666666;
            font-size: 16px;
        }
    }
}

// 手机端
@media only screen and (max-width: 768px) {
    #function-phone-intro-wrap {
        padding-bottom: 0 !important;
    }



    .fun-img {
        display: none;
    }

    .phone-show-intro-wrap:nth-child(3) {
        flex-direction: column-reverse !important;
    }

    .phone-show-intro-wrap {
        width: 92%;
        height: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid #C8AA7A !important;

        .intro-box {
            width: 100%;
        }

        .img-box {

            width: 100%;
            height: auto;
            margin-top: 30px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .description-box {

        width: 100%;
        margin: 0 auto;
        background: #C8AA7A !important;
        color: white !important;
        padding-left: 0 !important;
        padding: 40px 15px !important;

        .title {
            color: white !important;
            font-size: 16px !important;
        }

        .line {
            background: white !important;
            margin: 10px 0 20px !important;
        }

        .description {
            width: 100%;
            white-space: nowrap;
            color: white !important;
            font-size: 12px !important;
        }
    }

    .splash-box {
        width: 100%;
    }
}
</style>