import { render, staticRenderFns } from "./DevelopmentHistory.vue?vue&type=template&id=6c32b601&scoped=true&"
import script from "./DevelopmentHistory.vue?vue&type=script&lang=js&"
export * from "./DevelopmentHistory.vue?vue&type=script&lang=js&"
import style0 from "./DevelopmentHistory.vue?vue&type=style&index=0&id=6c32b601&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c32b601",
  null
  
)

export default component.exports