<template>
  <div class="honorsAndPositionsDetail">
    <h2>荣誉和职务</h2>
    <p>获得荣誉</p>
    <div>
      <p>2020年6月，受邀担任北大博雅-元培商学院国学研究课题客座教授。</p>
      <p>
        2020年10月，受聘为中国管理科学研究院教育标准化研究所客座教授（国学智慧）。
      </p>
      <p>
        2022年1月，中国智慧产业发展研究院管委会任命为中国智慧产业发展研究院专家委员会（副院级）专家委员/研究院。
      </p>
      <p>2022年12月，获得北大博雅教育研究院颁发高级创业指导师。</p>
      <p>2022年12月，受仙居县道教协会聘为协会副会长。</p>
      <p>2022年12月，受聘为中国道教十大洞天凝真宫养生院院长。</p>
      <p>
        2023年1月，受中非共和国驻华大使馆聘为中非共和国驻华大使馆特邀文化顾问。
      </p>
      <p>
        2023年1月，中国管理科学学会培训中心评定“道医养生师”优秀学员，入选专业人才库。
      </p>
      <p>2023年3月，获得中华人民共和国国学文化国家一级服务资质。</p>
      <p>2023年4月，获中国书法院院士，行书副主任（一级书法师）。</p>
      <p>担任职务：</p>
      <p>主要职务：知悟创始人</p>
      <p>
        社会职务：深研传统文化领域多年，何教授通过自己的博学与德行，先后被社会及知名机构邀请担任众多职务：
      </p>
      <p>中国关心下一代体育健康基金会·传统文化教育基金秘书长</p>
      <p>北大博雅元培智库专家</p>
      <p>
        中国管理科学研究院教育标准化研究所中国传统国学与互联网应用课题组组长
      </p>
      <p>中国管理科学研究院教育标准化研究所客座教授</p>
      <p>中国智慧产业发展研究院专家（副院级）</p>
      <p>国际玄道养生研究院副院长</p>
      <p>中非共和国驻华大使馆特邀国学文化顾问</p>
      <p>凝真宫十大洞天养生院、堪舆院院长</p>
      <p>
        此外，何志强教授还在多家上市公司担任持股顾问，首席战略执行官等要职。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    document.body.scrollTop = document.documentElement.scrollTop = this.scrollTop;
  },
}
</script>

<style lang='less' scoped>
.honorsAndPositionsDetail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  h6 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    line-height: 30px;
  }
}
</style>