<template>
  <div class="dynamic-detail-wrapper">
    <div class="detail-wrapper">
        <div @click="backList" class="nav-wrapper"> 
            <img src="@/assets/img/dynamic/detailArrow.png"/>
            <div class="main">知悟动态/</div>
            <div class="tips">{{detailData.title}}</div>
        </div>
        <div class="title">{{detailData.excerpt}}</div>
        <div class="second-tips">
            <span style="color:#184A8E;">生活指南知悟</span>
            <span style="margin-left:10px;color:#666666">{{detailData.updateAt}}</span>
        </div>
        
        <div class="content-wrapper">
            <div class="detail" v-html="detailData.content"></div>
            <!-- <div v-for="(item,index) in itemList.detailList" :key="index">
                <div v-if="item.title" style="margin-bottom:16px;font-weight:bold;">{{item.title}}</div>
                <p style="text-align:justify;white-space: pre-wrap;" v-for="(e,index1) in item.detail" :key="index1">{{e.content}}</p>
                <div class="img-box" v-if="item.img">
                    <img class="content-img" :src="item.img"/>
                </div>
            </div> -->
        </div>
    </div>


    <!-- 一些背景图 -->
    <div class="bird-bg"></div>
    <div class="boat-bg"></div>
    <div class="mountain-bg"></div>
  </div>
</template>
<script>
import { getdata } from '@/api/getapi.js'
export default {
  name: "DynamicDetail",
  components: {
    
  },
  data() {
    return {
        detailData:null,
        itemList:null,
        id: '',
    };
  },
  created () {
    // const detail = JSON.parse(sessionStorage.getItem("zhiWuDetail"));
    // this.itemList = detail;
    // console.log(this.itemList.detailList);
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    //返回上一页
    backList(){
        this.$router.back();
    },
    currentTime() { //当前日期
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mytime=date.toLocaleTimeString();     //获取当前时间
      // myDate.toLocaleString( );
      var time = year + "-" + month + "-" + day+' '+mytime;
      return time;
    },
        // 分类详情
    getDetail() {
      getdata(this.$url + 'system/dynamic/detail', {
        id: this.id,
      }).then((ok) => {
        this.detailData = ok.data.data
        console.log(this.detailData)
      })
    },
  },
  mounted () {
    this.$refs.detail.scrollIntoView({behavior: 'smooth'});
  }
};
</script>

<style scoped lang="less">
.dynamic-detail-wrapper{
    width:100%;
    background:#F9F9F9;
    position:relative;
    z-index:1;
    .bird-bg{
        width:306px;
        height:260px;
        position: absolute;
        top:207px;
        z-index:0;
        background:url("@/assets/img/bird.png") no-repeat center 0;
    }
    .boat-bg{
        width:375px;
        height:267px;
        position: absolute;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        background:url("@/assets/img/boat.png") no-repeat center 0;
        z-index:-1;
    }
    .mountain-bg{
        width:606px;
        height:606px;
        position: absolute;
        left:0;
        bottom:200px;
        background:url("@/assets/img/mountain.png") no-repeat center 0;
        z-index:-1;
    }
    .detail-wrapper{
        width:1000px;
        // width:1200px;
        background:#fff;
        margin:0 auto;
        // padding: 40px 159px 114px 159px;
        padding: 40px 100px 114px 100px;
        box-sizing:border-box;
    }
    .nav-wrapper{
        display:flex;
        align-items: center;
        color:#54462F;
        img{
            display:block;
            width:12px;
            height:20px;
            margin-right:18px;
        }
        .main{
            font-size:24px;
            margin-right:9px;
            width:110px;
        }
        .tips{
            font-size:18px;
        }
    }
    .title{
        font-size:22px;
        color:#333;
        margin:40px 0 18px 0;
        font-weight:bold;
    }
    .second-tips{
        font-size:14px;
        color:#333;
        margin-bottom:37px;
    }
    .content-wrapper{
         ::v-deep .detail p{
            text-align: center;
         }
       ::v-deep .detail p img{
            width: 80%;
            margin: 0 auto;
        }
        p{
            text-align:justify;
        }
        .img-box{
            display:flex;
            justify-content: center;
        }
        .content-img{
            width:100%;
            // height:385px;
            height:auto;
            margin:30px auto 22px ;
        }
        
    }
}
// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
    .dynamic-detail-wrapper .detail-wrapper{
        width:100%;
    }
    .dynamic-detail-wrapper{
        .content-wrapper .content-img{
            width:100%;
        }
    } 
}
// 手机端
@media only screen and (max-width: 767px) {
    .dynamic-detail-wrapper{
        .detail-wrapper{
            width:100%;
            margin:0 auto;
            padding:20px;
            box-sizing:border-box;
        }
        .content-wrapper .content-img{
            width:100%;
        }
        .nav-wrapper{
            .main{
                font-size:14px;
            }
            .tips{
                font-size:14px;
            }
        }
    } 
    .mountain-bg{
        // display:none;
    }
}
::v-deep .content-img{
  width: 100%;
}
</style>
