<template>
  <div class="characterDeeds">
    <h2>人物事迹</h2>
    <div>
     <p>姚婷的事业起源于医学美容，但她的视野并未局限于此，她在多个领域中展现了出众的才华和魅力。她始终认为，只有打破行业界限，才能拥有更广阔的天地。姚婷现身兼多重身份，包括：中国智慧产业发展研究院传统文化研究中心研究员、诸夏周易研究院中华面部风水企划师、传统文化专项基金副主任、中国美业协会理事、全通布道文化复兴产业联盟副主席、中国管理科学研究院客座教授、中非共和国驻华大使馆国学文化顾问秘书长等。这些经历证明了她的跨行业转型和跨界创业的成功，同时也表明了她在行业中引领潮流的地位。</p>
     <p>随着社会的发展，人们的生活水平不断提高，健康意识不断增强，对健康产品的需求也与日俱增。同时，随着互联网的普及，人们的消费方式也发生了很大的变化，新零售成为了一种最具潜力的新兴商业模式。在这样的背景下，姚婷以其敏锐的商业嗅觉和前瞻性的视野，积极布局大健康产业，以实现她的人生追求和价值。</p>
     <p>在姚婷的带领下，知悟大健康·灵玥康体系统于2023年正式成立。这个系统以健康为核心，致力于为人们提供全方位的健康服务。在短短一周的时间内，姚婷便招募了数百名股东、合伙人，创造了单人一周1000万的业绩。这样的成就不仅证明了姚婷的商业才能和领导力，也表明了大健康产业在未来有着巨大的发展潜力和市场前景。</p>
     <p>除了在商业领域的成就之外，姚婷还关注公益事业，积极参与社会公益活动和慈善捐赠。她深知社会责任的重要性，始终坚持回馈社会、服务社会的理念。她多次组织志愿者团队前往贫困地区开展义诊和捐赠活动。</p>
     <p>姚婷还以善为本，不仅为自己创造了财富自由，更是心系国家、关注社会，用自己的智慧和行动，推动着中国传统文化的复兴。她一直以来都是以善为本的，她不仅为自己创造了财富自由，更是心系国家、关注社会，用自己的智慧和行动，推动着中国传统文化的复兴。她的成就和贡献不仅在美业行业让人瞩目，更是在整个社会中起到了积极的引领作用。</p>
    
    </div>
  </div>
</template>

<script>
export default {
   created(){
    document.body.scrollTop = document.documentElement.scrollTop = this.scrollTop;
  },
}
</script>

<style lang='less' scoped>
.characterDeeds {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2{
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    font-size: 24px;
    font-weight: bold;
  }
  p{
    line-height: 30px;
  }
}
</style>