<template>
  <div id="enterprise-honor-wrap">
    <div class="enterprise-honor-wrap">
      <div class="title-wrap">
        <img class="title-img" src="@/assets/img/four.png" />
        <div class="title">企业荣誉</div>
        <div class="english">Enterprise Honor</div>
      </div>
      <div class="carousel-box">
        <div class="carousel wow fadeInUp" data-wow-delay="1000" data-wow-duration="2s">
          <VueSlickCarousel v-bind="settings">
            <div class="item-box" v-for="(item, index) in bannerList" :key="index">
              <div class="item-box-small" :style="(index == 1 || index == 7 || index == 11) ? 'width:340px' : ''">
                <img :style="(index == 1 || index == 7 || index == 11) ? 'width:330px' : ''" :src="item.src" />
              </div>
              <div :style="(index == 1 || index == 7 || index == 11) ? 'width:330px' : ''" class="title">{{ item.title
              }}</div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import honor1 from "@/assets/img/about/honor/honor1.png";
import honor2 from "@/assets/img/about/honor/honor2.png";
import honor3 from "@/assets/img/about/honor/honor3.png";
import honor4 from "@/assets/img/about/honor/honor4.png";
import honor5 from "@/assets/img/about/honor/honor5.png";
import honor6 from "@/assets/img/about/honor/honor6.png";
import honor7 from "@/assets/img/about/honor/honor7.png";
import honor8 from "@/assets/img/about/honor/honor8.png";
import honor9 from "@/assets/img/about/honor/honor9.png";
import honor10 from "@/assets/img/about/honor/honor10.png";
import honor11 from "@/assets/img/about/honor/honor11.png";
import honor12 from "@/assets/img/about/honor/honor12.png";
import honor13 from "@/assets/img/about/honor/honor13.png";
import honor14 from "@/assets/img/about/honor/honor14.png";
import honor15 from "@/assets/img/about/honor/honor15.png";
export default {
  name: "EnterpriseHonor",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 5,
        // centerMode: true,
        // centerPadding: '20px',
        // focusOnSelect: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        pauseOnHover: true,
        touchMove: true,
        variableWidth: true,
        responsive: [{
          "breakpoint": 1024,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 1,
            "infinite": true,
            "dots": true,
            "arrows": false,
          }
        },
        {
          "breakpoint": 600,
          "settings": {
            "slidesToShow": 3,
            "slidesToScroll": 1,
            "initialSlide": 2,
            "arrows": false,
          }
        },
        {
          "breakpoint": 480,
          "settings": {
            "dots": true,
            "arrows": false,
            "slidesToShow": 1,
            "slidesToScroll": 1
          }
        }
        ]
      },
      bannerList: [
        {
          id: 1,
          title: "中国关心下一代健康体育基金会红头文件",
          src: honor14
        }, {
          id: 2,
          title: "信用评级",
          src: honor2
        }, {
          id: 3,
          title: "中国管理科学研究院课题证书",
          src: honor6
        }, {
          id: 4,
          title: "资信等级证书",
          src: honor4
        }, {
          id: 5,
          title: "诸夏周易文化研究院战略合作",
          src: honor5
        }, {
          id: 6,
          title: "知悟软著",
          src: honor3
        }, {
          id: 7,
          title: "中国校园健康行动领导小组办公室红头文件",
          src: honor7
        }, {
          id: 8,
          title: "广电许可证",
          src: honor8
        }, {
          id: 9,
          title: "诚信供应商证书",
          src: honor9
        }, {
          id: 10,
          title: "重服务守信用证书",
          src: honor10
        }, {
          id: 11,
          title: "企业信用等级书",
          src: honor11
        }, {
          id: 12,
          title: "中国校园健康行动·传统文化知悟教育实践基地",
          src: honor12
        }, {
          id: 13,
          title: "诚信企业家证书",
          src: honor13
        }, {
          id: 14,
          title: "电信增值",
          src: honor1
        }, {
          id: 15,
          title: "北大博雅教育研究院",
          src: honor15
        }
      ],
    };
  },
  methods: {

  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 200, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: false, //对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  }
};
</script>
<style scoped lang="less">
::v-deep .slick-dots {
  bottom: -66px;
}

::v-deep .slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #57462B !important;
  // opacity: 0.2;

}

::v-deep .slick-next:before {
  font-size: 30px !important;
  color: #57462B !important;
}

::v-deep .slick-prev {
  top: 130px;
  left: -85px;
}

::v-deep .slick-next {
  top: 125px;
  right: -80px;
}

.enterprise-honor-wrap {
  width: 100%;
  background: #F5F5F5;
  padding: 90px 0 52px;
  box-sizing: border-box;

  .carousel-box {
    width: 100%;
    margin-top: 57px;

    .carousel {
      max-width: 1200px;
      margin: 0 auto;
      padding-bottom: 60px;
      box-sizing: border-box;

      .item-box {
        width: 190px;
        padding: 15px 10px 0px;
        box-sizing: border-box;

        .title {
          width: 180px;
          text-align: center;
          margin-top: 20px;
        }

        .item-box-small {
          width: 190px;
          height: 250px;
          background: #FFF;
          // background:red;
          box-shadow: 3px 3px 3px 1px rgba(167, 167, 167, 0.2000);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        img {
          display: block;
          width: 180px;
          height: 240px;
        }
      }

      .big-box {
        width: 340px;
      }
    }
  }

}

// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  .enterprise-honor-wrap .carousel {
    padding: 0 20px;
    box-sizing: border-box;
  }
}

// 手机端
@media only screen and (max-width: 767px) {
  .enterprise-honor-wrap .carousel {
    padding: 0 10px;
    box-sizing: border-box;
  }

  ::v-deep .slick-dots li button {
    width: 10px;
    height: 10px;
  }

  ::v-deep .slick-dots li button:before {
    line-height: 0;
    width: 10px;
    height: 10px;
  }

  ::v-deep .slick-dots {
    li {
      width: 10px;
      height: 10px;
    }
  }
}
</style>