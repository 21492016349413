<template>
  <div class="about-wrapper">
    <div class="banner-wrap">
      <img src="@/assets/img/about/banner.png" />
    </div>
    <div class="banner-wrap2">
      <img src="@/assets/img/mobBanner2.jpg" />
    </div>
    <!-- first 公司介绍 -->
    <div class="about-content-wrapper">
      <!-- first 公司介绍 -->
      <div class="title-wrap">
        <img class="title-img" src="@/assets/img/one.png" />
        <div class="title">公司介绍</div>
        <div class="english">Company Introduction</div>
        <img class="bg-img" src="@/assets/img/bird.png" />
      </div>
      <div class="company-list">
        <div
          class="company-introduction-wrap wow fadeInUp"
          data-wow-delay="800"
          data-wow-duration="1s"
        >
          <img src="@/assets/img/about/cpmpanyIntro.png" alt="" />
          <div class="company-introduction">
            <div class="title">北京真源诚传科技有限责任公司</div>
            <div class="detail">
              北京真源诚传科技有限责任公司，是一家集技术推广服务、计算机系统服务、软件开发、网络文化经营、组织文化艺术交流活动、广播电视节目制作经营、增值电信业务等于一体的企业。2021年，北京真源诚传科技有限责任公司，申报的《传统国学与互联网应用课题》被列为国家“十四五”规划重点课题，顺应时代变化，用互联网思维赋予中华传统文化以新的时代内涵，使传统文化在新时代迸发出新的生命力。
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div
          class="arrow-wrap wow fadeInUp"
          data-wow-delay="1500"
          data-wow-duration="1.5s"
        >
          <div></div>
          <span style="color: #c2a16c">六心文化</span>
        </div>
        <div class="intro-list-wrap">
          <div
            class="list wow animate__zoomIn"
            data-wow-delay="1000"
            data-wow-duration="2s"
            v-for="item in culturalData"
            :key="item.id"
          >
            <div class="words">&nbsp;{{ item.leftWords }}</div>
            <div class="words">{{ item.rightWords }}</div>
            <div class="img-bg"></div>
            <img class="words-img" :src="item.title" :alt="item.alt" />
          </div>
        </div>
      </div>
    </div>
    <!-- second 品牌故事 -->
    <div id="brand-story">
      <div class="title-wrap" style="margin: 80px 0 60px">
        <img class="title-img" src="@/assets/img/two.png" />
        <div class="title">品牌故事</div>
        <div class="english">Brand Story</div>
      </div>
      <div class="brand-story-wrap">
        <img class="bg-img" src="@/assets/img/about/aboutMountain.png" />
        <div class="content-wrap">
          <div class="words-content">
            <div
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="500"
              data-wow-duration="0.5s"
              style="margin-bottom: 30px; font-size: 22px; color: #54462f"
            >
              【品牌简介】
            </div>
            <p
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="1000"
              data-wow-duration="1s"
            >
              中国传统文化这座宝库蕴含着无穷的宝藏和力量，等待着我们的挖掘和阐发。近年来，我国科技硬实力的强化和提升，也为中国传统文化增添了新的表现形式、丰富了表现手段，科技的发展让我们看到了传统文化传播的多种可能性。
            </p>
            <p
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="1500"
              data-wow-duration="1.5s"
            >
              2017年何志强教授联合同频人士搭建理念框架，组建技术团队，期间通过“易学文化空中课堂”“轻模式文化发展目标”等传统文化传承项目累积沉淀成功经验。历经5年时间，于22年推出「知悟」APP，业务覆盖文化、教育、医疗、旅游、购物等各个生活场景。知悟APP利用“互联网+”的全方位交融，打造了一个服务智慧生活的平台，融合传统文化与时代科技，开拓新生活理念生态圈，以中国传统文化为产品内核，以科技为媒介结合生活场景，用传统文化智慧结晶覆盖服务中国人衣食住行的全维度。
            </p>
          </div>
          <div
            class="img wow fadeInUp"
            data-wow-delay="1500"
            data-wow-duration="1.5s"
          >
            <img src="@/assets/img/about/brand-story-hand.png" alt="" />
          </div>
        </div>
      </div>
      <!-- second brand story -->
      <div class="brand-story-wrap">
        <img class="bg-img" src="@/assets/img/about/aboutMountain2.png" />
        <div class="content-wrap">
          <div
            class="img wow fadeInUp"
            data-wow-delay="1500"
            data-wow-duration="1.5s"
          >
            <img src="@/assets/img/about/brand-story-people.png" alt="" />
            <!-- 遮罩上的内容 -->
            <div class="mask-box">
              <div class="description-box">
                <span>·国际玄道养生研究院副院长</span>
                <span
                  >·中国管理科学研究院教育标准化研究所中国传统国学与互联网应用课题组组长</span
                >
              </div>
              <div class="description-box">
                <span>·中国智慧产业发展研究院专家（副院级） </span>
                <span
                  >·中国关心下一代体育健康基金会·传统文化教育基金秘书长</span
                >
              </div>
              <div class="description-box">
                <span>·北大博雅元培智库国学研究课题客座教授 </span>
                <span>·中国管理科学研究院教育标准化研究所客座教授</span>
              </div>
            </div>
          </div>
          <div class="words-content" style="margin-right: 0">
            <div
              class="wow animate__fadeInBottomRight"
              data-wow-delay="500"
              data-wow-duration="0.5s"
              style="margin-bottom: 30px; font-size: 22px; color: #54462f"
            >
              【笃定前行】
            </div>
            <p
              class="wow animate__fadeInBottomRight"
              data-wow-delay="1000"
              data-wow-duration="1s"
            >
              作为多家上市公司的持股顾问，首席战略执行官，「知悟」创始人——何志强教授自幼研悟传统文化，体系化学习道家思想，蕴蓄几十载潜研传统文化。功不枉人，何教授在中国传统文化方面高深的造诣获得了众多专业研究机构的嘉奖和认可，并受邀作为多所学府的客座教授。
            </p>
            <p
              class="wow animate__fadeInBottomRight"
              data-wow-delay="1500"
              data-wow-duration="1.5s"
            >
              “知道才能悟道，悟道才能得到”是「知悟」创立的初衷，同样也是知悟的目标。将科技深度融合于生活各维度，让国民享受智慧生活带来的便利，利用“传承心”和“提升认知”，先市场一步，开拓智慧生活新方向，国家相关机构颁发多项奖项给予肯定。+”的全方位交融，打造了一个服务智慧生活的平台，融合传统文化与时代科技，开拓新生活理念生态圈，以中国传统文化为产品内核，以科技为媒介结合生活场景，用传统文化智慧结晶覆盖服务中国人衣食住行的全维度。
            </p>
            <!-- 6.2师父简介 -->
            <div class="summary">
              <div
                class="summary_title"
                v-for="(sumItenm, sumIndex) in summaryMenu"
                :key="sumIndex"
                @click="goLink(sumItenm)"
              >
                {{ sumItenm.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- 姚婷简介 -->
      <div class="brand-story-wrap">
        <div class="content-wrap">
         <div class="brand-story-wrap">
        <!-- <img class="bg-img" src="@/assets/img/about/aboutMountain.png" /> -->
        <div class="content-wrap">
          <div class="words-content" style="margin-top:-50px">
            <div
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="500"
              data-wow-duration="0.5s"
              style="margin-bottom: 30px; font-size: 22px; color: #54462f"
            >
              【美丽健康，由内而外】
            </div>
            <p
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="1000"
              data-wow-duration="1s"
            >
              当我们谈到姚灵玥，很多人会想到她在美业事业的成就。但实际上，她不仅仅是一名温婉可人、财富自由、风华正茂的美业从业者，更是一位智慧卓越、大爱天下、具有多面光芒的创业者、企业家和导师。
            </p>
             <p
              class="wow animate__fadeInBottomLeft"
              data-wow-delay="1500"
              data-wow-duration="1.5s"
            >
              如今，姚灵玥已经成为知悟大健康·灵玥康体系统的创始人。在姚灵玥的引领下，知悟大健康·灵玥康体系统于2023年正式成立。短短一周的时间内，她便招募了数百名股东、合伙人，突破单人一周1000万的业绩。作为该系统的创始人，姚灵玥深知大健康产业的重要性，更是在疫情过后一马当先，担负起大健康板块的重任，致力于为人们提供更好的健康服务，让成千上万人实现财富自由。
            </p>

            <!-- 9.6姚婷简介 -->
            <div class="summary">
              <div
                class="summary_title"
                v-for="(sumItenm, sumIndex) in summaryMenuYao"
                :key="sumIndex"
                @click="goLinkYao(sumItenm)"
              >
                {{ sumItenm.title }}
              </div>
            </div>
          </div>
          <div
            class="img wow fadeInUp"
            data-wow-delay="1500"
            data-wow-duration="1.5s"
          >
            <img src="@/assets/img/about/yaoImg2.jpeg" alt="" />
          </div>
        </div>
      </div>
          </div>
        </div>

    <!-- 发展历程 -->
    <div>
      <DevelopmentHistory />
    </div>

    <!-- 企业荣誉 -->
    <div>
      <EnterpriseHonor />
    </div>

    <!-- 人才招聘 -->
    <div>
      <TalentRecruitment />
    </div>

    <!-- 明星祝福 -->
    <div>
      <StarBlessingCom />
    </div>
    <ScrollTop />
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import childlike from '@/assets/img/about/childlike.png'
import heartfelt from '@/assets/img/about/heartfelt.png'
import sincerely from '@/assets/img/about/sincerely.png'
import ambition from '@/assets/img/about/ambition.png'
import determination from '@/assets/img/about/determination.png'
import care from '@/assets/img/about/care.png'
import DevelopmentHistory from '@/components/aboutCom/DevelopmentHistory.vue'
import EnterpriseHonor from '@/components/aboutCom/EnterpriseHonor.vue'
import TalentRecruitment from '@/components/aboutCom/TalentRecruitment.vue'
import StarBlessingCom from '@/components/aboutCom/StarBlessingCom.vue'
import ScrollTop from '@/components/scrollTop/ScrollTop.vue'
export default {
  name: 'AboutView',
  components: {
    DevelopmentHistory,
    EnterpriseHonor,
    TalentRecruitment,
    StarBlessingCom,
    ScrollTop,
  },
  data() {
    return {
      summaryMenu: [
        {
          title: '个人简介',
          path: '/about/personalProfile'
        },
        {
          title: '人物事迹',
          path: '/about/characterDeeds'
        },
        {
          title: '荣誉和职务',
          path: '/about/honorsAndPositions'
        },
      ],
      summaryMenuYao: [
        {
          title: '个人简介',
          path: '/about/personalProfileYao'
        },
        {
          title: '人物事迹',
          path: '/about/characterDeedsYao'
        },
        {
          title: '荣誉和职务',
          path: '/about/honorsAndPositionsYao'
        },
      ],
      culturalData: [
        {
          id: 0,
          leftWords: '怀有一颗真心，对人对事无功利心',
          rightWords: '守初，坚持自我本心，',
          title: childlike,
          alt: '童心',
        },
        {
          id: 1,
          leftWords: '爱岗敬业，认真做事',
          rightWords: '守忠，',
          title: heartfelt,
          alt: '忠心',
        },
        {
          id: 2,
          leftWords: '求真务实，求理客观',
          rightWords: '守真，',
          title: sincerely,
          alt: '真心',
        },
        {
          id: 3,
          leftWords: '突破限制，积极进取',
          rightWords: '有志，',
          title: ambition,
          alt: '野心',
        },
        {
          id: 4,
          leftWords: '直面挑战与困难',
          rightWords: '有魄，存着必胜的心态和信念，',
          title: determination,
          alt: '决心',
        },
        {
          id: 5,
          leftWords: '凡事用心，细节做起',
          rightWords: '有思，',
          title: care,
          alt: '用心',
        },
      ],
    }
  },
  methods: {
    goLink(sumItenm){
      console.log(sumItenm);
      this.$router.push(sumItenm.path);
    },

    goLinkYao(sumItenm){
      console.log(sumItenm);
      this.$router.push(sumItenm.path);
    },
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: 'wow', ///动画元件css类（默认为wow）
        animateClass: 'animated', //动画css类（默认为animated）
        offset: 200, //到元素距离触发动画（当默认为0）
        mobile: false, //在移动设备上触发动画（默认为true）
        live: false, //对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
</script>

<style lang="less">
.banner-wrap2 {
  display: none;
}
.about-wrapper {
  width: 100%;

  .title-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #54462f;
    font-size: 28px;
    position: relative;

    .title {
      margin-top: 9px;
      font-weight: bold;
    }

    .english {
      font-size: 14px;
      margin-top: -6px;
    }

    .title-img {
      width: 42px;
      height: 52px;
    }

    .bg-img {
      position: absolute;
      left: 0;
    }
  }

  .banner-wrap {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .about-content-wrapper {
    padding-top: 80px;
    box-sizing: border-box;

    .arrow-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 40px;

      div {
        width: 11px;
        height: 0px;
        border: 11px solid;
        border-top-color: transparent;
        border-right-color: #c2a16c;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      span {
        margin-left: 10px;
      }
    }

    .intro-list-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 25px;

      .list {
        width: 180px;
        height: 300px;
        border: 1px solid #ccc;
        background: #f5f6f7;
        padding: 20px 48px;
        box-sizing: border-box;
        position: relative;
        display: flex;

        .words {
          writing-mode: vertical-lr;
        }

        .img-bg {
          width: 50px;
          height: 48px;
          background: url('@/assets/img/about/aboutIntroBg.png') no-repeat
            center 0;
          background-size: 50px 48px;
          position: absolute;
          top: 24px;
          right: 14px;
        }

        .words-img {
          display: block;
          width: 39px;
          height: 65px;
          position: absolute;
          top: 40px;
          right: 31px;
        }
      }
    }

    .company-list {
      max-width: 1200px;
      margin: 83px auto 0;
    }

    .company-introduction-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      img {
        display: block;
        width: 590px;
        height: 320px;
      }

      .company-introduction {
        width: 543px;

        .title {
          color: #c2a16c;
          font-size: 22px;
          margin-bottom: 26px;
        }

        .detail {
          font-size: 16px;
          color: #333;
          text-align: justify;
        }

        .line {
          width: 60px;
          height: 3px;
          background: #666;
          margin-top: 40px;
        }
      }
    }
  }

  .brand-story-wrap {
    width: 100%;
    position: relative;

    .bg-img {
      width: 100%;
      height: 160px;
    }

    .content-wrap {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      padding: 44px 0 30px;
      box-sizing: border-box;

      .words-content {
        width: 575px;
        margin-right: 65px;

        p {
          text-align: justify;
          color: #333;
        }
      }

      .img {
        width: 800px;
        height: 510px;
        position: relative;

        img {
          display: block;
          width: 800px;
          height: 510px;
          margin-top: -88px;
        }

        // 遮罩内容
        .mask-box {
          width: 800px;
          height: 160px;
          background: url('@/assets/img/about/mask-bottom.png') no-repeat;
          background-size: 800px;
          position: absolute;
          bottom: 88px;
          left: 0;
          display: flex;
          flex-direction: column;
          padding: 58px 50px 26px 50px;
          box-sizing: border-box;
          justify-content: space-between;

          .description-box {
            font-size: 12px;
            color: #fff;
            display: flex;
            justify-content: flex-start;

            span:first-child {
              width: 40%;
            }
          }
        }
      }
    }
  }

  .brand-story-wrap:last-child .content-wrap .img {
    margin-right: 64px;
  }
}

// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  .about-wrapper .brand-story-wrap .content-wrap .img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
      margin-top: 0;
      padding: 30px;
      box-sizing: border-box;
    }

    .mask-box {
      width: 100%;
      height: auto;
      bottom: 0;
      background-size: 100%;
    }
  }

  .about-wrapper
    .about-content-wrapper
    .company-list
    .company-introduction-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-wrapper
    .about-content-wrapper
    .company-introduction-wrap
    .company-introduction {
    margin-top: 30px;
  }

  .about-wrapper .about-content-wrapper .intro-list-wrap {
    padding: 14px;
    box-sizing: border-box;
  }

  .about-wrapper .about-content-wrapper .intro-list-wrap .list {
    margin-top: 10px;
  }

  .about-wrapper {
    .about-content-wrapper {
      .intro-list-wrap {
        justify-content: space-evenly;
      }
    }
  }

  .content-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-wrapper .brand-story-wrap .content-wrap .words-content {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    padding: 20px;
    box-sizing: border-box;
  }

  .about-wrapper .brand-story-wrap .content-wrap .img {
    margin-top: 0px;
  }

  .about-wrapper .brand-story-wrap:last-child .content-wrap .img {
    margin-right: 0px;
  }
}

// 手机端
@media only screen and (max-width: 767px) {
  // #content {
  //   margin-top: 100px;
  // }

  .title-wrap {
    margin: 30px 0 20px !important;
  }
  .arrow-wrap {
    justify-content: left !important
    ;
    font-size: 18px;
  }
  .line {
    display: none;
  }
  .about-content-wrapper {
    background: #f3f3f3;
    padding-bottom: 12px;
  }
  .intro-list-wrap {
    .list {
      width: 49% !important;
      margin-bottom: 12px;
    }
  }
  .about-content-wrapper {
    padding-top: 30px !important;
  }
  .company-list {
    margin-top: 15px !important;
  }
  .title {
    margin-bottom: 12px !important;
  }
  .bg-img {
    display: none;
  }
  .banner-wrap2 {
    width: 100%;
    display: block;

    img {
      display: block;
      width: 100%;
    }
  }
  .banner-wrap {
    display: none;
  }
  .about-wrapper .brand-story-wrap .content-wrap .img {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
      margin-top: 0;
    }

    .mask-box {
      width: 100%;
      height: auto;
      bottom: 0;
      padding: 10px;
    }
  }

  .about-wrapper .about-content-wrapper .company-introduction-wrap {
    padding: 20px;
    box-sizing: border-box;
    background: white;
    margin-bottom: 20px;
  }

  .about-wrapper .about-content-wrapper .company-introduction-wrap img {
    width: 100%;
    margin-bottom: 20px;
  }

  .intro-list-wrap {
    padding: 0 10px;
    box-sizing: border-box;
  }

  .content-wrap {
    flex-wrap: wrap;
    padding: 0 !important;
  }
  .development-history-wrap {
    padding: 0 !important;
  }
  .enterprise-honor-wrap {
    padding: 20px 0 !important;
  }
  .footer {
    img {
      width: 35px;
    }
  }
  .slick-list {
    // padding-bottom: 15px!important;
  }
  .slick-dots {
    display: none !important;
  }
  .create-team {
    width: 94px !important;
    font-size: 18px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  .talent-recruitment-wrap {
    padding-top: 0 !important;
  }
  .english-name {
    font-size: 12px;
    white-space: nowrap;
  }
  .carousel-box {
    margin: 10px !important;
  }
  .about-wrapper .brand-story-wrap .content-wrap .words-content {
    width: 100%;
    text-align: center;
    margin-right: 0px;
    padding: 22px 20px 0 !important;
    box-sizing: border-box;
  }

  .about-wrapper .brand-story-wrap:last-child .content-wrap .img {
    margin-right: 0px;
  }
}
.summary {
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  padding: 0 0 30px;
  box-sizing: border-box;
  .summary_title {
    min-width: 100px;
    height: 30px;
    background: #c2a16c;
    color: #fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
