var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footerTop"},[_vm._m(0),_c('div',{staticClass:"footer-wrap",staticStyle:{"width":"280px"}},[_c('div',{staticClass:"words number"},[_c('b-icon',{attrs:{"icon":"telephone"}}),_vm._v(" 联系我们 ")],1),_c('div',{staticClass:"words"},[_c('b-icon',{attrs:{"icon":"geo-alt"}}),_vm._v(" 北京市大兴区瀛海镇瀛坤路4号院1号楼一层105号114室 ")],1)]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"phoneH5"},[_c('div',{staticClass:"phone-title"},[_c('h3',[_vm._v("产品与服务")]),_c('ul',{staticStyle:{"justify-content":"space-between"}},_vm._l((_vm.titleData),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item.name))])}),0)]),_c('div',{staticClass:"phone-title",staticStyle:{"padding-bottom":"20px!important"}},[_c('h3',[_vm._v("关于我们")]),_c('ul',{staticStyle:{"justify-content":"start"}},_vm._l((_vm.titleData2),function(itemAbout,indexAbout){return _c('li',{key:indexAbout,staticStyle:{"padding-right":"28px"}},[_vm._v(" "+_vm._s(itemAbout.name))])}),0)]),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrap"},[_c('img',{attrs:{"src":require("../../assets/img/footerLogo.png")}}),_c('div',{staticClass:"words number"},[_vm._v("北京真源诚传科技有限责任公司")]),_c('div',{staticClass:"words"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_vm._v(" 备案号 京ICP备2022001000号-1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrap"},[_c('div',{staticClass:"words number"},[_vm._v("产品与服务")]),_c('div',{staticClass:"service-words"},[_c('div',{staticClass:"row-font"},[_c('span',[_vm._v("在线云医")]),_c('span',[_vm._v("知悟商城")])]),_c('div',{staticClass:"row-font"},[_c('span',[_vm._v("传统国学")]),_c('span',[_vm._v("文化学习")])]),_c('div',{staticClass:"row-font"},[_c('span',[_vm._v("会员旅行")]),_c('span',[_vm._v("艺术鉴赏")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrap"},[_c('div',{staticClass:"words number"},[_vm._v("关于我们")]),_c('div',{staticClass:"service-words"},[_c('div',{staticClass:"row-font"},[_c('span',[_vm._v("企业简介")])]),_c('div',{staticClass:"row-font"},[_c('span',[_vm._v("企业动态")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrap"},[_c('img',{staticClass:"code-wrap",attrs:{"src":require("../../assets/img/code.png")}}),_c('div',{staticClass:"words",staticStyle:{"align-self":"center"}},[_vm._v("知悟官方服务号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-title",staticStyle:{"border-top":"1px solid #555555","padding-top":"20px"}},[_c('h3',[_vm._v("联系我们")]),_c('p',[_vm._v("400-105-7698")]),_c('ul',{staticStyle:{"justify-content":"start"}},[_c('li',[_vm._v("北京市大兴区瀛海镇瀛坤路4号院1号楼一层105号114室")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('div',{staticClass:"pic-left"},[_c('img',{attrs:{"src":require("../../assets/img/code.png")}}),_c('div',{staticClass:"pic-words",staticStyle:{"align-self":"center"}},[_vm._v("知悟官方服务号")])]),_c('div',{staticClass:"pic-logo"},[_c('img',{attrs:{"src":require("../../assets/img/footerLogo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py"},[_c('span',[_vm._v("北京真源诚传责任有限公司")]),_c('span',[_vm._v("备案号 京ICP备2022001000号-1")])])
}]

export { render, staticRenderFns }