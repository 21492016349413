<template>
  <div id="talent-recruitment-wrap" style="background:#FCF8FB;">
    <div class="talent-recruitment-wrap">
        <div class="title-wrap">
            <img class="title-img" src="@/assets/img/five.png"/>
            <div class="title">人才招聘</div>
            <div class="english">Talent Recruitment</div>
            <div class="sub-title">
                科技是第一生产力，人才是第一资源。知悟竭诚欢迎社会各界有志之士<br/>投身传承中华传统文化的伟大使命中，携手共进，共同见证中华文化新辉煌！
            </div>
        </div>
        <!-- 创始团队 -->
        <div class="carousel-box">
            <div class="carousel wow animate__fadeInDown" data-wow-delay="1000" data-wow-duration="1.5s">
                <div class="footer">
                    <div class="create-team">创始团队</div>
                    <img src="@/assets/img/about/talentBg.png" alt="">
                    <div class="english-name">Founding Team</div>
                </div>
                <VueSlickCarousel v-bind="settings">
                    <div class="item-box" v-for="(item,index) in bannerList" :key="index">
                        <div class="item-box-small">
                        <img :src="item.src" />
                        <span class="name">{{item.name}}</span>
                        <span>{{item.title1}}</span>
                        <span>{{item.title2}}</span>
                        <span>{{item.title3}}</span>
                        <span>{{item.title4}}</span>
                        <span v-if="item.id == 11">{{item.title5}}</span>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <!-- 顾问团队 -->
        <div class="carousel-box">
            <div class="carousel wow animate__fadeInDown" data-wow-delay="1000" data-wow-duration="1.5s">
                <div class="footer">
                    <div class="create-team">顾问团队</div>
                    <img src="@/assets/img/about/talentBg.png" alt="">
                    <div class="english-name">Consultant Team</div>
                </div>
                <VueSlickCarousel v-bind="settings1">
                    <div class="item-box" v-for="(item,index) in consultantList" :key="index">
                        <div class="item-box-small">
                        <img :src="item.src" />
                        <span class="name">{{item.name}}</span>
                        <span>{{item.title1}}</span>
                        <span>{{item.title2}}</span>
                        <span>{{item.title3}}</span>
                        <span>{{item.title4}}</span>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <!-- 背景图 -->
        <div class="talent-mountain-bg hide-class"></div>
        <div class="talent-bottom-bg hide-class"></div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// 创始团队
import recruit1 from "@/assets/img/about/createTeam/recruit1.png";
import recruit2 from "@/assets/img/about/createTeam/recruit2.png";
import recruit3 from "@/assets/img/about/createTeam/recruit3.png";
import recruit4 from "@/assets/img/about/createTeam/recruit4.png";
import recruit5 from "@/assets/img/about/createTeam/recruit5.png";
import recruit6 from "@/assets/img/about/createTeam/recruit6.png";
import recruit7 from "@/assets/img/about/createTeam/recruit7.png";
import recruit8 from "@/assets/img/about/createTeam/recruit8.png";
import recruit9 from "@/assets/img/about/createTeam/recruit9.png";
import recruit10 from "@/assets/img/about/createTeam/recruit10.png";
import recruit11 from "@/assets/img/about/createTeam/recruit11.png";
import recruit12 from "@/assets/img/about/createTeam/recruit12.png";

//顾问团队
import consultant1 from "@/assets/img/about/consultantTeam/consultant1.png";
import consultant2 from "@/assets/img/about/consultantTeam/consultant2.png";
import consultant3 from "@/assets/img/about/consultantTeam/consultant3.png";
import consultant4 from "@/assets/img/about/consultantTeam/consultant4.png";
import consultant5 from "@/assets/img/about/consultantTeam/consultant5.png";
import consultant6 from "@/assets/img/about/consultantTeam/consultant6.png";
import consultant7 from "@/assets/img/about/consultantTeam/consultant7.png";
import consultant8 from "@/assets/img/about/consultantTeam/consultant8.png";

export default {
  name: "TalentRecruitment",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
        settings:{
            // dots:true,
            arrows:true,
            slidesToScroll:4,
            slidesToShow:4,
            // centerMode: true,
            // centerPadding: '20px',
            // focusOnSelect: true,
            // infinite: true,
            speed: 1000,
            autoplay: true,
            pauseOnHover: true,
            touchMove:true,
            responsive: [{
                "breakpoint": 1024,
                "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "infinite": true,
                "dots": true,
                "arrows":false
                }
            },
            {
                "breakpoint": 600,
                "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "initialSlide": 2,
                "dots": true,
                "arrows":false
                }
            },
            {
                "breakpoint": 480,
                "settings": {
                "dots":true,
                "arrows":false,
                "slidesToShow": 1,
                "slidesToScroll": 1
                }
            }
            ]
        },
        settings1:{
            // dots:true,
            arrows:true,
            slidesToScroll:2,
            slidesToShow:4,
            // centerMode: true,
            // centerPadding: '20px',
            // focusOnSelect: true,
            // infinite: true,
            speed: 1000,
            autoplay: true,
            pauseOnHover: true,
            touchMove:true,
            responsive: [{
                "breakpoint": 1024,
                "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "infinite": true,
                "dots": true,
                "arrows":false
                }
            },
            {
                "breakpoint": 600,
                "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "initialSlide": 2,
                "dots": true,
                "arrows":false
                }
            },
            {
                "breakpoint": 480,
                "settings": {
                "dots":true,
                "arrows":false,
                "slidesToShow": 1,
                "slidesToScroll": 1
                }
            }
            ]
        },
        bannerList: [
            {
                id: 0,
                src: recruit1,
                name:"郜殿革",
                title1:"中华玄门终身名誉掌门",
                title2:"中国社会管理科学院教育科学研究所研究员",
                title3:"中国领购网董事长",
                title4:"知悟创始人之一"
            },{
                id: 1,
                src: recruit2,
                name:"何志强",
                title1:"中国管理科学研究院教育标准化研究所客座教授",
                title2:"中国智慧产业发展研究院专家（副院级）",
                title3:"北大博雅元培智库国学研究课题客座教授",
                title4:"国际玄道养生研究院副院长"
            },{
                id: 2,
                src: recruit3,
                name:"宋国梁",
                title1:"中华玄门终身名誉掌门",
                title2:"中国社会管理科学院教育科学研究所研究员",
                title3:"传统文化产业发展研究中心副主任",
                title4:"北京真源诚传科技有限责任公司总裁"
            },{
                id: 3,
                src: recruit4,
                name:"朱红柳",
                title1:"中国管理科学研究院特约研究员",
                title2:"中国智慧产业发展研究院传统文化产业发展研究中心主任",
                title3:"北京真源诚传科技有限责任公司董事长",
                title4:""
            },{
                id: 4,
                src: recruit5,
                name:"郑茜宁",
                title1:"中国管理科学研究院特约研究员",
                title2:"中国智慧产业发展研究院副主任",
                title3:"北京真源诚传科技有限责任公司执行董事兼教育总监",
                title4:""
            },{
                id: 5,
                src: recruit6,
                name:"徐云汐",
                title1:"中国管理科学研究院特约研究员",
                title2:"中智传统文化产业发展研究院中心副主任",
                title3:"北京真源诚传科技有限责任公司执行董事长兼销售总监",
                title4:""
            },{
                id: 6,
                src: recruit7,
                name:"黄静娴",
                title1:"中智传统文化产业发展研究院中心副主任",
                title2:"北京真源诚传科技有限责任公司执行董事兼海外部运营总监",
                title3:"",
                title4:""
            },{
                id: 7,
                src: recruit8,
                name:"李柏豪",
                title1:"美国三藩市中美文化及贸易交流总顾问",
                title2:"美国三藩市中美促进协会会长及三藩市华人排球协会主席",
                title3:"北京真源诚传科技有限责任公司执行董事兼海外部总经理",
                title4:""
            },{
                id: 8,
                src: recruit9,
                name:"林冰玲",
                title1:"北京真源诚传科技有限责任公司海外部副总经理兼市场总监",
                title2:"",
                title3:"",
                title4:""
            },{
                id: 9,
                src: recruit10,
                name:"李永光",
                title1:"万国商业机器公司（IBM)高管",
                title2:"北京真源诚传科技有限责任公司执行董事兼海外部运营总监",
                title3:"",
                title4:""
            },{
                id: 10,
                src: recruit11,
                name:"潘文军",
                title1:"曾任职亚信科技（中国）技术总监",
                title2:"曾任职三星数据系统（西安）技术总监",
                title3:"北京真源诚传科技有限责任公司技术总监",
                title4:""
            },{
                id: 11,
                src: recruit12,
                name:"董艳",
                title1:"中国管理科学研究院特约研究员",
                title2:"中国智慧产业发展研究院",
                title3:"传统文化产业发展研究院中心副主任",
                title4:"国际注册会计师（ACCA)",
                title5:"北京真源诚传科技有限责任公司首席财务官"
            }
        ],
        consultantList: [
            {
                id: 0,
                src: consultant1,
                name:"许仕良",
                title1:"中国投资协会生态产业投资专业委员会副秘书长",
                title2:"",
                title3:"",
                title4:""
            },{
                id: 1,
                src: consultant2,
                name:"洪炎忠",
                title1:"全球可持续发展论坛组委会名誉主席",
                title2:"中国自贸区联合会名誉会长",
                title3:"中国缅甸经济合作促进会荣誉会长",
                title4:"中国管理科学研究院特邀高级研究院"
            },{
                id: 2,
                src: consultant3,
                name:"冯峻灏",
                title1:"中国智慧产业发展研究院常委会执行主席 执行院长",
                title2:"中国十大优秀策划专家",
                title3:"",
                title4:""
            },{
                id: 3,
                src: consultant4,
                name:"毛云霞",
                title1:"博鳌世界民族文化发展高峰论坛组委会副秘书长",
                title2:"",
                title3:"",
                title4:""
            },{
                id: 4,
                src: consultant5,
                name:"岳宣义",
                title1:"原司法部副部长",
                title2:"中纪委驻司法部纪律检查组组长",
                title3:"中国法律援助基金会会长",
                title4:""
            },{
                id: 5,
                src: consultant6,
                name:"傅新民",
                title1:"中国解放军驻军上海航天局中心原总军事代表",
                title2:"中智传统文化产业发展研究院中心副主任",
                title3:"上海百老德育讲师团名誉团长时董会导师",
                title4:""
            },{
                id: 6,
                src: consultant7,
                name:"夏达海",
                title1:"中华全科医师诊疗专家",
                title2:"北京真源诚传科技有限责任公司执行董事兼海外部运营总监",
                title3:"",
                title4:""
            },{
                id: 7,
                src: consultant8,
                name:"杨玉环",
                title1:"原中国侨联副主席上海侨联主席",
                title2:"",
                title3:"",
                title4:""
            }
        ],
    };
  },
  methods: {
    
  },
  mounted(){
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 200, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: false, //对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  }
};
</script>
<style scoped lang="less">
::v-deep .slick-prev:before, .slick-next:before{
  font-size:30px !important;
  color:#57462B !important;
}
::v-deep .slick-next:before{
  font-size:30px !important;
  color:#57462B !important;
}
::v-deep .slick-prev{
  left:-85px;
}
::v-deep  .slick-next{
  right:-80px;
}
.sub-title{
    width:600px;
    margin:0 auto;
    font-size:16px;
    text-align:center;
    margin-top:38px;
}
.talent-recruitment-wrap{
    width:100%;
    background:#F9F9F9;
    padding:90px 0 52px;
    box-sizing:border-box;
    position:relative;
    .talent-mountain-bg{
        width:617px;
        height:245px;
        background:url("@/assets/img/about/talentMoutainBg.png") no-repeat center 0;
        position:absolute;
        top:50px;
        right:0;
    }
    .talent-bottom-bg{
        width:1152px;
        height:239px;
        background:url("@/assets/img/about/teamTwoBg.png") no-repeat center 0;
        position:absolute;
        bottom:0;
        left:0;
    }
    .carousel-box{
        width:100%;
        margin-top:56px;
    }
    .carousel{
        max-width: 1200px;
        margin:0 auto;
        z-index:1;
        .footer{
            display:flex;
            align-items:center;
            padding-left: 10px;
            box-sizing: border-box;
            img{
                width:71px;
                height:11px;
                margin-left:15px;
            }
            .create-team{
                width: 145px;
                height: 45px;
                background: #C79E5B;
                font-size:28px;
                color:#fff;
                text-align:center;
            }
            .english-name{
                font-size:20px;
                color:#C2A16C;

            }
        }
        
        .item-box{
            width:284px;
            height:405px;
            padding:15px 7px;
            box-sizing:border-box;
            .item-box-small{
                width:100%;
                height:100%;
                box-shadow:10px 1px 20px rgba(177,177,177,0.1);
                background:#fff;
                padding:23px 5px;
                box-sizing:border-box;
                display:flex;
                flex-direction: column;
                align-items: center;
            }
            .name{
                font-size:24px;
                margin:11px 0 30px;
            }
            span{
                font-size:14px;
                text-align: -webkit-center;
            }
            img{
                display:block;
                width:148px;
                height:148px;
                border-radius: 50%;
            }
            p{
                font-size:12px;
            }
        }
    }
}
// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
    .talent-recruitment-wrap .carousel{
        padding:0 10px;
        box-sizing:border-box;
        margin-top:40px;
    }
    .hide-class{
        display:none;
    }
    .sub-title{
        width:100%;
        padding:0 20px;
        box-sizing: border-box;
    }
}
// 手机端
@media only screen and (max-width: 767px) {
    .talent-recruitment-wrap .carousel{
        padding:0 10px;
        box-sizing:border-box;
        margin-top:40px;
    }
    .hide-class{
        display:none;
    }
    .sub-title{
        width:100%;
        padding:0 20px;
        box-sizing: border-box;
    }
}
</style>