<template>
    <div id="function-intro-wrap">
        <div class="fun-img"></div>
        <!-- img区域 -->
        <div class="intro-box">
            <div class="intro-box-wrap wow"
                :class="(index == 0 || index == 1 || index == 2) ? 'animate__slideInLeft' : 'animate__slideInRight'"
                data-wow-delay="1500" data-wow-duration="2s" v-for="(item,index) in funImgData" :key="index">
                <div class="img-box" @mouseenter="showMaskContent(index)" @mouseleave="hideMaskContent(index)">
                    <img :src="item.img" alt="">
                    <div class="mask-box" :class="item.flag == true ? 'mask-box-active' : ''">
                        <h5>{{ item.maskTitle }}</h5>
                        <h5 style="margin-bottom:44px;">{{ item.maskSubTitle }}</h5>
                        <p style="font-size:14px;white-space: pre-wrap;text-align: justify;">{{ item.description }}</p>
                    </div>
                </div>
                <div class="title">{{ item.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
import fun1 from "@/assets/img/product/fun1.png"
import fun2 from "@/assets/img/product/fun2.png"
import fun3 from "@/assets/img/product/fun3.png"
import fun4 from "@/assets/img/product/fun4.png"
import fun5 from "@/assets/img/product/fun5.png"
import fun6 from "@/assets/img/product/fun6.png"
export default {
    name: "FunctionIntro",
    components: {

    },
    data() {
        return {
            funImgData: [
                {
                    id: 0,
                    title: "互联网问诊",
                    img: fun1,
                    flag: false,
                    maskTitle: "互联网在线问诊",
                    maskSubTitle: "您的掌上健康管理师",
                    description: "·全国医生7*24在线，一键问诊全国名医\n·慢病科学护理，健康指标跟踪监测管理\n·海量育儿知识库，健康资讯全知悉\n·AI舌诊个性化理疗，中医养生科学指导",
                }, {
                    id: 1,
                    title: "东方环境科学",
                    img: fun2,
                    flag: false,
                    maskTitle: "互联网AI技术结合先哲智慧",
                    maskSubTitle: "知晓终生运程起伏",
                    description: "·AI技术精准推算，国学大师24h在线精准答疑\n·环境科学清晰指点，兴旺家运，点亮人生高光时刻\n·行业泰斗观微今世通晓未来，开创人生巅峰\n·悟道师自主入驻，点拨人生智慧，掌舵人生"

                }, {
                    id: 2,
                    title: "文化学习",
                    img: fun3,
                    flag: false,
                    maskTitle: "先哲智慧结合国潮玩法",
                    maskSubTitle: "保护传承非遗",
                    description: "·《中国传统国学与互联网应用》课题背书，创新护中华文脉\n·国风元素与现代科技元素结合，内容有料有趣有深度\n·精巧文创衍生品，激活传统文化生命力\n·少儿文化启蒙，经典古诗文言史记，璀璨文明易懂易学\n·有料视频随时分享，精品上传就能赚钱"

                }, {
                    id: 3,
                    title: "知悟商城",
                    img: fun4,
                    flag: false,
                    maskTitle: "全品类正品资质保障",
                    maskSubTitle: "抄底供应链源头",
                    description: "·集合式购物平台，选择方便直击低价，优惠让利花样百出\n·全品类正品资质保障，分享裂变快速获客，多种玩法精准互动\n·深度探研文化雅韵，打造知悟甄茶系列，知悟专属商品持续推陈出新\n·官方直营，厂家入驻，配送快"

                }, {
                    id: 4,
                    title: "艺术鉴赏",
                    img: fun5,
                    flag: false,
                    maskTitle: "视觉艺术品鉴",
                    maskSubTitle: "独特审美质感，诠释美学价值",
                    description: "·知名导演尹哲担纲艺术总监，深度研讨艺术人文价值\n·全网独家蓝光片源，高清视觉纯享\n·艺考培训学习，为“艺”筑梦"
                }, {
                    id: 5,
                    title: "会员旅行",
                    img: fun6,
                    flag: false,
                    maskTitle: "走进典籍里的中国",
                    maskSubTitle: "体味山水人文",
                    description: "·会员专属0元旅游，全程无购物\n·拎包出行、免费游玩，出行无忧\n·精品文旅路线，体验国风历史特色"
                }
            ]
        }
    },
    methods: {
        showMaskContent(index) {
            this.funImgData[index].flag = true;
        },
        hideMaskContent(index) {
            this.funImgData[index].flag = false;
        }
    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow", ///动画元件css类（默认为wow）
                animateClass: "animated", //动画css类（默认为animated）
                offset: 300, //到元素距离触发动画（当默认为0）
                mobile: true, //在移动设备上触发动画（默认为true）
                live: false, //对异步加载的内容进行操作（默认为true）
            })
            wow.init()
        })
    },
}
</script>

<style scoped lang="less">
#function-intro-wrap {
    width: 100%;
    background: #FFFEFF;
    position: relative;

    // padding-bottom: 50px;
    // box-sizing: border-box;
    .fun-img {
        position: absolute;
        width: 606px;
        height: 606px;
        background: url("@/assets/img/product/funMoutainBg.png") no-repeat center 0;
        background-size: 606px;
        z-index: 1;
    }
}

.intro-box {
    width: 1200px;
    height: auto;
    background: #FFFEFF;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .intro-box-wrap {
        width: 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;

        .img-box {
            display: block;
            width: 390px;
            height: 340px;
            background: #FFFFFF;
            box-shadow: 0px 3px 20px 0px rgba(167, 167, 167, 0.2);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .mask-box {
                cursor: pointer;
                width: 358px;
                height: 310px;
                position: absolute;
                background: #C3A16C;
                // background:rgba(195, 161, 108,.0);
                opacity: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                transition: all 0.5s ease-in-out;
                color: #fff;
                padding: 0 10px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .mask-box-active {
                opacity: 0.9;
                background: rgba(195, 161, 108, .9);
            }

            img {
                display: block;
                width: 358px;
                height: 310px;
            }
        }

        .title {
            margin: 20px 0 20px;
            font-weight: bold;
        }
    }
}



// 手机端
@media only screen and (max-width: 768px) {
    .fun-img{
        display: none;
    }
    .intro-box{
        width: 100%;
    }
   
}
</style>