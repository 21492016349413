<template>
    <div id="looking-ahead-wrap">
        <div class="fun-img2"></div>
        <div class="looking-wrap">
            <div class="title-wrap" style="margin:93px 0 0px;">
                <img class="title-img" src="@/assets/img/two.png" />
                <div class="title">展望未来</div>
                <div class="english">Looking Forward To The Future</div>
            </div>
            <div class="words-img wow fadeInUp" data-wow-delay="800" data-wow-duration="1s"></div>

            <!-- 圆形图片展示 -->
            <div class="content-box" ref="splash">
                <div class="single-box" v-for="(item, index) in lookingAheadData" :key="index">
                    <div class="left-words-box">
                        <div class="description" style="text-indent:20px;">{{ item.subTitle1 }}</div>
                        <div class="description">{{ item.subTitle2 }}</div>
                        <div class="title-box">
                            <div class="line"></div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                    </div>
                    <div class="right-img">
                        <div class="splash-box">
                            <!-- <div class="box"> -->
                            <video autoplay muted webkit-playsinline="true" playsinline="true">
                                <source src="@/assets/img/product/shuimo.mp4" type="video/mp4" />
                            </video>
                            <!-- </div>  -->
                            <img :src="item.img" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
import social from "@/assets/img/product/social.png"
import inherit from "@/assets/img/product/inherit.png"
import service from "@/assets/img/product/service.png"
export default {
    name: "LookingAhead",
    components: {

    },
    data() {
        return {
            lookingAheadData: [
                {
                    id: 0,
                    img: social,
                    subTitle1: "多元化线上交友空间站",
                    subTitle2: "",
                    title: "社交"
                }, {
                    id: 1,
                    img: inherit,
                    subTitle1: "见证国之灵魂",
                    subTitle2: "海外文化输出，",
                    title: "传承"
                }, {
                    id: 2,
                    img: service,
                    subTitle1: "智享生活服务生态圈",
                    subTitle2: "生活服务、综合",
                    title: "服务"
                }
            ]
        }
    },
    methods: {

    },
    mounted() {
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow", ///动画元件css类（默认为wow）
                animateClass: "animated", //动画css类（默认为animated）
                offset: 200, //到元素距离触发动画（当默认为0）
                mobile: true, //在移动设备上触发动画（默认为true）
                live: false, //对异步加载的内容进行操作（默认为true）
            })
            wow.init()
        })
    }
}
</script>

<style scoped lang="less">
#looking-ahead-wrap {
    width: 100%;
    background: #FFFDFF;
    // overflow:hidden;
    position: relative;
    z-index: 100;

    .fun-img2 {
        position: absolute;
        width: 678px;
        height: 523px;
        background: url("@/assets/img/product/sun.png") no-repeat center 0;
        background-size: 678px;
        top: -100px;
        right: 0px;
        z-index: 110;
    }
}

.looking-wrap {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #54462F;
        font-size: 28px;

        .title {
            margin-top: 9px;
            font-weight: bold;
        }

        .english {
            font-size: 14px;
            margin-top: -6px
        }

        .title-img {
            width: 42px;
            height: 52px;
        }
    }

    .words-img {
        width: 760px;
        height: 132px;
        background: url("@/assets/img/product/lookingtitle.png") no-repeat center 0;
        background-size: 100%;
    }

    .content-box {
        width: 100%;
        display: flex;
        background: #FFFEFF;
        justify-content: space-between;
        margin-bottom: 77px;

        .single-box {
            display: flex;

            .left-words-box {
                display: flex;
                padding-top: 30px;
                box-sizing: border-box;

                .description {
                    writing-mode: vertical-lr;
                    font-size: 18px;
                    color: #666;
                }

                .title-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .line {
                        width: 16px;
                        height: 2px;
                        background: #333;
                        margin-bottom: 20px;
                    }

                    .title {
                        writing-mode: vertical-lr;
                        font-size: 24px;
                        color: #333;
                        font-weight: bold;
                    }
                }
            }

            .right-img {
                width: 340px;
                height: 340px;

                .splash-box {
                    width: 340px;
                    height: 340px;
                    position: relative;

                    // .box{
                    //     position: absolute;
                    //     top:0;
                    //     left:0;
                    //     bottom:0;
                    //     right:0;
                    //     margin:auto;
                    //     width:300px;
                    //     height:300px; 
                    //     box-sizing: border-box;
                    // }
                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        mix-blend-mode: screen;
                        object-fit: cover;
                    }
                }

                img {
                    display: block;
                    width: 340px;
                    height: 340px;
                }
            }
        }
    }

}

// 手机端
@media only screen and (max-width: 768px) {
    .title-wrap {
        margin-top: 50px !important;
    }
    .words-img{
        width: 100%!important;
        height: 66px!important;
    }
    .fun-img2{
        display: none;
    }
    .single-box {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 20px;
    }

    .right-img {
        img {
            width: 100%;
        }
    }

    .looking-wrap {
        width: 100% !important;

        .content-box {
            display: flex;
            flex-direction: column;
        }
    }

    .bottom-bg-box {
        img {
            width: 100%
        }
    }

    .fun-img {
        img {
            width: 100%
        }
    }

    .intro-box {
        width: 100%;
    }

    .intro-box-wrap {
        width: 98%;

        .img-box {
            width: 100% !important;

            img {
                width: 100% !important;
            }
        }
    }
}
</style>