import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
// import IndexView from '@/views/IndexView.vue'
import ProductView from '@/views/ProductView.vue'
import AboutView from '@/views/AboutView.vue'
import DynamicView from '@/views/DynamicView.vue'
import DynamicDetail from '../views/dynamicPage/DynamicDetail.vue'
import DynamicNewDetail from '../views/dynamicPage/DynamicNewDetail.vue'
import PersonalProfileDetail from '../views/aboutPage/PersonalProfileDetail.vue'
import CharacterDeedsDetail from '../views/aboutPage/CharacterDeedsDetail.vue'
import HonorsAndPositionsDetail from '../views/aboutPage/HonorsAndPositionsDetail.vue'
import PersonalProfileYaoDetail from '../views/aboutPage/YaoTing/PersonalProfileYaoDetail.vue'
import CharacterDeedsYaoDetail from '../views/aboutPage/YaoTing/CharacterDeedsYaoDetail.vue'
import HonorsAndPositionsYaoDetail from '../views/aboutPage/YaoTing/HonorsAndPositionsYaoDetail.vue'

// 解决路由重复错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [{
      name: 'index',
      path: '/',
      component: () =>
        import('@/views/IndexView.vue'),
      meta: {
        title: '首页'
      }
    }, {
      path: '/product',
      name: 'product',
      component: ProductView
    }, {
      path: '/dynamic',
      name: 'dynamic',
      component: DynamicView,
    }, {
      path: '/about',
      name: 'about',
      component: AboutView
    }, {
      path: '/dynamic/detail',
      name: 'detail',
      component: DynamicDetail,
    }, 
    {
      path: '/dynamic/newDetail',
      name: 'newDetail',
      component: DynamicNewDetail,
    },
    {
      path: '/about/personalProfile',
      name: 'personalProfile',
      component: PersonalProfileDetail,
    }, {
      path: '/about/characterDeeds',
      name: 'characterDeeds',
      component: CharacterDeedsDetail,
    }, {
      path: '/about/honorsAndPositions',
      name: 'honorsAndPositions',
      component: HonorsAndPositionsDetail,
    },

    {
      path: '/about/personalProfileYao',
      name: 'personalProfileYao',
      component: PersonalProfileYaoDetail,
    }, {
      path: '/about/characterDeedsYao',
      name: 'characterDeedsYao',
      component: CharacterDeedsYaoDetail,
    }, {
      path: '/about/honorsAndPositionsYao',
      name: 'honorsAndPositionsYao',
      component: HonorsAndPositionsYaoDetail,
    },
  ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//全局路由守卫
router.beforeEach((to, from, next) => {
  let name = to.name;
  sessionStorage.setItem("pathName", name)
  next();
});


export default router
