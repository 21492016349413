import service from "@/utils/service.js"
export let getlink=(url)=>{
    return new Promise((resolve,reject)=>{
        service.request({
            url,
            method:"get"
        }).then((ok)=>{
            resolve(ok)
        }).catch((err)=>{
            reject(err)
        })
    })
}
// 发送get数据   
// 使用params来进行发送数据
export let getdata=(url,params)=>{
    return new Promise((resolve,reject)=>{
        service.request({
            url,
            method:"get",
            params
        }).then((ok)=>{
            resolve(ok)
        }).catch((err)=>{
            reject(err)
        })
    })
}