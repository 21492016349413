<template>
  <div class="dynamicDetail">
    <h2>个人简介</h2>
    <p>知悟（北京真源诚传科技有限责任公司）旗下灵玥康体系统创始人</p>
    <div>
      <h6>摘要：</h6>
      <p>
        姚婷（别名：姚灵玥），知名企业家、创业家，著名女性成长导师、创业导师。现任知悟大健康·灵玥康体创始人兼执行董事、真源诚传科技有限责任公司副总裁 ，知悟生态事业部总经理。<br>她是中国新一代魅力女性企业家代表，在商业领域拥有卓越的市场运作和资源整合能力，2021年加入智慧生活服务平台“知悟”生态，并成为其核心股东，负责知悟大健康板块的整体运作，以其敏锐的商业嗅觉和前瞻性的视野，积极布局大健康产业，创立“知悟大健康·灵玥康体系统”，并迅速展现出其出众的商业能力与领导力。<br>姚灵玥在医学美容深耕17年，在美容与抗衰领域拥有较大的影响力，担任中联美业逆龄抗衰医学研究院院长、华夏国学教育研究院秘书长、中慈民族医药研究院秘书长、北大博雅研究院高级营养师等职务。<br>她认为人的魅力是由内而外的，外在形象要靠丰满的内在支撑，主张“内外兼修”，这与以中国传统优秀文化与智慧为灵魂的“知悟”不谋而合。除了商业运作外，她还进一步钻研文化与精神修养，任中国智慧产业发展研究院传统文化产业发展研究中心特约研究员，受聘为传统文化专项基金副主任、中非共和国驻华大使馆国学文化顾问秘书长等职，并于2023年荣获名人百科中国影响力人物数据库“非遗文化传播大使”荣誉。
      </p>
      <h6>基本信息：</h6>
      <p>
        姚婷（姚灵玥）深耕医学美容17年，始终坚守着让天下单纯女人皆享幸福时光的愿景，打破了所有行业的单一性。她认为，女性对美的追求不仅仅是外在形象，也要用文化丰富内在精神，要两者相互融合，此后，她的身份也从从业者、管理者转变为创业者、企业家、女性导师。
      </p>
      <p>
        2021年，姚婷正式加入首个基于中国传统文化智慧的现代生活服务平台和商业生态——知悟，并成为其核心股东，由于姚婷出色的整合能力，随即担任知悟生态事业部总经理。在疫情过后，更是一马当先，担负起大健康板块的重任，于2023年正式成立知悟大健康·灵玥康体系统，开启大健康新零售事业版图。
      </p>
       <p>
        姚婷（姚灵玥）是一个打破界限的女性创业者。在美业行业，姚婷（姚灵玥）的名字被人们广为熟知，这位温婉可人、风华正茂的女性创业家，不仅拥有着出色的外表，更在智慧和成就上超越了大多数同龄人。
      </p>
      
      <p>
        姚婷的成长经历并非一帆风顺。在美业行业的早期阶段，她也曾遭遇过许多困难和挫折。然而，这些挑战并没有让她放弃，反而坚定了她的信念。她用自己的行动证明了，只要坚持不懈、努力追求，人生中的任何挑战都可以被克服。在事业之初，她选择了进入美容行业，开始了自己的职业生涯。
      </p>
      <p>
       凭借自身的专业素养和进取精神，她潜心研究美容市场和商业模式，提出了“女性美要内外兼修”等颇具前瞻性的服务理念，引领新时代女性“美学觉醒”的潮流，很快便跻身行业头部影响力阵列。但她并没有满足于此，开始积极跨界学习各种知识，总结出一套独一无二的体系，并开始创业。她发现，当前美容行业的服务质量参差不齐，技术水平也参差不齐。于是，她开始尝试创造一种全新的美容服务模式，让顾客在享受美容服务的同时，还能够得到生活、心理健康等跨领域的专业建议和指导。这种服务模式被顾客们广泛认可。
      </p>
      <p>
        姚婷的成功离不开她对智慧的认知和对未来的前瞻把握。她认为，只有掌握了智慧，才能在未来的竞争中立于不败之地。因此，她一直致力于提升自己，不断探索新的领域，拓展自己的事业版图。2023年，她在加入知悟生态后，随即凭借其商业嗅觉和智慧，创办知悟大健康·灵玥康体系统，并在短短一周内招募了数百名股东、合伙人，创造了单人一周1000万的业绩。作为该系统的创始人，姚婷深知大健康产业的重要性，更是在疫情过后一马当先，担负起大健康板块的重任，致力于为人们提供更好的健康服务，让成千上万人实现财富自由。
      </p>
      <p>作为大健康产业的领军人物之一，姚婷始终坚持以客户需求为导向，注重产品品质和服务体验。她带领团队不断研发创新产品，满足消费者对于健康和生活品质的追求。同时，她也积极推动行业的规范化和标准化，为大健康产业的健康发展做出了积极的贡献。</p>
    </div>
  </div>
</template>

<script>
export default {
   created(){
    
    document.body.scrollTop = document.documentElement.scrollTop = this.scrollTop;
  },
}
</script>

<style lang='less' scoped>
.dynamicDetail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2{
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    font-size: 16px;
    font-weight: bold;
  }
  p{
    line-height: 30px;
  }
}
</style>