// 拦截器
// 1.引用axios
import axios from "axios"
// 2.创建axios实例
let service = axios.create()
// 3.请求拦截和相应拦截
// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

//   4.暴漏
export default service