<template>
    <div id="star-blessing-com">
        <div class="star-blessing-com" ref="attrRef">
            <div class="img" @click="showIntro(index)" @mouseenter="showIntro(index)" @mouseleave="hideIntro(index)" v-for="(item,index) in starData" :key="index">
                <img :style="index == 11 ? wordsWidth:starWidth" v-lazy="item.img" alt="">
                <!-- 遮罩 -->
                <div class="mask-full-box" v-show="!item.flag"></div>
                <img @click="mask(item)" class="play-btn" v-show="item.flag" src="@/assets/img/about/star/playBtn.png" alt=""/>
                <div class="words-box" v-show="item.flag">
                    <span>{{item.title}}</span>
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div id="mask" v-show="isPay">
            <div class="video-box">
                <div class="title">
                    <div>{{videoData.title}}：{{videoData.name}}为知悟助力</div>
                    <img src="@/assets/img/about/star/close.png" @click="close" alt="">
                </div>
                <div class="video">
                    <video ref="videoDom" class="comVideo" controls loop autoplaywebkit-playsinline="true" playsinline="true">
                        <!-- <source :src="videoData.src" type="video/mp4"> -->
                    </video> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import star1 from "@/assets/img/about/star/star1.png";
import star2 from "@/assets/img/about/star/star2.png";
import star3 from "@/assets/img/about/star/star3.png";
import star4 from "@/assets/img/about/star/star4.png";
import star5 from "@/assets/img/about/star/star5.png";
import star6 from "@/assets/img/about/star/star6.png";
import star7 from "@/assets/img/about/star/star7.png";
import star8 from "@/assets/img/about/star/star8.png";
import star9 from "@/assets/img/about/star/star9.png";
import star10 from "@/assets/img/about/star/star10.png";
import star11 from "@/assets/img/about/star/star11.png";
import star12 from "@/assets/img/about/star/star-words.png";
import star13 from "@/assets/img/about/star/star13.png";
import star14 from "@/assets/img/about/star/star14.png";
import star15 from "@/assets/img/about/star/star15.png";
import star16 from "@/assets/img/about/star/star16.png";
import star17 from "@/assets/img/about/star/star17.png";
import star18 from "@/assets/img/about/star/star18.png";
import star19 from "@/assets/img/about/star/star19.png";
import star20 from "@/assets/img/about/star/star20.png";
import star21 from "@/assets/img/about/star/star21.png";
import star22 from "@/assets/img/about/star/star22.png";
import star23 from "@/assets/img/about/star/star23.png";

// 引入视频
import qiaoting from "@/assets/img/about/star/qiaoting.mp4"
import liujiaxing from "@/assets/img/about/star/liujiaxing.mp4"
import shendanping from "@/assets/img/about/star/shendanping.mp4"
import yinzhe from "@/assets/img/about/star/yinzhe.mp4"
import wangsiyi from "@/assets/img/about/star/wangsiyi.mp4"
import dingyuchen from "@/assets/img/about/star/dingyuchen.mp4"
import chenhong from "@/assets/img/about/star/chenhong.mp4"
import zhouhang from "@/assets/img/about/star/zhouhang.mp4"
import yaojinnan from "@/assets/img/about/star/yaojinnan.mp4"
import siqingaowa from "@/assets/img/about/star/siqingaowa.mp4"
import juhao from "@/assets/img/about/star/juhao.mp4"
import wangji from "@/assets/img/about/star/wangji.mp4"
import zhouzhuoru from "@/assets/img/about/star/zhouzhuoru.mp4"
import zhuqiuying from "@/assets/img/about/star/zhuqiuying.mp4"
import haoyiping from "@/assets/img/about/star/haoyiping.mp4"
import fangqingzhuo from "@/assets/img/about/star/fangqingzhuo.mp4"
import zhangchao from "@/assets/img/about/star/zhangchao.mp4"
import zhengxiaoning from "@/assets/img/about/star/zhengxiaoning.mp4"
import wanglei from "@/assets/img/about/star/wanglei.mp4"
import wangboqing from "@/assets/img/about/star/wangboqing.mp4"
import houyuzhuo from "@/assets/img/about/star/houyuzhuo.mp4"
import baihongbiao from "@/assets/img/about/star/baihongbiao.mp4"

export default {
  name: "StarBlessingCom",
  components: {},
  data() {
    return {
      isPay: false,
      starWidth: "",
      wordsWidth: "",
      singleWidth: "240px",
      videoData:Object(),
      starData: [
        {
          id: 0,
          flag: false,
          img: star1,
          title: "女排世界冠军",
          name: "乔婷",
          src:qiaoting
        },
        {
          id: 1,
          flag: false,
          img: star2,
          title: "主持人",
          name: "刘佳兴",
          src:liujiaxing
        },
        {
          id: 2,
          flag: false,
          img: star3,
          title: "演员",
          name: "沈丹萍",
          src:shendanping
        },
        {
          id: 3,
          flag: false,
          img: star4,
          title: "导演",
          name: "尹哲",
          src:yinzhe
        },
        {
          id: 4,
          flag: false,
          img: star5,
          title: "演员",
          name: "王思懿",
          src:wangsiyi
        },
        {
          id: 5,
          flag: false,
          img: star6,
          title: "著名演员",
          name: "丁宇辰",
          src:dingyuchen
        },
        {
          id: 6,
          flag: false,
          img: star7,
          title: "花样滑冰冠军",
          name: "陈宏",
          src:chenhong
        },
        {
          id: 7,
          flag: false,
          img: star8,
          title: "著名演员",
          name: "周航",
          src:zhouhang
        },
        {
          id: 8,
          flag: false,
          img: star9,
          title: "体操世界冠军",
          name: "姚金男",
          src:yaojinnan
        },
        {
          id: 9,
          flag: false,
          img: star10,
          title: "演员",
          name: "斯琴高娃",
          src:siqingaowa
        },
        {
          id: 10,
          flag: false,
          img: star11,
          title: "国家一级演员",
          name: "句号",
          src:juhao
        },
        {
          id: 11,
          flag: false,
          img: star12,
          title: "",
          name: "",
          src:''
        },
        {
          id: 12,
          flag: false,
          img: star13,
          title: "著名演员",
          name: "王姬",
          src:wangji
        },
        {
          id: 13,
          flag: false,
          img: star14,
          title: "著名演员",
          name: "周卓如",
          src:zhouzhuoru
        },
        {
          id: 14,
          flag: false,
          img: star15,
          title: "体操世界冠军",
          name: "朱秋颖",
          src:zhuqiuying
        },
        {
          id: 15,
          flag: false,
          img: star16,
          title: "演员",
          name: "郝一平",
          src:haoyiping
        },
        {
          id: 16,
          flag: false,
          img: star17,
          title: "著名演员",
          name: "方青卓",
          src:fangqingzhuo
        },
        {
          id: 17,
          flag: false,
          img: star18,
          title: "演员",
          name: "张潮",
          src:zhangchao
        },
        {
          id: 18,
          flag: false,
          img: star19,
          title: "著名演员",
          name: "郑晓宁",
          src:zhengxiaoning
        },
        {
          id: 19,
          flag: false,
          img: star20,
          title: "击剑冠军 王磊",
          src:wanglei
        },
        {
          id: 20,
          flag: false,
          img: star21,
          title: "著名演员",
          name: "王铂清",
          src:wangboqing
        },
        {
          id: 21,
          flag: false,
          img: star22,
          title: "跆拳道世界冠军",
          name: "候玉琢",
          src:houyuzhuo
        },
        {
          id: 22,
          flag: false,
          img: star23,
          title: "著名演员",
          name: "白红标",
          src:baihongbiao
        }
      ]
    };
  },
  methods: {
    //停止页面滚动
    stopMove(){
        let m = function(e){e.preventDefault();};
        document.body.style.overflow='hidden';
        document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    //开启页面滚动
    Move(){
        let m =function(e){e.preventDefault();};
        document.body.style.overflow='';//出现滚动条
        document.removeEventListener("touchmove",m,{ passive:true });
    },
    mask(item) {
        this.videoData = item;
        this.$refs.videoDom.src = item.src;
        this.isPay = !this.isPay;
        this.stopMove()
    },
    close(){
        this.isPay = !this.isPay;
        this.$refs.videoDom.src = '';
        this.Move();
    },
    showIntro(index) {
      if (index == 11) return;
      this.starData[index].flag = true;
    },
    hideIntro(index) {
      this.starData[index].flag = false;
    }
  },
  mounted() {
    const w = this.$refs.attrRef.offsetWidth;
    const width = parseInt(Number(w) / Number(8));
    this.starWidth = "width:" + width + "px";
    let offsetWidth = document.documentElement.clientWidth;
    this.wordsWidth = "width:" + (offsetWidth * 480) / 1920 + "px";
  }
};
</script>
<style scoped lang="less">
.comVideo:focus {
    outline:none;
}
video::-webkit-media-controls-play-button {
    display: block;
}
//视频播放弹窗
#mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index:100000;
}
.video-box {
  background: #fff;
  width: 900px;
  height: 540px;
  z-index: 999;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  padding:0px 28px 32px;
  box-sizing:border-box;
  .title{
    font-size: 24px;
    color: #2F2F2F;
    margin:18px 0 19px;
    display:flex;
    justify-content: space-between;
    img{
        width:26px;
        height:27px;
        cursor: pointer;
    }
  }
  .video{
    width: 845px;
    height: 449px;  
    background:#000;
    .comVideo{
        width: 100%;
        height:100%;
        // object-fit: cover;
    }
  }
}

.star-blessing-com {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .img {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .mask-full-box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0.5;
    }
    .play-btn{
        width:48px;
        height:47px;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        cursor: pointer;
    }
    .words-box {
      width: 100%;
      height: 70px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease-in-out;
      color: #fff;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      span:first-child {
        margin-right: 16px;
      }
    }
  }
}
.star-blessing-com .img:nth-child(12) .mask-full-box {
  opacity: 0;
}
@media only screen and (max-width: 767px) {

  .words-box{
    display: none!important;
  }
  .video-box{
    width: 100%!important;
  }
  .video{
    width: 100%!important;
  }
}
</style>