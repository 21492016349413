<template>
  <div id="development-history">
    <div class="development-history-wrap">
        <div class="title-wrap">
            <img class="title-img" src="@/assets/img/three.png"/>
            <div class="title">发展历程</div>
            <div class="english">Development History</div>
        </div>
        <!-- pc端内容区域 -->
        <div class="hide-class">
            <div class="content-wrap wow animate__fadeInLeft" data-wow-delay="1000" data-wow-duration="1s">
                <!-- update by chenlixia -->
                <div class="accordion">
                    <div class="tab" v-for="(item,index) in developmentData" :key="index"  @mouseenter="showContent(index)" :style="item.developmentFlag == false ?'':'width:864px;'">
                        <img :src="item.bgSrc"/>
                        <div class="title" :class="item.developmentFlag == true?'title-active':''">{{item.year}}</div>
                        <div class="content">
                            <div class="wrap">
                                <div class="month-content" v-for="(item1) in item.month" :key="item1.id">
                                    <div class="month-left">{{Number(item1.month)}}月</div>
                                    <div class="month-right">{{item1.content}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 上下而求索 -->
            <div class="content-bottom-wrap wow animate__fadeInRight">
                <div class="content wow fadeInUp" data-wow-delay="200" data-wow-duration="2s">
                    <div class="img-bg">上下而求索</div>
                    <div class="right-content">
                        <div class="wrap">
                            <img src="@/assets/img/about/shangxia.png" alt="">
                            <div class="content">未来，「知悟」将继续延伸对云计算平台、物联网等科技手段的运用，规划国民生活新业态，以用户思维为指导方向，不断迭代升级知悟生活生态圈，从文化领域出发，深度结合时代科技，引领国人生活方式新趋势，创造兼具中国特色的元宇宙“智享”生活</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 移动端显示 -->
        <div id="mobile-show">
            <!-- 年份切换 -->
            <div class="mobile-wrapper">
                <div class="title-box">
                    <div @click="changeContent(index)" :class="mobileFlag == index ?'title-box-active':''" v-for= "(item,index) in developmentData" :key="index">{{item.numberYear}}年</div>
                </div>
                <div class="detail-box">
                    <div class="wrap">
                        <div class="month-content" v-for="(item,index) in mobileData.month" :key="index">
                            <div class="month-left">{{item.month}}月</div>
                            <div class="month-right">{{item.content}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 上下而求索 -->
            <div class="mobile-top-and-bottom-wrap">
                <div class="title">上下而求索</div>
                <div class="content">
                    <div class="wrap">
                        <img src="@/assets/img/about/shangxia.png" alt="">
                        <div class="detail">未来，「知悟」将继续延伸对云计算平台、物联网等科技手段的运用，规划国民生活新业态，以用户思维为指导方向，不断迭代升级知悟生活生态圈，从文化领域出发，深度结合时代科技，引领国人生活方式新趋势，创造兼具中国特色的元宇宙“智享”生活</div>
                     </div>
                </div>
            </div>
        </div>
    </div>


    <!-- 背景图 -->
    <div class="develop-mountain-bg hide-class"></div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import bg2022 from "@/assets/img/about/historyTitleBg2022.png"
import bg2021 from "@/assets/img/about/historyTitleBg2021.png"
import bg2020 from "@/assets/img/about/historyTitleBg2020.png"
import bg2019 from "@/assets/img/about/historyTitleBg2019.png"
import bg2018 from "@/assets/img/about/historyTitleBg2018.png"
import bg2017 from "@/assets/img/about/historyTitleBg2017.png"
export default {
  name: "DevelopmentHistory",
  components: {},
  data() {
    return {
        mobileFlag:0,
        mobileData:Object(),
        developmentData:[
            {
                id:0,
                year:"二零二二年",
                numberYear:'2022',
                developmentFlag:true,
                bgSrc:bg2022,
                month:[
                    {
                        id:0,
                        month:1,
                        content:"“Z世代”（指1995—2009年间出生的一代人）作为当下主要的消费群体和互联网发声群体，是「知悟」重要的用户群体之一，通过对Z世代的消费、审美偏好调研，「知悟」成立“中国智慧产业发展研究院传统文化产业发展研究中心”、“文创专项孵化基地事业部”，着重发力于Z时代的用户教育，以扩大品牌的潜在影响力"
                    },{
                        id:1,
                        month:2,
                        content:"借势教育改革，「知悟」与相关部门就未成年人的中国传统文化教育问题进行会晤，并督促设立专项基金，成立专项基金管理委员会，由此“中国关心下一代健康体育基金会·传统文化教育基金”正式成立。“传统文化进校园”公益活动被正式纳入“中国校园健康行动”"
                    },{
                        id:2,
                        month:3,
                        content:'获得《增值电信业务经营许可证》、《计算机软件著作权登记证书》；与“北大博雅教育研究院”联合举办“2022年首届传统文化智慧之光产业发展高峰论坛”；获得"中国校园健康行动领导小组办公室”的肯定，联合成立“传统文化知悟教育实验基地”'
                    },{
                        id:3,
                        month:4,
                        content:"与“诸夏周易文化研究院”达成品牌战略合作，为传统文化生活生态圈的构成衔接重要一环"
                    },{
                        id:4,
                        month:5,
                        content:"「知悟」APP通过迭代升级，能够满足和实现用户文化、教育、医疗、文旅、购物等多生活场景使用的需要，填补了市场同类型APP的功能空白，构建了完整的生活生态圈，拓宽了基础产品链，获得iOS、安卓、鸿蒙HarmonyOS应用商店的定向扶持"
                    }
                ]
            },{
                id:1,
                year:"二零二一年",
                numberYear:'2021',
                developmentFlag:false,
                bgSrc:bg2021,
                month:[
                    {
                        id:0,
                        month:7,
                        content:'2021年7月，历经4年沉淀，“知悟”APP框架搭建完成，通过中国传统文化搭载于互联网框架的手段，覆盖衣、食、住、行等生活维度成功覆盖文化、教育、医疗、文旅、购物等各个生活场景，APP功能版块包括文化、医疗、教育、商城、旅游等方面，服务国人的互联网“智享”生活。'
                    },{
                        id:1,
                        month:11,
                        content:'在技术、文化、内容等领域发起“招募专家人才”的活动，并最终确立知悟专家团成员成立注册"北京真源诚传科技有限责任公司"'
                    },{
                        id:2,
                        month:12,
                        content:'经过初审、复审、终审和公示等多重环节的层层筛查及审核，于“中国管理科学院教育标准化研究所”申报的《传统国学与互联网思维》课题被列入国家十四五规划重点课题'
                    }
                ]
            },{
                id:2,
                year:"二零二零年",
                numberYear:'2020',
                developmentFlag:false,
                bgSrc:bg2020,
                month:[
                    {
                        id:0,
                        month:6,
                        content:'知悟中国传统文化生态布局已有完善模型，为实现和合共赢，可持续发展，牵头各民生行业企业，组织“文化复兴产业联盟”——传承红色经典，缔造企业赋能'
                    },{
                        id:1,
                        month:11,
                        content:'后疫情时代，企业发展面临多重挑战，「知悟」顺应时局转变发展思维，提出“轻模式文化发展目标”，通过数字产业、品牌联名IP等文化新业态突破了大环境的影响，平稳发展中仍保持上升势头'
                    }
                ]
            },{
                id:3,
                year:"二零一九年",
                numberYear:'2019',
                developmentFlag:false,
                bgSrc:bg2019,
                month:[
                    {
                        id:0,
                        month:5,
                        content:'为助推中国传统文化的繁荣，助力构建更为紧密的传统文化命运共同体，知悟与各大传统文化机构接触，于江苏无锡举办“传统文化论坛”'
                    },{
                        id:1,
                        month:10,
                        content:'根据《征集2019年“一带一路”文化产业和旅游产业国际合作重点项目的通知》，与沿线国家和地区及头部文旅集团接触，以中国传统文化资源为基础，合作开发中国传统文化＋文旅的功能版块，成立举办“圣魂毅行公益文旅系统”，进一步丰富「知悟」生活生态圈'
                    }
                ]
            },{
                id:4,
                year:"二零一八年",
                numberYear:'2018',
                developmentFlag:false,
                bgSrc:bg2018,
                month:[
                    {
                        id:0,
                        month:2,
                        content:'为助力青少年群体研习中国传统文化，“易学文化空中课堂服务”公益项目正式启动，结合时代条件对中国传统文化加以继承和发扬，赋予中国传统文化新的涵义'
                    }
                ]
            },{
                id:5,
                year:"二零一七年",
                numberYear:'2017',
                developmentFlag:false,
                bgSrc:bg2017,
                month:[
                    {
                        id:0,
                        month:3,
                        content:'「知悟」创始人何志强教授，联合中智传统文化产业发展研究院中心副主任宋国梁，中智传统文化产业发展研究中心主任朱红柳创立「知悟」，以传承中华文脉，探索传统文化在新时代的新应用为主要宗旨'
                    },{
                        id:1,
                        month:7,
                        content:'三星数据系统（西安）技术潘文军总监掌舵，成立技术团队，搭建“知悟”APP理念框架，通过知悟云技术支持系统结合中国传统文化云端智库大数据库，结合前期关于国人对于中国传统文化认知差异的调查分析报告，构建更适合国人的生活指南生态'
                    }
                ]
            }
        ],
    };
  },
  methods: {
    test(){
        // console.log("3454")
    },
    //切换发展历程的内容fun
    showContent(index){
        this.developmentData.forEach((item)=>{
            item.developmentFlag = false;
            
        })
        this.developmentData[index].developmentFlag = true;
    },
    //移动端切换内容
    changeContent(index){
        this.mobileData = this.developmentData[index];
        this.mobileFlag = index;
    },
    
  },
  mounted(){
    this.mobileData = this.developmentData[0];
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 200, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: false, //对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  }
};
</script>
<style scoped lang="less">
#development-history{
    overflow: hidden;
    background:#F9F9F9;
    padding-bottom:80px;
    box-sizing: border-box;
    position:relative;
    .develop-mountain-bg{
        width:550px;
        height:523px;
        background:url("@/assets/img/about/developMountainBg.png") no-repeat center 0;
        position:absolute;
        top:200px;
        left:0;
        z-index:1;
    }
}
.title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #54462f;
  font-size: 28px;
  position: relative;
  .title {
    margin-top: 9px;
  }
  .english {
    font-size: 14px;
    margin-top: -6px;
  }
  .title-img {
    width: 42px;
    height: 52px;
  }
}
.development-history-wrap {
    max-width:1200px;
    margin:0 auto;
    padding-top:40px;
    box-sizing: border-box;
    // pc端
    .content-wrap{
        width:1200px;
        height:470px;
        background:#fff;
        margin-top:50px;
        .accordion{
            width: 100%;
            height: 100%;
            // background-color: yellow;
            overflow: hidden;
            font-size: 0;
            display: flex;
            justify-content: space-between;
        }
        /*对每一个li设置为inline-block,让其排列在一行,溢出隐藏,因为.tab下面的.content宽度为360,而且.tab只有在hover的时候宽度才会变成450px,那时候.content刚好显示.另外设置过渡,使其宽度增长的过程平缓*/
        .tab{
            width:54px;
            height: 100%;
            display: inline-block;
            position: relative;
            margin: 0;
            overflow: hidden;
            transition: all .5s ease .1s;
            z-index:10;
            img{
                display:block;
                width:54px;
                height:470px;
                position:absolute;
                top:0;
                left:0;
            }
            .title{
                width:54px;
                height:470px;
                font-size:22px;
                color:#fff;
                position:absolute;
                top:0;
                left:0;
                line-height:54px;
                writing-mode:vertical-lr;
                padding-top:38px;
                box-sizing: border-box;
                background-size:54px 470px;
                cursor: pointer;
            }
            .title-active{
                color:#EBC68A;
            }
            .content{
                position: relative;
                width: 810px;
                height: 100%;
                color: #333;
                margin-left: 54px;
                padding:48px 40px;
                background:url("@/assets/img/about/historyContent.png") no-repeat center 0px;
                box-sizing:border-box;
                .wrap{
                    height:100%;
                    overflow-y: auto;
                }
                .month-content{
                    display: flex;
                    margin-top:22px;
                    cursor: default;
                    .month-left{
                        width:7%;
                        font-size:16px;
                        font-weight:bold;
                    }
                    .month-right{
                        text-align:justify;
                        padding-right:26px;
                        box-sizing:border-box;
                        width:93%;
                        font-size:16px;
                        text-align:justify;
                    }
                }
                .month-content:first-child{
                    margin-top:0px;
                }
            }
        }
        .tab .content{
            
        }
    }
    
    .content-bottom-wrap{
        height:310px;
        // background:red;
        margin-top:50px;
        display:flex;
        position: relative;
        .content{
            width:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            .img-bg{
                width:70px;
                height:220px;
                background:url("@/assets/img/about/shangxiaerqiusuo.png"); 
                font-size:22px;
                writing-mode: vertical-lr;
                line-height:70px;
                text-align:center;
                color:#C2A16C;
                
            }
            .right-content{
                width:1110px;
                display:flex;
                background:url("@/assets/img/about/shangxiaBg.png") no-repeat center 0px;
                background-size:1110px;
                padding:15px;
                box-sizing: border-box;
                .wrap{
                    background:#F3F3F5;
                    display:flex;
                }
                img{
                    display:block;
                    width:560px;
                    height:280px;
                    margin-right:36px;
                }
                .content{
                    padding:0 36px;
                    box-sizing:border-box;
                }
            }
        }
    }
    .content-bottom-wrap:before,.content-bottom-wrap:after{
        content:"";
        display:block;
        width:350px;
        height:60px;
        background:#C2A16C;
        opacity:0.3;
        position:absolute;
        top:0;
        bottom:0;
        margin:auto;
    }
    .content-bottom-wrap:before{
        left:-365px;
    }
    .content-bottom-wrap:after{
        right:-365px;
    }
    //移动端
    #mobile-show{
        display:none;
    }
    .mobile-wrapper{
        box-sizing: border-box;
        .title-box{
            height:50px;
            line-height:50px;
            margin-top:20px;
            font-size:16px;
            display:flex;
            justify-content: space-between;
            color:#fff;
            padding:0 10px 0;
            box-sizing: border-box;
            background:url("@/assets/img/about/mobileBg.png") no-repeat center;
            background-size:100%;
        }
        .detail-box{
            width:100%;
            height:440px;
            padding:26px 26px;
            box-sizing: border-box; 
            background:url("@/assets/img/about/mobileContentBg.png") no-repeat center 0px;
            background-size:100%;
            .wrap{
                height:100%;
                overflow: scroll;
            }
            .month-content{
                display: flex;
                margin-top:22px;
                .month-left{
                    width:7%;
                }
                .month-right{
                    // width:693px;
                    width:93%;
                    font-size:14px;
                }
            }
        }
    }
    .mobile-wrapper .title-box-active{
        color:red;
    }
    .mobile-top-and-bottom-wrap{
        display:flex;
        flex-direction: column;
        align-items: center;
        .title{
            width:220px;
            height:70px;
            background:url("@/assets/img/about/mobileTopTitle.png") no-repeat; 
            font-size:22px;
            line-height:70px;
            text-align:center;
            color:#C2A16C;
            margin:20px 0;
        }
        .content{
            width:100%;
            box-sizing: border-box;
            .wrap{
                width:100%;
                height:440px;
                background:url("@/assets/img/about/mobileContentBg.png") no-repeat center 0px;
                background-size: 100%;
                padding:40px 20px 10px;
                box-sizing:border-box;
                display:flex;
                flex-direction: column;
                align-items: center;
            }
            img{
                display:block;
                width:300px;
                margin-bottom:20px;
            }
        }
    }
}
// pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
    .hide-class{
        display:none;
    }
    .development-history-wrap #mobile-show{
        display:block;
    }
    .development-history-wrap .mobile-wrapper .detail-box{
        background:#fff;
    }
    .development-history-wrap .mobile-top-and-bottom-wrap .content .wrap{
        background:#fff;
    }
    .development-history-wrap .mobile-top-and-bottom-wrap .content img{
        width:auto;
    }
}
// 手机端
@media only screen and (max-width: 767px) {
    .hide-class{
        display:none;
    }
    .development-history-wrap #mobile-show{
        display:block;
    }
    .development-history-wrap .mobile-wrapper .detail-box .month-content .month-left{
        width:14%;
    }
    .development-history-wrap .mobile-wrapper .detail-box .month-content .month-right{
        width:80%;
    }
}
</style>