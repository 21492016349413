<template>
  <div class="dynamicDetail">
    <h2>个人简介</h2>
    <p>知悟（北京真源诚传科技有限责任公司）创始人</p>
    <div>
      <h6>摘要：</h6>
      <p>
        何志强，1984年生，2022年7月1日，在清华大学商学院哲学专业学习，修完硕士研究生全部课程，获得硕士研究生毕业证书。中国国学文化特色生活指南平台——「知悟」创始人，中国关心下一代健康体育基金会传统文化教育基金副主任兼秘书长，中国管理科学研究院客座教授，北京大学博雅教育研究院客座教授，中慈民族医药研究院副院长，国际玄道养生研究院副院长，全国卫生产业企业管理协会健康产业食品管理委员会副秘书长，国际易经协会副主席，中华周易名家高端论坛副主席，国际玄道养生研究院副院长，多家上市公司的企业顾问。
      </p>
      <h6>基本信息：</h6>
      <p>
        何志强自9岁时开始接触并学习中国传统儒释道经典，深研中国传统文化数十载，精通道学、丹功、武术、易经、中医等，他是集成道学五大科类、琴棋书画、环境科学人文科学、自然科学于一身的儒释道文化传承者与传播者。
      </p>
      <p>
        何志强凭借自己的对国学的研究，以其对道家文化精髓与中国传统文化思想的精深理解，总结延生出一套基于传统智慧的现代经营与管理理论，并先后以战略顾问等身份为多家上市公司提供决策参谋，更帮助了一批中小企业解决运营中遇到的困难。
      </p>
      <p>
        由于在国学智慧方面突出贡献，被中国管理科学研究院特聘为客座教授（国学智慧），同时，亦担任北京大学元培智库中华学委会客座教授、中国人民大学客座教授。如今中管院专业研究和应用开发涉及经济管理、社会管理、公共管理、文教管理、科技管理、生态环境管理等许多领域，已成为推动中国管理科学发展与管理思想研究的一支不可缺少的重要力量。
      </p>
    </div>
  </div>
</template>

<script>
export default {
   created(){
    
    document.body.scrollTop = document.documentElement.scrollTop = this.scrollTop;
  },
}
</script>

<style lang='less' scoped>
.dynamicDetail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2{
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    font-size: 16px;
    font-weight: bold;
  }
  p{
    line-height: 30px;
  }
}
</style>