import { render, staticRenderFns } from "./LookingAhead.vue?vue&type=template&id=81988026&scoped=true&"
import script from "./LookingAhead.vue?vue&type=script&lang=js&"
export * from "./LookingAhead.vue?vue&type=script&lang=js&"
import style0 from "./LookingAhead.vue?vue&type=style&index=0&id=81988026&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81988026",
  null
  
)

export default component.exports