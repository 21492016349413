<template>
  <div class="dynamic-wrapper">
    <!-- 顶部轮播 -->
    <div class="top-carousel-wrapper">
      <div
        class="left-words-item wow animate__slideInLeft"
        data-wow-delay="1000"
        data-wow-duration="2s"
      >
        <div
          class="words-wrap"
          @mouseenter.stop="changeImg2(index)"
          v-for="(item, index) in carouselLeftList"
          :key="index"
        >
          <div
            :class="imgFlag == index ? 'left-border-active' : 'left-border'"
          ></div>
          <div class="right-tips" @click="toDetail(item)">
            <div class="title" :class="imgFlag == index ? 'title-active' : ''">
              {{ item.title }}
            </div>
            <div class="detail">{{ item.detail }}</div>
          </div>
        </div>
      </div>
      <div
        class="right-image-item wow animate__slideInRight"
        data-wow-delay="1000"
        data-wow-duration="2s"
      >
        <div class="img-box">
          <img :src="carouselLeftList[imgFlag].src" alt="" />
        </div>
        <div class="title">{{ carouselLeftList[imgFlag].title }}</div>
      </div>
    </div>
    <!-- 新闻咨询 -->
    <div class="news-wrapper">
      <div class="title-wrap">
        <img class="title-img" src="@/assets/img/bird.png" />
        <div class="title">新闻资讯</div>
        <div class="english">News And Information</div>
        <img
          class="bg-img hide-class"
          src="@/assets/img/dynamic/newsTitle.png"
        />
      </div>
      <div class="content-wrapper">
        <div class="nav-wrapper">
          <span
            @click="changeNav(item.id)"
            v-for="item in navTitle"
            :key="item.id"
            class="nav-words"
            :class="navBar == item.id ? 'nav-words-active' : ''"
            >{{ item.name }}</span
          >
        </div>
        <!-- 内容区域 点击导航只切换接口！ -->
        <div class="main-content-wrapper" ref="mainDiv">
          <!-- 新查看详情 
            v-if="newDetail"-->
          <div v-if="newDetail">
            <div
              @click="toNewDetail(item)"
              class="main-wrapper"
              v-for="item in listData"
              :key="item.websiteCategoryId"
            >
              <div class="img-box">
                <img :src="item.img" />
              </div>
              <div class="content">
                <div class="title">{{ item.title }}</div>
                <div class="detail">{{item.excerpt}}</div>
                <div class="button" @click="toNewDetail(item)">查看详情</div>
              </div>
            </div>
          </div>
          <div
            @click="toDetail(item)"
            class="main-wrapper"
            v-for="item in contentData"
            :key="item.id"
          >
            <div class="img-box">
              <img :src="item.src" />
            </div>
            <div class="content">
              <div class="title">{{ item.title }}</div>
              <div class="detail">{{ item.detail }}</div>
              <div class="button" @click="toDetail(item)">查看详情</div>
            </div>
          </div>
        </div>
        <!-- 背景图 -->
        <img class="mountain hide-class" src="@/assets/img/mountain.png" />
        <img class="boat hide-class" src="@/assets/img/boat.png" />
      </div>
      <!-- loading more -->
      <!-- <div class="load-more" @click="loadMore"></div> -->
    </div>
    <ScrollTop />
  </div>
</template>
<script>
//轮播插件
import { WOW } from 'wowjs'
import dynamicImg1 from '@/assets/img/dynamic/dynamicImg1.png'
import dynamicImg2 from '@/assets/img/dynamic/dynamicImg2.png'
import dynamicImg3 from '@/assets/img/dynamic/dynamicImg3.png'
import news1 from '@/assets/img/dynamic/news1.png'
import news2 from '@/assets/img/dynamic/news2.png'
import news3 from '@/assets/img/dynamic/news3.png'
import news4 from '@/assets/img/dynamic/news4.png'
import industry1 from '@/assets/img/dynamic/industry1.png'
import industry2 from '@/assets/img/dynamic/industry2.png'
import industry3 from '@/assets/img/dynamic/industry3.png'
import policyImg1 from '@/assets/img/dynamic/policyImg1.png'
import policyImg2 from '@/assets/img/dynamic/policyImg2.png'
import policyImg4 from '@/assets/img/dynamic/policyImg4.png'
import ScrollTop from '@/components/scrollTop/ScrollTop.vue'
import { getdata, getLink } from '@/api/getapi.js'
// import {postdata} from "@/api/postapi.js"
export default {
  name: 'DynamicView',
  components: {
    ScrollTop,
  },
  data() {
    return {
      index: 0,
      imgFlag: 0,
      navBar: 0,
      newDetail: true,
      navTitle: [
        {
          id: 0,
          name: '知悟动态',
          flag: false,
        },
        {
          id: 1,
          name: '行业新闻',
          flag: false,
        },
        {
          id: 2,
          name: '政策要闻',
          flag: false,
        },
      ],
      listData: [],
      contentData: [],
      zhiWuDynamic: [
        {
          id: 1,
          src: require('@/assets/img/dynamic/dynamicDetail/newImg8.jpeg'),
          title:
            '盛行天下·汇聚八方——“知悟APP·灵玥康体新零售财富峰会”在东阿成功举办',
          detail:
            '2023年6月6日星期二，盛行天下·汇聚八方——“知悟APP·灵玥康体新零售财富峰会”在山东省东阿县盛大举行，东阿地方政府领导、知悟股东及母公司北京真源诚传科技有限责任公司...',
          detailTitle: '',
          time: '',
          detailList: [
            {
              id: 1,
              title:
                '盛行天下·汇聚八方——“知悟APP·灵玥康体新零售财富峰会”在东阿成功举办',
            },
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg8.jpeg'),
            },
            {
              detail: [
                {
                  id: 3,
                  content:
                    '2023年6月6日星期二，盛行天下·汇聚八方——“知悟APP·灵玥康体新零售财富峰会”在山东省东阿县盛大举行，东阿地方政府领导、知悟股东及母公司北京真源诚传科技有限责任公司（本文简称：真源诚传）高管团队、知悟兄弟公司及大健康新零售生产基地——山东东阿润合生阿胶制品有限公司（本文简称：润合生）领导团队列席，与来自全国各地的企业家、创业家和行业精英等共同见证了“灵玥康体新零售财富峰会”的成功举办，分享新时代背景下的财富逻辑与机遇。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg9.jpeg'),
            },
            {
              detail: [
                {
                  id: 3,
                  content:
                    '“万户喜鹊吉祥地，千年阿胶福寿乡”。“知悟APP·灵玥康体新零售财富峰会”于6月6日在知悟兄弟公司及大健康新零售生产基地润合生的总部所在地、中国文化历史名城、“阿胶之乡”——山东东阿举办。本次会议活动得到了当地政府领导的热切欢迎和大力支持，原东阿县政协主席朱风泽，原东阿县县委常委、政法委书记杨长林莅临本次活动。',
                },
              ],
            },
            {
              id: 4,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg10.jpeg'),
            },
            {
              detail: [
                {
                  id: 5,
                  content:
                    '朱风泽在特别讲话中对知悟（真源诚传）与润合生的战略合作表示高度赞赏，并表示：对于本地优秀的企业，要加大力度“推出去”；对于外面的优秀企业，要热情诚恳地“引进来”，知悟大健康新零售的到来，是一个很好的开端。“东阿县的地方工作班子会高度重视，也会给予最大的支持和帮助，让知悟大健康新零售平台公司在东阿扎根、茁壮成长。”',
                },
              ],
            },
            {
              id: 6,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg11.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '会议开始，真源诚传董事长朱柳红女士发表了热烈而诚挚的致辞。知悟作为一家年轻而富有朝气的企业，已经在过去的两年中取得了不小的成绩，这离不开各方合作伙伴和关注知悟的朋友的信任和支持。而知悟也将脚踏实地，专注于事业的健康、长远发展，以为股东提供可持续回报、与合作伙伴共同成长壮大为目标，为社会源源不断地创造价值。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg12.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '作为知悟联合创始人之一，李浩然先生向与会的领导和嘉宾讲述了知悟的创始渊源，就知悟的起源、创立初心及使命，为与会嘉宾带来了一场精彩的分享。知悟生态源自于以何志强教授为核心的创始团队对中国传统文化智慧力量的挖掘和实践。中国传统文化的力量，可以帮助我们乃至整个世界去追求更好的生活方式和文明生态。基于这样的认知和理念，2017年，知悟的服务平台理念框架设计出炉，历经5年时间，于2022年推出了一款包容中国传统智慧与特色的现代人智慧生活指南APP——「知悟」。',
                },
                {
                  id: 2,
                  content:
                    '在演讲中，李浩然特地提到：“知悟的一大重要的业务模块便是以传统医学为基础的健康服务模块，设立初心就是帮助人们更好地了解健康知识，提高健康素养。我们希望通过智能科技的手段，将中医养生等国粹智慧传递给每一个用户，让健康知识更加普及，让每个人都能够获得健康的生活方式。”他的演讲引发了与会嘉宾的共鸣。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg13.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '随后，知悟CEO宋国梁先生对知悟平台的商业模式、平台生态及产品功能、市场政策等进行了详细的阐述。他表示：“知悟的商业模式设计出发点，就是顾全供给端与需求端的双向利益，而非以自身利益为先，让利于商家与消费者，以真诚协调供需，以品质重塑消费市场信誉，进一步推动行业的自律性发展，最终实现“良性”生态的构建。而我们的大健康新零售模块，是通过技术手段将健康知识和健康产品有效结合，为用户提供全方位的健康服务。”',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg14.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '当日下午，知悟大健康·灵玥康体新零售联合创始人王继敏对灵玥康体新零售业务板块运作及市场模式做了详细介绍。灵玥康体，是知悟在新零售和大健康两个模块开启了全新的商业模式探索。它的成立意义重大，代表了“九商合一”，预示未来流量越来越多、转化力越来越强，同时生态化的服务也将越来越完善、越来越便捷。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg15.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '知悟大健康·灵玥康体新零售创始人姚灵玥就灵玥康体的创建、发展的心路历程做了精彩的分享，她表示：“灵玥康体新零售是一个以健康为主题的新零售平台，我们将健康产品和智能科技有机结合，为用户带来全新的消费体验。未来，我们将继续加大投入，不断创新，为用户提供更好的服务。”',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg16.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '知悟创始人何志强教授总结道：知悟平台的业务覆盖文化、教育、医疗、旅游、购物等各个生活场景，利用全方位的“互联网+”生态，融合传统文化与时代科技，开拓全新的生活理念生态圈，以图用传统文化智慧结晶，覆盖服务中国人衣食住行的全维度生活场景，打造具有全新高度的商业与生活服务生态。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg17.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '何志强教授表示：灵玥康体新零售的开启，意味着知悟大健康能将好的商品、有价值有特色的产品平价化，让普通老百姓也能体验出厂价、享受到优质的产品和切实的实惠。因此，知悟团队认为，它不仅仅是一个商业模式，也不仅仅是一门生意，更是一个利国利民、服务社会的崇高事业。知悟团队将永葆初心，将这份事业越做越大、越做越长远，吸引并容纳更多有理想、有抱负的有识之士，一同前行。',
                },
                {
                  id: 1,
                  content:
                    '本次“知悟APP·灵玥康体新零售财富峰会”圆满结束，不仅为参会嘉宾带来了精彩的分享和交流，还展现了知悟的实力和未来发展的巨大潜力。相信在知悟团队的不断努力下，能与更多同频人士合流共创、携手并进，知悟·灵玥康体新零售将在未来的市场竞争中取得更大的成功，推动大健康产业的发展。',
                },
              ],
            },
          ],
        },

        {
          id: 1,
          src: require('@/assets/img/dynamic/dynamicDetail/newImg1.jpeg'),
          title: '知悟APP | 知悟股东团考察知悟新零售东阿阿胶生产基地',
          detail:
            '知悟股东团前往山东东阿参观考察知悟大健康新零售东阿生产基地、知悟持股兄弟企业、战略合作伙伴——山东东阿润合生阿胶制品有限公司。',
          detailTitle: '',
          time: '',
          detailList: [
            {
              id: 1,
              title: '知悟APP | 知悟股东团考察知悟新零售东阿阿胶生产基地',
            },
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg1.jpeg'),
            },
            {
              detail: [
                {
                  id: 1,
                  content:
                    '6月5日，知悟团队携股东团前往山东东阿参观考察知悟大健康新零售东阿生产基地、知悟持股兄弟企业、战略合作伙伴——山东东阿润合生阿胶制品有限公司。此次考察旨在深入了解润合生的企业文化、历史、运营、管理和生产等，以更全面地了解阿胶文化及产品和品牌价值。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg2.jpeg'),
            },
            {
              detail: [
                {
                  id: 3,
                  content:
                    '山东东阿润合生阿胶制品有限公司（本文简称：润合生）是东阿知名企业，也是中国阿胶行业的领军企业之一，拥有先进的生产技术、领先行业的创新能力和雄厚的实力，是一家非常具有实力和创新精神的现代化阿胶研发生产企业，生产的阿胶产品品质优良、口感纯正，是高品质阿胶的代表，深受消费者喜爱。',
                },
              ],
            },

            {
              id: 4,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg3.jpeg'),
            },
            {
              id: 5,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg4.jpeg'),
            },
            {
              detail: [
                {
                  id: 6,
                  content:
                    '此次考察行程主要参观了阿胶博物馆，润合生现代化生产线等，在润合生团队的接待和陪同下，知悟股东团详实而生动地了解到东阿阿胶的历史文化，及其在中国医药健康历史中的中药地位，并对润合生的生产研发能力有了直接的认知。',
                },
              ],
            },
            {
              id: 7,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg5.jpeg'),
            },
            {
              detail: [
                {
                  id: 8,
                  content:
                    '润合生占地170余亩，先后取得六大项十余种食品生产许可，2018年通过了HACCP质量体系认证，产品质量得到了广大消费者的认可和信赖。同时，公司先后被评为中国食品报食安山东重点宣调企业称号，2018年获得东阿县阿胶协会发起人“副会长”资格，并于同年12月在首届健康中国年会第四节营养健康食品产业高峰论坛获得“2018阿胶十大品牌”荣誉称号。',
                },
              ],
            },

            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg6.jpeg'),
            },
            {
              detail: [
                {
                  id: 4,
                  content:
                    '作为知悟的战略联盟伙伴、大健康生产基地及相互持股的兄弟企业，润合生以务实的实干精神和高标准的匠人精神作为知悟大健康新零售的战略支撑，将为知悟大健康产业版图布局提供强大的支持。',
                },
              ],
            },
            {
              id: 6,
              img: require('@/assets/img/dynamic/dynamicDetail/newImg7.jpeg'),
            },
            {
              id: 5,
              detail: [
                {
                  content:
                    '据悉，除了与润合生共创新零售基地外，知悟还计划在润合生阿胶生产基地投入数亿元，规划170余亩地，成立知悟的康养基地，以完善在大健康生态版图的布局。知悟康养基地将提供高品质的康养服务，包括康复、养生、健康饮食、健康管理等一系列业务规划。此外，知悟还将通过科技手段打造智能化的康养基地，为消费者提供更为便捷、舒适的康养体验。该计划的实施将进一步加强知悟在大健康产业领域的竞争优势，为消费者带来更好的健康服务。',
                },
              ],
            },
          ],
        },

        {
          id: 1,
          src: news1,
          title: '战略合作是北京真源诚传的一小步，也是健康中国的一大步',
          detail:
            '2022年4月24日，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导与知美口腔医院达成了初步的战略合作协议。',
          detailTitle:
            '战略合作 | 是北京真源诚传的一小步，也是健康中国的一大步',
          time: '',
          detailList: [
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/detail3.png'),
            },
            {
              id: 1,
              title: '与自己修“心” | 积硅步以至千里',
            },
            {
              id: 2,
              detail: [
                {
                  id: 1,
                  content: '文化中国，健康中国。',
                },
                {
                  id: 2,
                  content:
                    '在目前全球疫情严重的大环境下，文化与健康已经成为人们常见的字眼。一个是物质基础，一个是精神需求，二者缺一不可，相辅相成，都是强壮自身的武器。',
                },
                {
                  id: 3,
                  content:
                    '文化是一种温暖人心的力量，无论是用文化凝聚起战疫必胜的力量，还是以文化为媒介助力脱贫攻坚、乡村振兴，生发出扶智与扶志的新力量。',
                },
                {
                  id: 4,
                  content:
                    '而十九届五中全会要求“全面推进健康中国建设”，并明确提出到2035年要建成“健康中国”的远景目标，在新时代，无论是中医、道医等传统文化，还是西医现代化手段，只有相互拧成一股力量，才能真正进行创新性转化和创造性发展，为“文化中国，健康中国”的宏伟目标做出更大的贡献。',
                },
                {
                  id: 5,
                  content:
                    '随着互联网，大数据等新一代技术的广泛应用和价值的不断延伸。北京真源诚传科技有限责任公司抓住国家战略、民生需求的文化复兴机遇与趋势，通过一系列的重要举措，携旗下产品《知悟》APP，持续发挥互联网+传承传统文化战略优势，助力国家“文化中国”的建设。',
                },
                {
                  id: 6,
                  content:
                    '2021年，公司在中国管理科学研究院教育标准化研究所申报的《传统国学与互联网应用》课题被列入国家“十四五”规划重点课题。',
                },
                {
                  id: 7,
                  content:
                    '2022年1月，正式成立中国智慧产业发展研究院传统文化产业发展研究中心，“文创专项孵化基地事业部”',
                },
                {
                  id: 8,
                  content:
                    '同年4月，中国关心下一代健康体育基金会传统文化教育基金正式成立；“传统文化进校园”公益活动正式纳入“中国校园健康行动”。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail4.png'),
            },
            {
              id: 4,
              title: '与大咖论“道” | 合则两利，深耕健康文化',
            },
            {
              id: 5,
              detail: [
                {
                  content:
                    '与此同时，为了进一步深耕健康文化领域，促进大健康领域于传统文化领域的相结合，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导慎重选择了知美口腔医院作为事业合作伙伴。',
                },
                {
                  content:
                    '知美口腔医院由知美（香港）实业有限公司直接控股，以成都为中心，在中国多个省，市，县均建立知美口腔运营中心及门店。以香港总公司的口腔专家，华西医院以及中医药大学专家为技术后盾，以成都大学医学院实习基地、四川大学华西医学院口腔修复制作中心、成都中医药大学口腔学院等多家合作单位为外脑，自有材料加工厂、仓储中心、进口知名品牌设备及材料等强大的供应体系和国内外精英专业团队，为客户提供特色一对一口腔健康服务，力求打造成中国口腔行业的标杆。',
                },
              ],
            },
            {
              id: 6,
              img: require('@/assets/img/dynamic/dynamicDetail/detail5.png'),
            },
            {
              id: 7,
              detail: [
                {
                  content:
                    '此次战略合作，不止是北京真源诚传科技有限责任公司与知美口腔医院的合作共赢，也是一座在“弘扬传统文化”与“现代智慧生活”的基础上搭建的共同发展的桥梁，更是一座践行“文化中国，健康中国”发展政策的里程碑。',
                },
              ],
            },
            {
              id: 8,
              img: require('@/assets/img/dynamic/dynamicDetail/detail6.png'),
            },
          ],
        },
        {
          id: 2,
          src: news2,
          title: '著名品牌战略专家翁向东为知悟高级顾问，为知悟助力！',
          detail:
            '近日，在知悟创始人何志强教授的邀请下翁向东先生来到知悟苏州运营中心，与知悟创始人何志强教授、董事长朱红柳女士、总裁宋国梁先生进行了深度的交流。',
          detailTitle:
            '著名品牌战略与营销广告专家翁向东为知悟高级顾问，为知悟助力！',
          time: '2022-05-11 18:29 发表于江苏',
          detailList: [
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/detail7.png'),
            },
            {
              id: 1,
              detail: [
                {
                  id: 0,
                  content:
                    '翁向东：著名品牌战略与营销广告专家。上海浦东新区十大杰出青年、中国十大策划专家（中国生产力学会评定）、影响中国品牌进程的50大风云人物、上海杰信咨询有限公司总经理，演讲与培训足迹遍及清华、交大、复旦、同济、上海财大等高校。',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/detail8.png'),
            },
            {
              id: 3,
              detail: [
                {
                  id: 0,
                  content:
                    '在品牌策划与咨询实践中，翁向东以擅长品牌战略规划和低成本营销广告策略而闻名企业界。他注重科学市场调查，能迅速为品牌找到差异点，进入无竞争领域，并以新奇的创意和锐利的战术推广低成本提升销售，持续累积品牌资产。翁老师先后为昆仑润滑油、海信、宝娜斯袜业、格兰仕、沱牌、夏新、范笛诗眼睛、舍得酒、金娃、衡水老白干、乐百氏、锦帛尔家纺、佳乐等著名企业提供了卓越的品牌战略与整合营销传播策划服务。',
                },
              ],
            },
            {
              id: 4,
              img: require('@/assets/img/dynamic/dynamicDetail/detail9.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '近日，在知悟创始人何志强教授的邀请下翁向东先生来到知悟苏州运营中心，与知悟创始人何志强教授、董事长朱红柳女士、总裁宋国梁先生进行了深度的交流。',
                },
                {
                  content:
                    '在知悟高层领导的邀请下翁向东先生表示愿意加入并成为知悟的高级顾问，为知悟助力。',
                },
              ],
            },
            {
              id: 5,
              img: require('@/assets/img/dynamic/dynamicDetail/detail10.png'),
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '格局决定布局，布局决定结局，知悟筑巢引凤，越来越多高人加入知悟，同频共振带领知悟更上一层楼。',
                },
                {
                  content:
                    '知悟是一个专注传统国学文化的传承，发展与现代互联网结合的平台。它融合了中国易经知识、哲学、数字学、养生、社交等，将易经文化，传统国学智慧巧妙地与人工智能相结合，通过大数据分析，对人的行为模式、情感、运势、命理、生命密码等进行解析，对传统国学文化进行各个领域的展现，让用户直观的使用学习传统国学文化。并且与上百位国内著名的易经师，健康养生专家共同打造了一对一交流窗口，用户可直接预约专家一对一的交流互动。',
                },
                {
                  content: '知悟发扬传承传统国学，助力国家“文化复兴”的建设。',
                },
              ],
            },
          ],
        },
        {
          id: 3,
          src: news3,
          title: '用经典浸润童年，让智慧点亮人生',
          detail:
            '中华优秀传统文化是中华民族的文化根脉，其蕴含的思想观念、人文精神、道德规范，不仅是我们中国人思想和精神的内核，对解决人类问题',
          detailTitle: '用经典浸润童年，让智慧点亮人生',
          time: '2022-05-14 19:46 发表于江苏',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  content:
                    '“中华优秀传统文化是中华民族的文化根脉，其蕴含的思想观念、人文精神、道德规范，不仅是我们中国人思想和精神的内核，对解决人类问题也有重要价值。”习近平总书记指出，“文化自信是更基础、更广泛、更深厚的自信”。',
                },
                {
                  content:
                    '2022年4月由北京真源诚传科技有限责任公司申请的“中国关心下一代健康体育基金会·传统文化教育基金”正式获得批复；中国关心下一代健康体育基金会是经国务院批准，在民政部注册成立的全国性公募基金会，是具有独立法人资格的非营利性社会公益组织。中国关心下一代健康体育基金会由中国关心下一代工作委员会发起，业务主管单位为中华人民共和国教育部。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail11.png'),
            },
            {
              id: 2,
              detail: [
                {
                  id: 0,
                  content:
                    '该基金会自成立以来，得到了党和国家及相关部委领导的重视和关怀。原国家副主席曾庆红、原人大常委副委员长蒋正华、国务委员陈至立、航天英雄杨利伟以及中国关心下一代工作委员会、教育部、民政部、财政部、文化部、国家体育总局、团中央、中央电视台等有关部门均给予了大力支持。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail12.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '知悟创始人何志强介绍：“传统文化教育”专项基金的设立顺应了当前弘扬中华优秀传统文化的号召，专项基金将秉承“让更多青少年儿童走近传统文化艺术，传承传统文化教育”的宗旨，用于支持国学经典、传统文化艺术、民俗文化等青少年教育事业。专项基金还将组建由专家学者、行业协会、媒体机构共同弘扬传统文化，为致力于中华传统教育的学者、机构搭建起学习、交流、合作与展示的平台，用经典浸润孩子的童年，让智慧点亮他们的人生。',
                },
              ],
            },
            {
              id: 5,
              img: require('@/assets/img/dynamic/dynamicDetail/detail13.png'),
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '学而时习，活学活用。知悟也将邀请更多支持中华优秀传统文化传承传播的知名人士、专家学者，助力知悟平台为新时代培养博古通今、与时俱进的国之栋梁。',
                },
              ],
            },
          ],
        },
        {
          id: 4,
          src: news4,
          title: '奇迹集团董事长齐光伟先生成为知悟高级顾问，为知悟助力年',
          detail:
            '《知悟》一款国人生活指南的“APP”；传承国学文化的载体；学习国学文化的平台；弘扬国学文化的通道。',
          detailTitle: '奇迹集团董事长齐光伟先生成为知悟高级顾问 为知悟助力',
          time: '2022-05-16 19:24 发表于江苏',
          detailList: [
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/detail14.png'),
            },
            {
              id: 1,
              detail: [
                {
                  content:
                    '《知悟》一款国人生活指南的“APP”；传承国学文化的载体；学习国学文化的平台；弘扬国学文化的通道。',
                },
                {
                  content:
                    '相信奇迹的人，本身就和奇迹一样了不起！于2022年1月22日，奇迹集团董事长齐光伟先生加入知悟，成为知悟的高级顾问。齐光伟先生一年招商300亿，创造了营销界的天花板，他对知悟给予了高度的认可，并全力支持！',
                },
              ],
            },
            {
              id: 2,
              img: require('@/assets/img/dynamic/dynamicDetail/detail15.png'),
            },
            {
              id: 3,
              detail: [
                {
                  content: '齐光伟：',
                },
                {
                  content: '奇迹集团创始人暨奇迹投资董事长',
                },
                {
                  content: '奇迹和家慈善基金发起人',
                },
                {
                  content: '北京盛基艺术学校名誉校长',
                },
                {
                  content: '全球酷供应链创始人',
                },
                {
                  content: '大连食品安全协会副会长',
                },
                {
                  content: '大连食品安全协会副会长',
                },
                {
                  content: '大连市慈善总会理事会副会长',
                },
                {
                  content: '光伟财商教育咨询有限公司董事长',
                },
                {
                  content: '奇迹文化传媒（大连）有限公司创始人',
                },
                {
                  content: '上海汇根教育科技集团有限公司董事局主席',
                },
              ],
            },
            {
              id: 4,
              img: require('@/assets/img/dynamic/dynamicDetail/detail6.png'),
            },
            {
              id: 5,
              detail: [
                {
                  content:
                    '相信奇迹的人，本身就和奇迹一样了不起，在选择好自己要走的路之后，就要勇敢的走上去，要坚持的走下去。',
                },
                {
                  content:
                    '听党话，跟党走！知悟顺应时代而生，大力弘扬优秀传统文化，为提高民族道德素养；为文化复兴，民族富强；为实现中国梦做贡献。',
                },
              ],
            },
          ],
        },
      ],
      industryNews: [
        {
          id: 0,
          title:
            '打响文化产业品牌 加快建成文化强市 苏州市文化产业首届“东吴奖”评选启动',
          detail:
            '苏州文化产业领军人物”则着重推选在文化产业领域勇于改革、积极探索、开拓创新，带领所在企业实现重大突破和提升',
          src: industry1,
          detailTitle:
            '打响文化产业品牌 加快建成文化强市 苏州市文化产业首届“东吴奖”评选启动',
          time: '2022-07-15 16:12  新华报业网',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  id: 0,
                  content:
                    '7月14日，苏州市文化产业首届“东吴奖”评选活动在第十一届中国苏州文化创意设计产业交易博览会新闻发布会上宣布启动。据悉，首批“苏州领军型文化企业十强”“苏州成长型文化企业十强”有望在年内推出。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail17.png'),
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '自泰伯兴吴以来，依水而生的苏州便是吴文化的发源地和核心区。唐宋元明清，苏州是全国经济文化中心，传统工商业高度发达，“商贾辐辏，百货骈阗”，称为“人间都会最繁华，除是京师吴下有”。如今，延续千年的文脉助推着苏州的文化产业蓬勃发展。2021年，苏州文化产业增加值初步核算1225.92亿元，占GDP比重5.4％；规模以上文化企业1143家，实现营业收入3275亿元；现有10个国家级、25个省级文化产业园区（示范基地）。今年5月，苏州市召开文化企业座谈会。会议强调，要用好苏州文化资源优势，抢抓数字经济时代产业创新集群建设机遇，不断做大做强，坚定不移把文化产业打造成苏州地标产业、支柱产业',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail18.png'),
            },
            {
              id: 4,
              detail: [
                {
                  id: 0,
                  content:
                    '自泰伯兴吴以来，依水而生的苏州便是吴文化的发源地和核心区。唐宋元明清，苏州是全国经济文化中心，传统工商业高度发达，“商贾辐辏，百货骈阗”，称为“人间都会最繁华，除是京师吴下有”。如今，延续千年的文脉助推着苏州的文化产业蓬勃发展。2021年，苏州文化产业增加值初步核算1225.92亿元，占GDP比重5.4％；规模以上文化企业1143家，实现营业收入3275亿元；现有10个国家级、25个省级文化产业园区（示范基地）。今年5月，苏州市召开文化企业座谈会。会议强调，要用好苏州文化资源优势，抢抓数字经济时代产业创新集群建设机遇，不断做大做强，坚定不移把文化产业打造成苏州地标产业、支柱产业。',
                },
              ],
            },
            {
              id: 5,
              detail: [
                {
                  content:
                    '为贯彻落实市委、市政府关于推动文化产业高质量发展的决策部署，全面展现苏州文化产业发展成效，展示苏州文化企业创新发展风貌，增强文化企业和企业家的荣誉感和归属感，苏州市文化产业“东吴奖”评选应运而生。该奖项评选由苏州市委宣传部、苏州市文化广电和旅游局指导，苏州日报社、苏州市文化创意产业联合会联合主办，旨在通过特色鲜明的文化产业奖项评选，驱动文化企业融合创新，助力文化产业高质量发展。',
                },
                {
                  content:
                    '“东吴奖”作为苏州市文化产业奖项，每年评选1次，“苏州领军型文化企业十强”“苏州成长型文化企业十强”和“苏州文化产业领军人物”评选交替进行。评选活动将面向全社会广泛发动、公开征集，多渠道开展报送，依托专家评审、网络投票等方式，确保程序公开、过程透明、公众参与。其中，“苏州领军型文化企业十强”着重考查企业的营业收入、利润、净利润和税收贡献，突出企业自身的规模实力、对行业发展的引领效果、对地区经济发展贡献度，推选出大型骨干文化企业。“苏州成长型文化企业十强”着重考查企业的成长性和创新性，注重企业在促进产业融合、培育新型业态、创新商业模式、创新数字技术等方面的探索，推选出“专、精、特、新”的文化企业。“苏州文化产业领军人物”则着重推选在文化产业领域勇于改革、积极探索、开拓创新，带领所在企业实现重大突破和提升，具有示范引领效果的企业家、投资者、创意设计人才、经营管理人才。符合条件且在本市登记注册、纳入文化产业单位统计目录、具有独立法人资格的文化企业，均可参加评选。',
                },
                {
                  content:
                    '首届“东吴奖”申报工作将由县级市（区）文化产业主管部门组织推荐或文化企业向所属地区的文化产业主管部门自行推荐，经资格审核、专家评审、征求意见、会议审议、公示等环节确定入选名单。苏州市将为入选企业提供金融服务协调、项目申报指导等支持，市属媒体将开展集中推广宣传，以此来更好服务优秀的文化企业，宣传展示苏州文化产业发展成效，吸引更多有潜力、有抱负的文化企业和企业家来苏投资、落户苏州。（邹玉芬）',
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: '绘制文化产业的“姑苏繁华图”，苏州文创设计产业交易博览会今开幕',
          detail:
            '中国文化产业峰会为国内外文化创意企业搭建平台，着力促进文化产业信息资源对接，共商文化产业发展，共享产业发展机遇。',
          src: industry2,
          detailTitle:
            '绘制文化产业的“姑苏繁华图”，苏州文创设计产业交易博览会今开幕',
          time: '2022-07-15 17:05:46　来源: 文汇报 发表于上海',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  content:
                    '今天，第十一届中国苏州文化创意设计产业交易博览会在金鸡湖畔开幕，第二届苏州·中国文化产业峰会同步举行。首批国家夜间文化和旅游消费集聚区——周庄古镇、金鸡湖景区获授牌；20个数字文化产业重点项目签约，涵盖数字创意、影音娱乐、演艺传媒等多个领域，签约总额超过50亿元。',
                },
                {
                  content:
                    '近年来，苏州积极打造数字文化产业高地，全面打响苏州“江南文化”品牌。目前，全市已集聚规模以上文化企业1143家，拥有10个国家级、25个省级文化产业园区（示范基地），2020年获评首批国家文化和旅游消费示范城市。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail19.png'),
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '当前，苏州正积极抢抓数字经济时代发展机遇，加快发展新型文化企业、文化业态、文化消费模式，全力打造数字文化产业创新集群。期待更多优秀企业和团队扎根苏州、加码苏州，推动更多优质项目落地生根，共同把文化产业打造成苏州的“地标产业”，绘制文化产业的“姑苏繁华图”。',
                },
                {
                  content:
                    '据悉，苏州已连续举办10届苏州文博会，促成产业合作项目1300余个，总交易额近400亿元。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail20.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '本届文博会以“数字赋能·创意之都”为主题，围绕文化产业展示、项目招商、产品交易和业界交流四大功能，在线上线下同步进行展示活动。同步举办的苏州·中国文化产业峰会为国内外文化创意企业搭建平台，着力促进文化产业信息资源对接，共商文化产业发展，共享产业发展机遇。.92亿元，占GDP比重5.4％；规模以上文化企业1143家，实现营业收入3275亿元；现有10个国家级、25个省级文化产业园区（示范基地）。今年5月，苏州市召开文化企业座谈会。会议强调，要用好苏州文化资源优势，抢抓数字经济时代产业创新集群建设机遇，不断做大做强，坚定不移把文化产业打造成苏州地标产业、支柱产业。',
                },
                {
                  content:
                    '现场，3位外国专家获聘苏州市艺术创意设计专业技术资格评审委员会特聘专家。',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: '第二届苏州·中国文化产业峰会成功举办',
          detail:
            '峰会现场解读了苏州文化产业招商政策，围绕苏州城市规划和资源特点，全方位展示了苏州丰厚的产业资源和广阔的产业前景',
          src: industry3,
          detailTitle: '第二届苏州·中国文化产业峰会成功举办',
          time: '2022-07-15 17:10:41 来源：中国日报网',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  content:
                    '7月15日，2022第二届苏州·中国文化产业峰会在苏州金鸡湖国际会议中心举办。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail21.png'),
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '本次峰会聚焦数字文化，汇聚了来自中国文化传媒集团、中国数字文化集团、阿里巴巴大文娱和泡泡玛特等国内头部文化企业的创始人及相关负责人。峰会交流分享文化产业发展经验，促进文化产业资源对接，成为国内文化产业创新融合发展的重要平台。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail22.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '本届峰会在2021年首届峰会成功举办的基础上举办，力争通过每年连续举办，将峰会办成具有全国影响力的品牌性文化行业盛会。峰会聚焦当前中国文化产业发展亮点和投资热点，集聚国内文化产业头部资源，发布行业前沿趋势，分享产业创新理念，以此促进行业内人脉汇聚、观点交流、经验互通、资源整合，共同促进文化产业高质量发展。',
                },
                {
                  content:
                    '中数集团、阿里巴巴大文娱、泡泡玛特等重点文化企业围绕数字技术推动文化产业变革、金融推动文化产业跨越发展等议题，通过多场主题演讲、巅峰对话等系列活动，分享文化产业发展最新的观点、理念，以及对文化产业未来发展的展望，为与会者奉上了丰富的文化大餐。纪录片《我在故宫修文物》导演萧寒在会上作了《从故宫修文物到苏州看非遗》的主题分享，为苏州非遗的数字化打开了全新视角。',
                },
                {
                  content:
                    '亲商理念是苏州高速发展的秘诀。峰会现场解读了苏州文化产业招商政策，围绕苏州城市规划和资源特点，全方位展示了苏州丰厚的产业资源和广阔的产业前景，并对苏州制定发布的一系列文化产业优惠政策进行说明，加大招商引资力度，吸引更多优质企业来苏州投资合作。',
                },
                {
                  content:
                    '作为有着2500多年建城史的历史文化名城，苏州是一座被文化浸润的城市，也是中国传统文化的代表性城市，拥有独特的江南文化资源优势。去年年初，苏州市成立了苏州市文化产业管理运营公司，同期设立了苏州文化产业投资基金。一年多来，苏州努力克服疫情等不利因素影响，稳步推进重点项目建设，不断推出新的文化阵地、产业项目、文艺精品，城市文化软实力和核心竞争力都得到了持续提升。',
                },
                {
                  content:
                    '当下，苏州正在抢抓数字经济时代产业创新集群建设的机遇，不仅要推动实现文化产业倍增，还将坚定不移地把文化产业打造成苏州的地标产业、支柱产业，不断开创文化产业发展“新蓝海”。目前，包括网易、大麦网等越来越多的文化企业将目光聚焦在苏州，笑果文化、开心麻花等知名文化企业已经选择在苏州落户，正是基于苏州良好的经济基础和文化底蕴，看好苏州文化产业的未来发展。',
                },
              ],
            },
          ],
        },
      ],
      policyData: [
        {
          id: 0,
          title: '我国三项文化产业政策利好近期将出台',
          detail:
            '由文化部等部门制定的三项支持文化产业发展的政策近期将陆续出台——《关于推进文化创意和设计服务与相关产业融合发展的若干意见》、《关于深入推进文化金融合作的意见》、《关于支援小微文化企业发展的意见》',
          src: policyImg4,
          detailTitle: '我国三项文化产业政策利好近期将出台',
          time: '2022-08-31 来源：中国包装网',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  content:
                    '据了解，由文化部等部门制定的三项支持文化产业发展的政策近期将陆续出台——《关于推进文化创意和设计服务与相关产业融合发展的若干意见》、《关于深入推进文化金融合作的意见》、《关于支援小微文化企业发展的意见》，传递出文化产业作为支撑和引领经济结构优化升级重要抓手的强烈信号。',
                },
                {
                  content:
                    '这些政策包括引导和鼓励社会资本投资文化创意、设计服务、资讯服务、体育、文化娱乐业等领域，完善相关扶持政策和金融服务，用好文化产业发展专项资金和税收减免，还涉及困扰企业多年的土地使用及煤、水、气、电价格等方面如何减负的问题。',
                },
                {
                  content:
                    '如《关于推进文化创意和设计服务与相关产业融合发展的若干意见》针对性强、含金量高，重在支持文化产业的“跨界融合”：一方面是促进文化产业与科技的融合，包括移动互联网在内的数字文化产业、动漫、手游等文化创意企业都将获得扶持;另一方面是促进文化产业与资本的融合，大规模资本运作将催生更多综合性文化产业集团;此外，还要促进文化产业与实体经济的融合，提升产业附加值，推动其它产业创新和转型升级。',
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: '文化和旅游部发布最新利好政策：关于推动数字文化产业高质量发展',
          detail:
            '对文化资源进行数字化转化和开发，让优秀文化资源借助数字技术“活起来”，将所蕴含的价值内容与数字技术的新形式新要素结合好，实现创造性转化和创新性发展',
          src: policyImg1,
          detailTitle:
            '文化和旅游部发布最新利好政策：关于推动数字文化产业高质量发展',
          time: '2020-12-15 15:06',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  content:
                    '据了解，由文化部等部门制定的三项支持文化产业发展的政11月26日，文化和旅游部发布《关于推动数字文化产业高质量发展的意见》（简称《意见》）提出发展目标：到2025年，培育20家社会效益和经济效益突出、创新能力强、具有国际影响力的领军企业，各具特色、活力强劲的中小微企业持续涌现，打造5个具有区域影响力、引领数字文化产业发展的产业集群，建设200个具有示范带动作用的数字文化产业项目。策近期将陆续出台——《关于推进文化创意和设计服务与相关产业融合发展的若干意见》、《关于深入推进文化金融合作的意见》、《关于支援小微文化企业发展的意见》，传递出文化产业作为支撑和引领经济结构优化升级重要抓手的强烈信号。',
                },
                {
                  content: '《意见》提出:',
                },
              ],
            },
            {
              id: 1,
              title: '促进优秀文化资源数字化',
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '对文化资源进行数字化转化和开发，让优秀文化资源借助数字技术“活起来”，将所蕴含的价值内容与数字技术的新形式新要素结合好，实现创造性转化和创新性发展。支持文化场馆、文娱场所、景区景点、街区园区开发数字化产品和服务，将创作、生产和传播等向云上拓展。支持文物、非物质文化遗产通过新媒体传播推广，鼓励线下文艺资源、文娱模式数字化，创新表现形式，深化文化内涵。鼓励依托地方特色文化资源，开发具有鲜明区域特点和民族特色的数字文化产品，助力扶贫开发。',
                },
              ],
            },
            {
              id: 3,
              title: '深化融合发展',
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '以数字化推动文化和旅游融合发展，实现更广范围、更深层次、更高水平融合。加强数字文化企业与互联网旅游企业对接合作，促进文化创意向旅游领域拓展。推进数字文化产业与先进制造业、消费品工业、智慧农业融合发展，与金融、物流、教育、体育、电子商务等现代服务业融合发展。发展品牌授权，提升制造业和服务业的品牌价值和文化价值。促进数字文化与社交电商、网络直播、短视频等在线新经济结合，发展旅游直播、旅游带货等线上内容生产新模式。推动数字文化产品和服务在公共文化场馆的应用，丰富公共文化空间体验形式和内容。',
                },
              ],
            },
            {
              id: 5,
              title: '发展平台经济',
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '深入推进“互联网+”，促进文化产业上线上云，加快传统线下业态数字化改造和转型升级，培育文化领域垂直电商供应链平台，形成数字经济新实体。鼓励各类电子商务平台开发文化服务功能和产品、举办文化消费活动，支持互联网企业打造数字精品内容创作和新兴数字文化资源传播平台，支持具备条件的文化企业平台化拓展，培育一批具有引领示范效应的平台企业。鼓励互联网平台企业与文化文物单位、旅游景区度假区合作，探索流量转化、体验付费、服务运营等新模式。引导“宅经济”健康发展，鼓励线上直播、有声产品、地理信息等服务新方式，发展基于知识传播、经验分享的创新平台。',
                },
              ],
            },
            {
              id: 7,
              img: require('@/assets/img/dynamic/dynamicDetail/detail23.png'),
            },
            {
              id: 8,
              detail: [
                {
                  content:
                    '吻途旅行作为中国领先的休闲旅游在线服务商，高品质旅游定制平台全球领航者，全球首创旅游管家服务。将在数字文化产业高质量发展上持续深耕，以优质的旅游管家服务模式，打造新品质、新旅游、新生态;以“让全球人的旅行更有保障”为使命，为消费者提供全国近1000多个城市出发的国内游、出境游、定制游等旅游产品预订服务，产品路线覆盖全球200多个国家，提供高品质、高性价比的服务，致力打造备受尊重的全球旅游品牌，同时提供全球最佳旅游创业平台，成为最受人尊敬的国际化企业。',
                },
              ],
            },
            {
              id: 9,
              img: require('@/assets/img/dynamic/dynamicDetail/detail24.png'),
            },
            {
              id: 10,
              detail: [
                {
                  content:
                    '吻途致力于打造一个专注于服务的互联网旅游定制平台，一切以客户体验为导向，秉承“让客户感动，让伙伴圆满，让员工成就”的企业宗旨，运用优质的管家服务模式，倾心打造个性化定制、纯玩无购物高品质高性价比的线路。想一切游客所想，做一切游客所做，严格的公司监管体系，全力保障顾客利益!独创旅游界分享式商业模式，玩的开心赚的痛快!让全球人的旅行更有保障。 只需带上身体与心灵，即刻出发。吻途，世界“游”你',
                },
              ],
            },
            {
              id: 11,
              img: require('@/assets/img/dynamic/dynamicDetail/detail25.png'),
            },
            {
              id: 12,
              detail: [
                {
                  content:
                    '2015年4月份推出以来，旅游线路受到了众多微信用户的喜爱，用户及粉丝数量呈爆炸式增长。吻途坚持“用心做服务，全心控质量”的理念，彻底铲除行业痛点，打造优质的旅游服务体验，让旅程成为快乐与赚钱的连体婴。品质商城：吻途品质商城是吻途国际旗下旅游电商平台，专注为广大旅游爱好者提供品质、口碑、性价比的各地特色产品、优质产品。致力将品质商城打造成中国旅游业中经销的品牌多、商品种类齐全的电子商务旅游直购商城。地方特色产品：分公司严格遵守吻途品质商城商品准入规则，整合当地高品质特色产品。会员优质产品：成为粉丝会员，既可一键代理上万种产品，也可成为吻途供应商，提供优质产品',
                },
                {
                  content:
                    '未来将会继续加快步伐落实完成发展战略，部署拓展全球品牌连锁旅行社，以更加优质的服务和产品吸引更多的粉丝加入到吻途大家庭。公司未来将继续拓展和优化公司产品和连锁店，积极发展电商和营运模式，巩固和提高公司在实体店及电商渠道的市场占有率;进一步完善信息化系统的应用，提高公司在设计研发、供应链管理及渠道建设等方面的综合能力。吻途会继续用感恩的心回报社会。',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: '政府工作报告透露“文化产业”八大利好',
          detail:
            '政府工作报告，是国家经济的发展大纲，总揽全局，视野宏阔，科学准确研判经济形势，明确提出当年经济工作目标要求、政策取向、工作方针和重点任务，为做好国民经济发展工作提供了有力指引',
          src: policyImg2,
          detailTitle: '政府工作报告透露“文化产业”八大利好',
          time: '2019-03-06 14:13:39 来源：文汇客户端',
          detailList: [
            {
              id: 0,
              img: require('@/assets/img/dynamic/dynamicDetail/detail26.png'),
            },
            {
              id: 1,
              title: '促进优秀文化资源数字化',
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '政府工作报告，是国家经济的发展大纲，总揽全局，视野宏阔，科学准确研判经济形势，明确提出当年经济工作目标要求、政策取向、工作方针和重点任务，为做好国民经济发展工作提供了有力指引。今年1.9万字的政府工作报告中，虽然只有两处直接谈到文化产业：一处是谈要加大“旅游产业”，另一处是要“推动文化事业和文化产业改革发展”，但是领会习近平总书记对文艺界、社科界政协委员的讲话后，学习政府工作报告的精神以后，学习中央对2019年经济形势的总体判断以后，能够看出文化产业发展的新趋势、新方向和新作为。“文化产业新视界”特整理了《从政府工作报告看2019年文化产业发展“八大利好”》，供业界人士参考。',
                },
                {
                  content: '1、文化产业为经济发展提供新动能。',
                },
                {
                  content: '2、文化产业要生产和传播优质文化内容。',
                },
                {
                  content: '3、文化消费助力高质量发展。',
                },
                {
                  content: '4、文化产业增加和促进就业。',
                },
                {
                  content: '5、文化企业减税降费，轻装前行。',
                },
                {
                  content: '6、支持小微文化企业茁壮成长。',
                },
                {
                  content: '7、加强互联网内容建设。',
                },
                {
                  content: '8、激发文化人才创造活力。',
                },
                {
                  content: '',
                },
              ],
            },
            {
              id: 3,
              title: '1、文化产业为经济发展提供新动能。',
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '李克强总理在政府报告中回顾了2018年的成绩，国内生产总值增长6.6%，总量突破90万亿元。但思危方能居安，要清醒看到我国发展面临的问题和挑战，2019年的环境更复杂更严峻，可以预料和难以预料的风险挑战更多更大：世界经济增速放缓，保护主义、单边主义加剧，国际大宗商品价格大幅波动，不稳定不确定因素明显增加，外部输入性风险上升；国内经济下行压力加大，消费增速减慢，有效投资增长乏力，要做好打硬仗的充分准备。2019年的国内生产总值增长下调到6%－6.5%，经济下行的压力很大，但我国发展仍处于重要战略机遇期，国家正在调整产业，重点发展新产业、新业态，培育新动能。',
                },
                {
                  content:
                    '文化产业以非物质文化资源为加工对象，为市场提供文化意义消费，对自然资源依赖程度低，需求弹性大，产品附加值高，价值链长，是典型的绿色经济。目前，文化产业与信息、旅游、体育、教育等国民经济部门正在发生普遍的渗透和融合，形成了以文化内容为纽带、关联度日益密切的庞大产业链和产业集群。文化产业高端领域的创意设计、电子商务，以及会展、广告等生产性服务业，与工业、农业日益融合发展，不断提升传统产业的内在价值、品牌、信息化和集约化水平，在推动经济发展、优化经济结构中发挥着越来越大的作用。文化产业要顺势而为，激发文化创新创造活力，既为国民经济发展提供新动能，又增强战胜困难的信心。',
                },
                {
                  content:
                    '比如，一部《流浪地球》，几乎没有多大的能源消耗和环保污染，却创造了超过45亿元的票房，是名副其实的社会效益、经济效益“双丰收”。近日， 青岛东方影都影视产业发展专项资金为《流浪地球》等四部影片发放制作成本补贴资金3600余万元，鼓励优秀影视作品在青岛制作，通过加大对影视产业的投入，凝聚起高端影视资源，打造世界级的影视产业基地和国际一流文化旅游项目，促进产业转型、改变城市定位。据悉，青岛市制定出台了《关于促进影视产业发展的若干意见》，由青岛市、西海岸新区和社会资本各出资50％设立总额50亿元的影视产业发展资金，对入驻青岛东方影都拍摄的优秀影视作品最高给予制作成本40％的补贴，大力发展影视新产业，培育发展新动能。',
                },
              ],
            },
            {
              id: 5,
              title: '2、文化产业要生产和传播优质文化内容。',
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '习近平总书记看望参加全国政协十三届二次会议的文化艺术界、社会科学界联组委员时，他强调新时代呼唤着杰出的文学家、艺术家、理论家，文艺创作、学术创新，拥有无比广阔的空间。要坚定文化自信，把握时代脉搏，聆听时代声音，坚持与时代同步，以人民为中心、以精品奉献人民，用明德引领风尚。文艺创作要勇于回答时代课题，从当代中国的伟大创造中发现创作的主题，捕捉创新的灵感，深刻反映我们这个时代的历史巨变，描绘我们这个时代的精神图谱，为时代画像、为时代立传，为时代明德。李克强总理在政府工作报告中也指出“培育和践行社会主义核心价值观，广泛开展群众性精神文明创建活动，大力弘扬奋斗精神、科学精神、劳模精神、工匠精神，汇聚起向上向善的强大力量”。',
                },
                {
                  content:
                    '好的作品需要好的传播，才会有广泛的受众，才能高扬主旋律、发出最强音，最大范围内弘扬正能量。因此，文化传播渠道和现代文化市场，就要让优质文化内容得到最广泛的传播，“培根铸魂”，把当代中国发展进步和当代中国人精彩生活表现好、展示好，把中国精神、中国价值、中国力量阐释好。',
                },
              ],
            },
            {
              id: 7,
              title: '3、文化消费助力高质量发展。',
            },
            {
              id: 8,
              detail: [
                {
                  content:
                    '政府工作报告中，指出要“充分发挥消费的基础作用、投资的关键作用，稳定国内有效需求，为经济平稳运行提供有力支撑“。其中特别强调“丰富人民群众精神文化生活”、 “发展壮大旅游产业”。',
                },
                {
                  content:
                    '人民群众追求美好生活的愿望十分强烈，存在满足巨大的文化消费需求和消费潜力。我国的消费模式已经从模仿型排浪式消费阶段全面转向个性化、多样化消费，文化消费通过提供多样化的文化产品和服务来满足人们精神文化的需求。2018年文化和旅游部门机构合并，为文化和旅游深度融合扫清体制机制障碍，有助于扩大文旅消费，激发内需。',
                },
                {
                  content:
                    '2018年国内旅游55.39亿人次，收入5.13万亿元，分别同比增长10.76%和12.3%。入境旅游1.41亿人次，国际旅游收入1271亿美元，分别同比增长1.2%和3.0%。文化能促进旅游产业的内涵式、创新型、特色化、体验式发展，旅游促进文化的创新、创意和创造，以文化人。这样，文旅的融合发展，实现资源和载体、内容与形式、休闲与体验的结合，推动业态创新，助力高质量发展，实现产业升级与消费升级。例如青海省黄南州重点扶持企业开发创意商品，开发文化旅游线路，举办特色文化活动，2016年至2018年旅游人次年平均增长25%，较文化消费试点前提高了10个百分点。',
                },
              ],
            },
            {
              id: 9,
              img: require('@/assets/img/dynamic/dynamicDetail/detail27.png'),
            },
            {
              id: 10,
              title: '4、文化产业增加和促进就业。',
            },
            {
              id: 11,
              detail: [
                {
                  content:
                    '“文化产业新视界”注意到，在今年的政府工作报告中，首次将“就业优先”政策置于宏观政策层面，旨在强化各方面重视就业、支持就业的导向。就业是民生之本、财富之源。今年当前和今后一个时期，我国就业总量压力不减、结构性矛盾凸显，新的影响因素还在增加，必须把就业摆在更加突出位置。2019年，“稳增长”首要是为“保就业”，就业优先政策要全面发力。今年城镇新增就业要在实现预期目标的基础上，力争达到近几年的实际规模，既保障城镇劳动力就业，也为农业富余劳动力转移就业留出空间。',
                },
                {
                  content:
                    '文化产业的行业和门类众多，产业链条长，就业容量大，就业形式灵活多样，从传统手艺者到互联网创业者，从非遗传承人到自由职业者都可以促进就业。尤其是作为知识经济和服务行业，文化产业特别适合大学生、研究生等知识阶层就业和创业。比如喜马拉雅FM是中国第一音频平台，拥有超过2.5亿手机用户，2000万车载、穿戴、音响智能设备用户，同时也吸引了6000多位有声自媒体大咖和400万有声主播投身音频微创业。因此，文化产业作为吸纳就业能力强的产业,不仅可以扩大就业数量,还可以提高就业质量。据有关专家估算，在北京多达数10万人的“北漂”中，有一半以上属于灵活就业的各类文化人和应届毕业的大学生、研究生。;进一步完善信息化系统的应用，提高公司在设计研发、供应链管理及渠道建设等方面的综合能力。吻途会继续用感恩的心回报社会。',
                },
              ],
            },
            {
              id: 12,
              title: '5、文化企业减税降费，轻装前行。',
            },
            {
              id: 13,
              detail: [
                {
                  content:
                    '“文化产业新视界”注意到，今年政府工作报告最大的亮点是大规模“减税降费”， 直击当前市场主体的痛点和难点，着眼“放水养鱼”、增强发展后劲并考虑财政可持续，推出减轻企业负担、激发市场活力的重大举措。全年减轻企业税收和社保缴费负担近2万亿元，是既公平又有效率的宏观政策，是支持稳增长、保就业、调结构的重大抉择，必将惠及广大文化企业。',
                },
                {
                  content:
                    '使企业社保缴费负担实质性下降：下调城镇职工基本养老保险单位缴费比例，各地可降至16%。稳定现行征缴方式，各地在征收体制改革过程中不得采取增加小微企业实际缴费负担的做法，不得自行对历史欠费进行集中清缴。今年务必使企业特别是小微企业社保缴费负担有实质性下降。既要减轻企业缴费负担，又要保障职工社保待遇不变、养老金合理增长并按时足额发放。',
                },
              ],
            },
            {
              id: 13,
              title: '6、支持小微文化企业茁壮成长。',
            },
            {
              id: 14,
              detail: [
                {
                  content:
                    '今年政府工作报告指出，要进一步把大众创业万众创新引向深入。鼓励更多社会主体创新创业，拓展经济社会发展空间，加强全方位服务，发挥双创示范基地带动作用。这有利于小微文化企业茁壮成长。',
                },
                {
                  content:
                    '政府工作报告要求普惠性减税与结构性减税并举，重点降低制造业和小微企业税收负担。小规模纳税企业提高起征点，从月销售额3万元提高到10万元等税收优惠政策，强化普惠性支持。引导金融机构扩大信贷投放、降低贷款成本，精准有效支持实体经济。加大对中小银行定向降准力度，释放的资金全部用于民营和小微企业贷款。',
                },
              ],
            },
            {
              id: 15,
              title: '7、加强互联网内容建设。',
            },
            {
              id: 16,
              detail: [
                {
                  content:
                    '政府工作报告指出，促进平台经济、共享经济健康成长；要深化大数据、人工智能等研发应用，培训新一代信息技术、高端装备、生物医药、新能源汽车、新材料等新兴产业集群，壮大数字经济。坚持包容审慎监管，支持新业态新模式发展，促进平台经济、共享经济健康成长。加快在各行业各领域推进“互联网+”。',
                },
                {
                  content:
                    '新技术革命为文化功能的扩展提供了新的手段，使得文化产品和服务的经济属性日益凸现，不断催生出网络服务、动漫游戏、数字媒体、手机视频等一系列新的文化业态，对经济发展的贡献率明显提高，必须加强互联网内容建设。今年持续推动网络提速降费，中小企业宽带平均资费再降低15%，移动网络流量平均资费再降低20%以上，在全国实行“携号转网”，规范套餐设置，使降费实实在在、消费者明明白白，也为数字文化内容生产提供广阔空间。',
                },
              ],
            },
            {
              id: 17,
              title: '8、激发文化人才创造活力。',
            },
            {
              id: 18,
              detail: [
                {
                  content:
                    '科技创新、文化创意本质上是人的创造性活动。因此，政府工作报告着力激发人才的创造活力。要求“改革完善人才培养、使用、评价机制，优化归国留学人员和外籍人才服务。把面向市场需求和弘扬人文精神结合起来，善聚善用各类人才”。',
                },
                {
                  content:
                    '报告指出，加大基础研究和应用基础研究支持力度，强化原始创新，加强关键核心技术攻关。健全以企业为主体的产学研一体化创新机制。扩大国际创新合作。全面加强知识产权保护，健全知识产权侵权惩罚性赔偿制度，促进发明创造和转化运用。要充分尊重和信任科研人员，赋予创新团队和领军人才更大的人财物支配权和技术路线决策权。进一步提高基础研究项目间接经费占比，开展项目经费使用“包干制”改革试点，不设科目比例限制，由科研团队自主决定使用。',
                },
                {
                  content: '来源：文化产业新视界公众号',
                },
                {
                  content: '作者：张玉玲',
                },
                {
                  content: '编辑：王筱丽',
                },
                {
                  content: '责任编辑：李婷',
                },
              ],
            },
          ],
        },
      ],
      carouselLeftList: [
        {
          id: 0,
          src: dynamicImg1,
          title: '热烈祝贺知悟在中国管理科学研究院的课题小组正式成立',
          detail:
            '共创未来，向智慧出发——知悟在中国管理科学研究院的课题小组正式成立！',
          detailTitle:
            '共创未来，向智慧出发——知悟在中国管理科学研究院的课题小组正式成立！',
          time: '2022-05-11 18:29 发表于江苏',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  id: 0,
                  content:
                    '近日，知悟经中国管理科学研究院教育标准化研究所学术委员会审定，知悟创始人何志强申报的课题《中国传统国学与互联网应用》，已经被列入中国管理科学研究院教育标准化研究所“十四五”规划重点课题',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail1.png'),
            },
            {
              id: 2,
              detail: [
                {
                  id: 0,
                  content:
                    '据悉，此次申报的研究课题组长由北大博雅教育研究院客座教授、中国管理科学研究院客座教授、知悟创始人何志强教授担任。课题成员分别为朱红柳、郑茜宁、宋国梁、徐小艳、王慧星、史子淼、董艳、赵俊皓、徐璘。',
                },
                {
                  id: 1,
                  content:
                    '中国管理科学研究院（简中管院）由科学家钱学森、钱三强、钱伟长和裴丽生、马洪等200多位高级科研人员发起，于1986年9月1日经陈云同志批示，在宋平等中央主要领导同志的关怀和支持下，1987年6月2日，经中国政府批准成立的国家事业单位。',
                },
                {
                  id: 2,
                  content:
                    '中国管理科学研究院是中国第一家经国家批准的专门从事管理科学和相关交叉科学研究的新型科研咨询机构。为国际管理学者协会联盟（IFSAM）的理事单位。“中国管理科学研究院颁发的证书是中国科学院推出的国家资质认证体系',
                },
              ],
            },
            {
              id: 3,
              detail: [
                {
                  id: 0,
                  content:
                    '未来，知悟将继续通过不断创新，以传统文化和互联网应用相结合为核心理念，让更多的国人在享受科技生活的同时真正把传统文化融入到日常中，打造一款国人生活指南的APP。',
                },
              ],
            },
            {
              id: 4,
              img: require('@/assets/img/dynamic/dynamicDetail/detail2.png'),
            },
          ],
        },
        {
          id: 1,
          title: '扬帆破浪！前进与合作的脚步永不停歇',
          detail:
            '知悟创始人何志强教授、北京真源诚传科技有限责任公司执行董事兼首席战略执行官郜殿革先生和总裁宋国梁先生，接受知悟平台顾问,生..',
          src: dynamicImg2,
          detailTitle: '扬帆破浪！前进与合作的脚步永不停歇',
          time: '',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  id: 0,
                  content:
                    '2022年4月30日，知悟创始人何志强教授、北京真源诚传科技有限责任公司执行董事兼首席战略执行官郜殿革先生和总裁宋国梁先生，接受知悟平台顾问，知本生物集团董事长石光吉先生的邀请，在四川省成都市大邑县知本集团驻地开展了商务洽谈，参观了集团驻地与生产车间。双方就品牌力量与实业+互联网的融合等话题，共叙合作情谊、共享发展经验，共谋发展大计。',
                },
                {
                  content:
                    '奇迹集团董事长齐光伟先生也出席了此次洽谈，知悟创始人何志强教授与其交换了下一步商务合作意向，并表示将进一步开展调研和探讨，推动合作早日取得成果。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail28.png'),
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    '知本集团是集房地产、医养、旅游产业、健康产业、智慧养老数据平台暨终端智能产品研发、生物科技制品（保健品、食品）研发、生产销售为一体的多元化企业。知本集团旗下四川知本生物工程有限公司，以保健食品、功能性养生食品、医疗器械、消毒产品的研发、制作、营销为主业的企业，目前规模居四川功能养生食品工业企业前列，属于大邑县重点培育上市的企业之一。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail29.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '在参观过程中，知本集团为何志强教授、郜殿革董事、宋国梁总裁等人展现了雄厚的实力，何志强教授表示，北京真源诚传科技有限责任公司，坚信品牌推动“国人生活便捷、文化传承”的力量，一直秉承着对“文化中国，健康中国”发展前景的深切关怀。',
                },
                {
                  content:
                    '这与知本生物集团保健食品与食品专业团队21年来不断致力于对人类疾病，免疫健康的研究精神，胸怀实业报国的豪情壮志不谋而合。',
                },
                {
                  content:
                    '因此，结合自身互联网+传承传统文化的战略优势，根据相关产业的发展实际，双方达成了初步合作愿景，就如何进一步做大做强“文化健康新生态”产业、中国智慧发展产业交换了许多宝贵的意见建议，对“中国梦”战略在北京真源诚传科技有限责任公司以及知本集团的践行拥有十分重要的意义。',
                },
              ],
            },
            {
              id: 5,
              img: require('@/assets/img/dynamic/dynamicDetail/detail30.png'),
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '最后，此次商务洽谈在和谐欢乐气氛中圆满落下帷幕，北京真源诚传科技有限责任公司携旗下“知悟”线上生活平台，将继续用传统文化+互联网的形式，打造中国式生活，让世界爱上中国，以服务国家战略为己任，带动全民共同富裕。',
                },
                {
                  content: '文化中国，健康中国的“舰队”再次壮大，继续扬帆破浪。',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: '传统文化进校园，在传承中焕发新生机',
          detail:
            '优秀的中华传统文化，不仅能够弘扬中华民族精神，是新时代文化理念的追求，同时也能形成更多元化、更易吸收的文化熏陶氛围。',
          src: dynamicImg3,
          detailTitle: '传统文化进校园，在传承中焕发新生机',
          time: '2022-05-13 17:27 发表于江苏',
          detailList: [
            {
              id: 0,
              detail: [
                {
                  id: 0,
                  content:
                    '优秀的中华传统文化，不仅能够弘扬中华民族精神，是新时代文化理念的追求，同时也能形成更多元化、更易吸收的文化熏陶氛围。这对于学生来说，能够为其树立爱国情怀、增强民族自豪感，树立积极的人生态度，有益于形成正确的三观。此外，也促使学校更加健康稳定、和谐长远的发展，培养出符合新时代要求的杰出学子。',
                },
                {
                  content:
                    '新时期，知悟平台也在校园全面推进优秀传统文化，对学生教育进行更科学有效的指导，2022年4月由北京真源诚传科技有限责任公司申请的中国关心下一代健康体育基金会·传统文化教育基金正式获得批复；由此专项基金发起的传统文化进校园公益活动也被正式纳入“中国校园健康行动”。',
                },
              ],
            },
            {
              id: 1,
              img: require('@/assets/img/dynamic/dynamicDetail/detail31.png'),
            },
            {
              id: 2,
              detail: [
                {
                  content:
                    ' “中国校园健康行动”是由中国关工委、教育部、司法部、文化部、卫生部、农业部、国家质检总局、国家广电总局、国家体育总局、国家安监总局、国家食品药品监督管理局、国务院法制办等十二部委联合发起的大型公益事业。',
                },
              ],
            },
            {
              id: 3,
              img: require('@/assets/img/dynamic/dynamicDetail/detail33.png'),
            },
            {
              id: 4,
              detail: [
                {
                  content:
                    '在参观过程中，知本集团为何志强教授、郜殿革董事、宋国梁总裁等人展现了雄厚的实力，何志强教授表示，北京真源诚传科技有限责任公司，坚信品牌推动“国人生活便捷、文化传承”的力量，一直秉承着对“文化中国，健康中国”发展前景的深切关怀。',
                },
                {
                  content:
                    '这与知本生物集团保健食品与食品专业团队21年来不断致力于对人类疾病，免疫健康的研究精神，胸怀实业报国的豪情壮志不谋而合。',
                },
                {
                  content:
                    '因此，结合自身互联网+传承传统文化的战略优势，根据相关产业的发展实际，双方达成了初步合作愿景，就如何进一步做大做强“文化健康新生态”产业、中国智慧发展产业交换了许多宝贵的意见建议，对“中国梦”战略在北京真源诚传科技有限责任公司以及知本集团的践行拥有十分重要的意义。',
                },
              ],
            },
            {
              id: 6,
              detail: [
                {
                  content:
                    '“中国校园健康行动—学生健康安全教育行动”是一项通过开展一系列素质教育活动，净化校园环境，引导学生健康成长的公益事业，也是一项伴随全国亿万学生“生命安全，健康成长”的关爱工程，对保护学生生命安全，促进学生素质教育，打造和谐校园，构建和谐社会具有深远意义。',
                },
              ],
            },
            {
              id: 7,
              title: '一、传统文化进校园的的意义',
            },
            {
              id: 8,
              detail: [
                {
                  content: '1. 建立民族情感，培育民族精神',
                },
                {
                  content:
                    '传统文化是一个民族的特色，也是民族的思想观念表达。传统文化进校园有利于维系民族情感、传达民族精神及民族价值。',
                },
                {
                  content: '2. 解决学生面临的难题，践行社会主义核心价值观',
                },
                {
                  content:
                    '现代社会主义核心价值观拥有很多传统中华文化遗传下来的精神内涵，包括和谐、民主、文明，建设祖国富强等等。传统文化进校园，有利于优秀传统文化的继承、运用，建设与发展。',
                },
                {
                  content:
                    '为促进多样化文化教育活动，开展传统文化教学，将价值观植入学生心中，从而不断内化学生思维品行，使其道德素养得到提升，能够自主、自发的弘扬中华文化，践行社会主义核心价值观念。知悟还专门成立了“传统文化知悟教育实践基地”，方便广大学子在实践中更好的学习传统文化，感受传统文化的魅力。',
                },
              ],
            },
            {
              id: 9,
              img: require('@/assets/img/dynamic/dynamicDetail/detail34.png'),
            },
            {
              id: 10,
              title: '二、传统文化进校园的影响',
            },
            {
              id: 11,
              detail: [
                {
                  content:
                    '加强传统文化研究，推动校园文化建设，知悟平台通过思研传统文化，充实自身的智囊库，为校园文化精神建设奠定科学依据。',
                },
                {
                  content:
                    '知悟利用互联网思维构建传统文化学习氛围，积极吸纳先进技术，以开放的姿态充分有效利用互联网思维，搭建更完善的教育平台。让本土优秀文化通过知悟app获得更为广泛的传播，增强学科教育的时效性，加强互动感，将传统文化与时代融合。',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    //鼠标移入切换图片
    changeImg(index) {
      this.imgFlag = index
    },
    //移动端点击事件
    changeImg2(index) {
      this.imgFlag = index
    },
    //切换新闻的导航
    changeNav(id) {
      console.log(id);
      this.navBar = id
      if (id == 0) {
        this.contentData = this.zhiWuDynamic
        this.newDetail = true
      } else if (id == 1) {
        this.contentData = this.industryNews
        this.newDetail = false
      } else {
        this.contentData = this.policyData
        this.newDetail = false
      }
    },
    // 跳转新闻详情
    toNewDetail(item) {
      this.$router.push('/dynamic/newDetail?id=' + item.id)

      // let detail = JSON.stringify(item)
      // this.$router.push({
      //   path: '/dynamic/DynamicNewDetail',
      //   query:{
      //     id:item.id
      //   }
      // })
      console.log(item.id)
      // sessionStorage.setItem('zhiWuDetail', detail)
    },
    // 跳转新闻详情
    toDetail(item) {
      let detail = JSON.stringify(item)
      this.$router.push('/dynamic/detail?')
      sessionStorage.setItem('zhiWuDetail', detail)
    },
    //加载更多
    loadMore() {
      var height = this.$refs.mainDiv.offsetHeight
      console.log('初始盒子高度', height)
      let data = [
        {
          id: 0,
          src: news4,
          title: '战略合作是北京真源诚传的一小步，也是健康中国的一大步',
          detail:
            '2022年4月24日，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导与知美口腔医院达',
        },
        {
          id: 1,
          src: news1,
          title: '铿锵前行7位世界冠军共祝北京真源诚传蒸蒸日上',
          detail:
            '2022年4月24日，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导与知美口腔医院达',
        },
        {
          id: 2,
          src: news2,
          title: '用经典浸润童年，让智慧点亮人生',
          detail:
            '中华优秀传统文化是中华民族的文化根脉，其蕴含的思想观念、人文精神、道德规范，不仅是我们中国人思想和精神的内核，对解决人类问题',
        },
        {
          id: 8,
          src: news3,
          title: '战略合作是北京真源诚传的一小步，也是健康中国的一大步',
          detail:
            '2022年4月24日，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导与知美口腔医院达',
        },
        {
          id: 9,
          src: news4,
          title: '铿锵前行7位世界冠军共祝北京真源诚传蒸蒸日上',
          detail:
            '2022年4月24日，何志强教授率领北京真源诚传科技有限责任公司团队总裁宋国梁、执行董事兼销售总监徐云汐等高层领导与知美口腔医院达',
        },
        {
          id: 10,
          src: news1,
          title: '用经典浸润童年，让智慧点亮人生',
          detail:
            '中华优秀传统文化是中华民族的文化根脉，其蕴含的思想观念、人文精神、道德规范，不仅是我们中国人思想和精神的内核，对解决人类问题',
        },
      ]
      this.contentData = [...data, ...this.contentData]
      setTimeout(() => {
        var height = this.$refs.mainDiv.offsetHeight
        console.log('盒子高度', height)
        this.$refs.mainDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 1000)
    },
    // 分类列表
    getList() {
      getdata(this.$url + '/system/dynamic/lists', {
        websiteCategoryId: '0',
      }).then((ok) => {
        this.listData = ok.data.data
      })
    },
    // 分类名称
    gitMenu() {
      getLink(this.$url + 'system/dynamic/category')
        .then((ok) => {
          if (ok.data.code == 200) {
            this.navTitle = ok.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.contentData = this.zhiWuDynamic
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: 'wow', ///动画元件css类（默认为wow）
        animateClass: 'animated', //动画css类（默认为animated）
        offset: 300, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: false, //对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
</script>

<style scoped lang="less">
.dynamic-wrapper {
  width: 100%;
  background: #f9f9f9;
  .top-carousel-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .left-words-item {
      height: 100%;
      width: 580px;
      background: #fff;
      border: 1px solid #eeeeee;
      padding-bottom: 20px;
      box-sizing: border-box;
      cursor: pointer;
      .words-wrap {
        padding: 29px 20px 0px 0;
        box-sizing: border-box;
        text-align: left;
        display: flex;
        justify-content: space-between;
        .left-border {
          border-left: 2px solid #fff;
          margin-left: 2px;
        }
        .left-border-active {
          border-left: 2px solid #c2a16c;
          margin-left: 2px;
        }
        .right-tips {
          margin-left: 20px;
          border-bottom: 1px solid #eeeeee;
          .title {
            color: #333;
            font-size: 20px;
            font-weight: bold;
          }
          .title-active {
            color: #c2a16c;
          }
          .detail {
            color: #666666;
            font-size: 16px;
            margin: 19px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; // 想要超出三行显示... 就把这里改成3就好了
          }
        }
      }
    }
    .right-image-item {
      width: 517px;
      background: #f0f0f0;
      margin-top: -36px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px 0 30px;
      box-sizing: border-box;
      .img-box {
        width: 477px;
        display: flex;
        background: #fff;
        justify-content: center;
        padding: 10px 0 30px 0px;
        box-sizing: border-box;
        margin-bottom: 20px;
        img {
          display: block;
          width: 460px;
          height: 400px;
        }
      }
      .title {
      }
    }
  }
  .news-wrapper {
    max-width: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 70px;
    box-sizing: border-box;
    z-index: 99;
    .title-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #54462f;
      font-size: 28px;
      .title {
        margin-top: 9px;
        font-weight: bold;
      }
      .english {
        font-size: 14px;
        margin-top: -6px;
      }
      .title-img {
        width: 317px;
        height: 260px;
        position: absolute;
        left: 0;
        top: -20px;
        z-index: -1;
      }
      .bg-img {
        width: 638px;
        height: 151px;
        position: absolute;
        top: 20px;
        // bottom:0;
        left: 0;
        right: 0;
        margin: auto;
        // z-index:-1;
      }
    }
    .content-wrapper {
      max-width: 1140px;
      padding: 20px;
      box-sizing: border-box;
      .nav-wrapper {
        height: 80px;
        text-align: left;
        cursor: pointer;
        .nav-words {
          height: auto;
          padding-bottom: 10px;
          box-sizing: border-box;
          margin-right: 50px;
          border-bottom: 2px solid #fcf8fb;
          font-size: 24px;
        }
        .nav-words:last-child {
          margin-right: 0;
        }
        .nav-words-active {
          font-size: 28px;
          color: #c2a16c;
          border-bottom: 2px solid #c2a16c;
        }
      }
      .main-content-wrapper {
        .main-wrapper {
          display: flex;
          margin-top: 20px;
          cursor: pointer;
          .img-box {
            width: 330px;
            height: 220px;
            img {
              display: block;
              width: 330px;
              height: 220px;
            }
          }
          .content {
            padding: 20px 40px;
            box-sizing: border-box;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
              font-size: 20px;
              text-align: left;
              color: #333;
              font-weight: bold;
            }
            /deep/ .detail p img {
              width: 100%;
            }
            ::v-deep .detail {
              font-size: 16px;
              color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; // 想要超出三行显示... 就把这里改成3就好了
              // ::v-deep p{
              //   img{
              //   width: 100%;
              // }
              // }
            }
            .button {
              width: 80px;
              height: 30px;
              background: #c2a16c;
              color: #fff;
              text-align: center;
              line-height: 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .boat {
      width: 462px;
      height: 267px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .mountain {
      width: 500px;
      height: 500px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
    }
  }
  .load-more {
    width: 132px;
    height: 46px;
    background: url('@/assets/img/dynamic/loadingMore.png') no-repeat;
    background-size: 132px;
    margin: 48px 0 74px;
    cursor: pointer;
  }
}
.dynamic-wrapper
  .top-carousel-wrapper
  .left-words-item
  .words-wrap:last-child
  .right-tips {
  border-bottom: none;
}
@media only screen and (max-width: 767px) {
  .news-wrapper {
    padding-top: 30px !important;
  }
  .dynamic-wrapper .news-wrapper .content-wrapper .nav-wrapper .nav-words {
    font-size: 16px;
  }
  .dynamic-wrapper .top-carousel-wrapper {
    padding: 0 20px;
  }
  .main-content-wrapper .main-wrapper {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  .dynamic-wrapper .top-carousel-wrapper .right-image-item {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
  .dynamic-wrapper .top-carousel-wrapper .right-image-item .img-box {
    width: 100%;
    height: auto;
  }
  .dynamic-wrapper .top-carousel-wrapper .right-image-item .img-box img {
    width: 100%;
    height: auto;
  }
  .button {
    margin-top: 20px;
  }
  .hide-class {
    display: none;
  }
}
</style>
