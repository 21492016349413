import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import qs from 'qs'
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs

Vue.prototype.$url = 'https://devapi.new.zhiwuapp.cn/japi/' // Java测试

Vue.prototype.$url = 'https://prd.zhiwuapp.cn/japi/' // Java正式


import "./assets/font/font.css";

// 引入animate.css动画
import 'animate.css'
import 'wowjs/css/libs/animate.css'
// 引入、bootstrap
import {BootstrapVue,BootstrapVueIcons} from "bootstrap-vue";
Vue.use(BootstrapVue).use(BootstrapVueIcons);

import VuePrlx from 'vue-prlx'
Vue.use(VuePrlx);

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  loading: require('@/assets/img/loading.gif')  //此图片路径为懒加载的默认图，小伙伴根据实际情况更改路径
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
