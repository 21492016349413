<template>
  <div class="characterDeeds">
    <h2>人物事迹</h2>
    <div>
      <p>
        由于何志强在国学智慧方面突出贡献，于2020年6月被北京大学元培智库中华学委会特聘为国学研究课题客座教授；2020年10月28日，被中国管理科学研究院特聘为客座教授（国学智慧）。
      </p>
      
      <p>
        除了在传统文化和国学智慧领域贡献突出，何志强教授在时代前沿的智慧产业发展中亦有所建树，担任中国智慧产业发展研究院核心职务。中国智慧产业发展研究院是中国智慧产业最具权威性的学术经营实体之一，创立于2010年12月，以中国知识经济、创意产业、文化产业等专业“高端服务业”学术研究领域、市场研发与咨询策划、服务工作。中国智慧产业发展研究院核心成员：中国智慧产业发展研究院常委会执行主席中国智慧产业发展研究院执行院长/著名学者冯峻灏，中国智慧产业发展研究院专家委员何志强、徐小艳、宋国良、张公仆、董艳等人。
      </p>
      <p>
        2022年，经中国管理科学研究院教育标准化研究所研究，同意设立由何志强教授担任课题组组长，由朱红柳、郑茜宁、宋国梁、徐小艳、王慧星、史子森、董艳、赵俊皓、徐璘组成的中国管理科学研究院教育标准化研究所中国传统国学与互联网应用课题组。
      </p>
      <p>
        2017年，创立了北京真源诚传科技有限责任公司。期间，何教授通过“易学文化空中课堂”、“轻模式文化发展目标”等传统文化传承项目累积沉淀成功经验，并联合同频人士搭建理念框架，组建技术团队，搭建「知悟」APP模式与服务架构。
      </p>
      <p>2018年2月，何志强教授积极筹备中国传统文化进校园活动，为助力青少年群体研习中国传统文化，“易学文化空中课堂服务”公益项目正式启动，结合时代条件对中国传统文化加以继承和发扬，赋予中国传统文化新的涵义。</p>
      <p>2022年「知悟」APP正式推出，以传承中华文脉、探索中国传统文化在新时代的新应用为主要宗旨，业务覆盖文化、教育、医疗、旅游、购物等各个生活场景，实现“互联网+”的全方位交融，打造中国人的特色生活指南。</p>
      <p>2017年，何志强教授联合同频人士一道，设计了一个全新的服务平台理念框架，并组建技术和运营团队，历经5年时间，于2022年推出了一款包容中国传统智慧与特色的现代人智慧生活指南APP——「知悟」。知悟平台的业务覆盖文化、教育、医疗、旅游、购物等各个生活场景，利用全方位的“互联网+”生态，融合传统文化与时代科技，开拓全新的生活理念生态圈，以图用传统文化智慧结晶，覆盖服务中国人衣食住行的全维度生活场景，打造具有全新高度的商业与生活服务生态。</p>
      <p>作为何志强教授首款主导设计的服务平台类产品，「知悟」是文化与生活的融媒，也是文明生态理想与现实生活的全新纽带。它不仅带有浓厚的理想与人文色彩，更在人们日常的衣食住行及迫切关切的领域解决着实际的问题，让人们享受智慧生活带来的便利。</p>
      <p>何志强教授自幼研习中国传统文化，潜研数十载，在中国传统文化的传承与发展方面获得了众多专研机构的嘉奖与认可。他由于在国学智慧方面突出贡献，2020年10月28日，被中国管理科学研究院特聘为客座教授（国学智慧），同时，亦担任北京大学元培智库中华学委会客座教授、中国人民大学客座教授。其中，何教授在中国管理科学研究院教育标准化研究所申报的课题《中国传统国学与互联网应用》被列入中国管理科学研究院教育标准化研究“十四五”规划重点课题。</p>
      <p>何教授以对道家文化精髓与中国传统文化思想的精深理解，总结延生出一套基于东方传统智慧的现代经营与管理理论，并先后以战略顾问等身份为多个上市公司注入新的企业智慧与生命力，更帮助了无数中小企业从生死存亡的困局中谋得新的生机与发展方向，如今担任多家上市公司的持股顾问和首席战略执行官。</p>
      <p>2021年12月，何教授申报的课题《中国传统国学与互联网应用》，被列入中国管理科学研究院教育标准化研究所“十四五”规划重点课题。</p>
      <p>何志强教授把自己与公益慈善紧密相连，先后帮助了大小救灾筹备款项与物资的捐赠，革命老区，空巢老人，失学儿童都是他的帮助人群，并且以自己的行为影响着身边的人一同发正心正念。</p>
      <p>几十年来，何教授积极投身传统文化传承和公益教育的事业中。2022年4月28日，何教授牵头申请的“中国关心下一代健康体育基金会·传统文化教育基金”正式获得批复，并受到了党和国家及相关部委领导的高度重视和关怀。此外，何教授亲身践行“传统文化进校园”行动，开展文化教学，持续内化学生思维品行，让广大学子在实践中学习传统文化，感受传统文化的魅力。</p>
      <p>何志强教授认为：中华民族传统文化是中国几千年智慧的结晶。近年来，国际竞争形势越来越激烈，文化软实力的竞争已成为国际竞争的核心之一。而在现代社会中，传统文化经过新一代人的发展，正在逐渐展现出其强大的生命力和文明价值。</p>
      <p>现在，全球数字化发展，为中国文化走向世界开启了第三次系统化传播的窗口。何志强教授认为这正是中国文化征服世界的新契机。基于此种想法，他开始构思如何使用现代化的信息传播方式与技术手段，让久被“束之高阁”的道学与传统文化走进人们的日常生活，为社会带来实实在在的便利。因此，他组织资源与团队，开发并推出了“知悟”综合性生活服务平台，为传统文化融入生活找到了路径，也为为文化出海开凿更高远的空间。</p>
      <p>2022年4月11日，“中国关心下一代健康体育基金会”正式成立传统文化教育专项基金，何志强教授担任专项基金秘书长，旨在加强下一代传统文化道德教育和美德教育的培养，培养文化传承的新力量。</p>
     
    </div>
  </div>
</template>

<script>
export default {
   created(){
    document.body.scrollTop = document.documentElement.scrollTop = this.scrollTop;
  },
}
</script>

<style lang='less' scoped>
.characterDeeds {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2{
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    font-size: 24px;
    font-weight: bold;
  }
  p{
    line-height: 30px;
  }
}
</style>