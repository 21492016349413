<!-- <template>
  <div class="scrollTop" @click="goTop">
    <img class="topImg" src="../../assets/img/top.png" alt="" />
  </div>
</template>

<script>

export default {
  name: 'ScrollTop',

  mounted() {
  },
  methods: {
    goTop() {
    let top = document.documentElement.scrollTop || document.body.scrollTop
    // 实现滚动效果
    const timeTop = setInterval(() => {
      document.body.scrollTop = document.documentElement.scrollTop = top -= 50
      if (top <= 0) {
        clearInterval(timeTop)
      }
    }, 10)
  }
  },
}
</script>
<style lang="less" scoped>
.scrollTop {
}

.topImg {
  width: 74px;
  height: 74px;
  position: fixed;
  right: 30px;
  top: 700px;
  cursor: pointer;
}
</style> -->

<template>
  <div id="goTop" @click="goTop" v-show="goTopShow">
    <img class="topImg" src="../../assets/img/top.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "backTop",
  data() {
    return {
      scrollTop: '',
      goTopShow: false,
    }
  },
  created() {
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 150) {//设置页面滑动多少显示按钮
        this.goTopShow = true
      } else {
        this.goTopShow = false;
      }
    },
    goTop() {
      let timer = null,
        _that = this;
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 50;//回到顶部的速度
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="less">
// 手机端
@media only screen and (max-width: 768px) {
  .topImg {
    width: 23px;
    top: 500px !important;
  }
}
// 手机端 640
@media only screen and (max-width: 638px) {
  .topImg {
    width: 37px !important;
    top: 450px !important;
  }
}

.goTop:hover {
  background: rgba(0, 0, 0, .85);
}

.topImg {
  width: 47px;
  /* height: 74px; */
  position: fixed;
  right: 30px;
  top: 700px;
  cursor: pointer;
  z-index: 999;
}

.toTop {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  opacity: .8;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  right: 10px;
  color: #409eff;
  font-size: 20px;
  cursor: pointer;
  z-index: 999;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
}
</style>

