<template>
  <div class="honorsAndPositionsDetail">
    <h2>荣誉和职务</h2>
    <p>2023年8月，获得名人百科中国影响力人物数据库非遗文化传播大使、美业文化传播大使荣誉；</p>
    <h2>担任职务：</h2>
    <p>主要职务</p>
    <p>北京真源诚传科技有限责任公司 副总裁 兼生态事业部总经理</p>
    <p>知悟（北京真源诚传科技有限责任公司）旗下灵玥康体系统创始人</p>
    <p>社会职务</p>
    <p>中国智慧产业发展研究院传统文化研究中心研究员；</p>
    <p>诸夏周易研究院中华面部风水企划师；</p>
    <p>关心下一代儿童健康体育基金传统文化专项基金副主任；</p>
    <p>全通布道文化复兴产业联盟副主席；</p>
    <p>中国管理科学研究院创业导师；</p>
    <p>中非共和国驻华大使馆国学文化顾问秘书长。</p>
    <p>决策中国专家智库联盟 创业导师</p>
    <p>利比里亚共和国驻华大使馆特邀两性心理学顾问</p>
    <p>中国管理科学研究院 客座教授</p>
    <p>华夏国学教育研究院 秘书长</p>
    <p>中慈民族医药研究院 秘书长</p>
    <p>中联美业逆龄抗衰医学研究院 院长</p>
    <p>北大博雅研究院高级营养师</p>
  </div>
</template>

<script>
export default {
  created() {
    document.body.scrollTop = document.documentElement.scrollTop =
      this.scrollTop
  },
}
</script>

<style lang='less' scoped>
.honorsAndPositionsDetail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  h6 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    line-height: 30px;
  }
}
</style>