<template>
  <!-- 头部导航 -->
  <div>
    <div class="headerTop" id="headerTopSmall">
      <div class="header-wrap">
        <img class="logo" src="@/assets/img/logo.png" />
        <div class="blank-box"></div>
        <div class="words-wrap">
          <div v-for="(item, index) in navData" :key="index" class="font-wrap"
            :id="activeFont == index ? 'active-font-wrap' : ''" @click="toPage(index)">
            <div class="header-font bold-words header-font-border"
              :id="activeFont == index ? 'active-header-font-border' : ''">{{ item.name }}</div>
            <div class="header-font english" :style="(index == 1 || index == 2) ? 'transform:scale(0.9)' : ''">
              {{ item.englishTitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderCom',
  components: {
  },
  data() {
    return {
      navData: [
        {
          id: 0,
          name: "首页",
          englishTitle: "HOME"
        }, {
          id: 1,
          name: "产品",
          englishTitle: "PRODUCT"
        }, {
          id: 2,
          name: "动态",
          englishTitle: "DYNAMIC"
        }, {
          id: 3,
          name: "关于",
          englishTitle: "ABOUT"
        }
      ],
      activeFont: 0
    }
  },
  props: {
    navFlag: String
  },
  methods: {
    toPage(event) {
      this.activeFont = event;
      switch (event) {
        case 0:
          this.$router.push('/')
          break;
        case 1:
          this.$router.push('/product')
          break;
        case 2:
          this.$router.push('/dynamic')
          break;
        case 3:
          this.$router.push('/about')
          break;
      }
    },
    toNav(name) {
      switch (name) {
        case 'index':
          this.activeFont = 0;
          break;
        case 'product':
          this.activeFont = 1;
          break;
        case 'dynamic':
          this.activeFont = 2;
          break;
        case 'about':
          this.activeFont = 3;
          break;
        case 'detail':
          this.activeFont = 2;
          break;
      }
    }
  },
  watch: {
    navFlag(oldVal) {
      let name = oldVal;
      this.toNav(name);
    }
  },
  mounted() {
    let name = this.$route.name;
    this.toNav(name);
  },

}
</script>
<style lang="less">
#active-font-wrap {
  color: #C2A16C;
}

.headerTop {
  height: 158px;
  display: flex;
  background: #F9F9F9;
  align-items: center;
  justify-content: center;
  background: url("@/assets/img/headerBg.png") no-repeat;
  background-size: 100%;

  .header-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100px;
      height: 100px;
    }

    .words-wrap {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    .blank-box {
      width: 100px;
    }
  }

  .font-wrap {
    display: flex;
    color: #676767;
    cursor: pointer;

    .bold-words {
      font-weight: bold;
    }

    .header-font {
      font-size: 24px;
      writing-mode: vertical-lr;
      text-align: center;
    }

    .english {
      font-size: 16px;
    }

    .header-font-border {
      border-right: 1px solid #676767;
      padding-right: 3px;
      margin-right: 2px;
    }

    #active-header-font-border {
      border-right: 1px solid #C2A16C;
    }

    .hide-class {
      display: none;
    }
  }
}

//pad端

//手机端
@media only screen and (max-width: 767px) {
  .headerTop {
    width: 100%;
    height: 100px;
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 9998;
    // background: #F9F9F9;
    .header-wrap {
      width: 100%;

      .words-wrap {
        width: calc(100% - 100px);
        display: flex;
        flex: 0;
        justify-content: space-between;
        .font-wrap{
          margin-right: 20px;
        }
      }

      .logo {
        width: 65px;
        height: 65px;
      }

      .blank-box {
        width: 0;
      }

      .header-font {
        font-size: 18px;
        writing-mode: vertical-lr;
        text-align: center;
      }
    }
  }
}
</style>