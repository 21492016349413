<template>
    <div id="product-wrap">
        <div class="banner-box">
            <div class="banner-wrap">
                <!-- 知悟 -->
                <div class="banner-inner-box" v-show="componentsFlag == 0">
                    <div class="banner-inner-left-box">
                        <span class="wow fadeInUp" data-wow-delay="800" data-wow-duration="1s">科技引领创新，服务智慧生活</span>
                        <img class="words wow fadeInUp" data-wow-delay="1500" data-wow-duration="2s"
                            src="@/assets/img/product/bannerWords.png" />
                        <div class="code-box wow fadeInUp">
                            <div class="single-code" style="margin-right:27px;">
                                <div class="img">
                                    <vue-qr :text="zhiWuAndroid" :size="82" :margin="0"></vue-qr>
                                </div>
                                <div class="title">知悟安卓下载</div>
                            </div>
                            <div class="single-code">
                                <div class="img">
                                    <vue-qr :text="zhiWuIos" :size="82" :margin="0"></vue-qr>
                                </div>
                                <div class="title">知悟iOS下载</div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-inner-right-box zhiwu">
                        <img src="@/assets/vedios/zhiwu.gif" />
                        <!-- <video class="comVideo" loop autoplay muted webkit-playsinline="true" playsinline="true">
                            <source src="@/assets/vedios/zhiwu.mp4" type="video/mp4">
                        </video> -->
                    </div>
                </div>

                <!-- 悟道师 -->
                <div class="banner-inner-box" v-show="componentsFlag == 1">
                    <div class="banner-inner-left-box">
                        <span class="wow fadeInUp" data-wow-delay="800" data-wow-duration="1s">科技引领创新，服务智慧生活</span>
                        <img class="words wow fadeInUp" data-wow-delay="1500" data-wow-duration="2s"
                            src="@/assets/img/product/bannerWords.png" />
                        <div class="code-box wow fadeInUp">
                            <div class="single-code" style="border:1px solid #5489A8;margin-right:27px;"
                                @mouseleave="hideBigCode(2)" @mouseenter="toBigCode(2)">
                                <div class="img">
                                    <vue-qr :text="wuDaoShiAndroid" :size="82" :margin="0"></vue-qr>
                                </div>
                                <div class="title" style="background:#5489A8;">悟道师安卓下载</div>
                            </div>
                            <div class="single-code" style="border:1px solid #5489A8;" @mouseleave="hideBigCode(3)"
                                @mouseenter="toBigCode(3)">
                                <div class="img">
                                    <vue-qr :text="wuDaoShiIos" :size="82" :margin="0"></vue-qr>
                                </div>
                                <div class="title" style="background:#5489A8;">悟道师iOS下载</div>
                            </div>
                            <div class="big-code" v-show="showOrHideFlag">
                                <vue-qr :text="zhiWuCode" :size="150" :margin="0"></vue-qr>
                            </div>
                        </div>
                    </div>
                    <div class="banner-inner-right-box ppc-gif">
                        <img class="wudaoGif" src="@/assets/vedios/wudaoshi.gif" alt="" />
                        <!-- <video class="comVideo" loop autoplay muted webkit-playsinline="true" playsinline="true">
                            <source src="@/assets/vedios/wudaoshi.mp4" type="video/mp4">
                        </video> -->
                    </div>
                </div>
                <div class="chang-box">
                    <div :class="componentsFlag == 0 ? 'active' : ''" style="margin-right:16px;" @click="changeBox(0)">
                    </div>
                    <div :class="componentsFlag == 1 ? 'active' : ''" @click="changeBox(1)"></div>
                </div>
            </div>
            <!-- 知悟 -->
            <div class="banner-words-wrap wow fadeInUp" data-wow-delay="1000" data-wow-duration="1s"
                v-show="componentsFlag == 0">
                <div class="title">
                    <span>知</span>
                    <span>悟</span>
                </div>
                <div class="description">
                    知悟秉承着传承和弘扬中国传统文化的理念，协同自身互联网“智享”的平台优势，结合完备的<span>传统文化知识库</span>，借力互联网飞速发展，从全面提升用户生活品质角度出发，基于<span>物联网、大数据和人工智能</span>等科技手段，实现知悟云技术平台与客户端的互联，通过品牌、用户、文化创作者等多元化主体的网络化价值链的交互协作，逐步建立「知悟」云智慧生活生态圈。实现<span>全场景智慧生活体验服务</span>，通过大数据算法为用户适配个性化生活服务，不断迭代升级知悟生活生态圈，从文化领域出发，深度结合时代科技，引领国人生活方式新趋势，创造兼具中国特色的元宇宙“智享”生活。
                </div>
            </div>
            <!-- 悟道师 -->
            <div class="banner-words-wrap wow fadeInUp" data-wow-delay="1000" data-wow-duration="1s"
                v-show="componentsFlag == 1">
                <div class="title">
                    <span>悟</span>
                    <span>道</span>
                    <span>师</span>
                </div>
                <div class="description change-desc">
                    文化是一个国家、一个民族的灵魂，文化兴国运兴，文化强民族强。悟道师APP始终坚守中华文化立场，发展中国特色社会主义文化，不断铸就中华文化
                    新辉煌。悟道师APP是悟道师针对<span>用户咨询订单、个人资料、个人动态、碎银提现</span>等功能进行管理的平台。APP功能轻便、简洁，旨在为广大悟道师提供更便捷的用户咨询订单处理服务。悟道师APP利用互联网+技术，通过资源整合的方式，打通<span>线上一对一沟通渠道，线上咨询订单智能推送、一键处理订单状态、掌握收益动态。</span>诸夏文化研究院4000名国学大师在线互动，悟道师可通过平台打造个人品牌和口碑，为不断铸就中华文化新辉煌提供坚强保障。
                </div>
            </div>
        </div>
        <!-- 手机端 -->
        <div class="phone-prd">
            <div class="pp-flag">
                <button :class="flag == 0 ? 'ppFlagBtnAct' : 'ppFlagBtn'" @click="goTab(0)">知悟APP</button>
                <button :class="flag == 1 ? 'ppFlagBtnAct' : 'ppFlagBtn'" @click="goTab(1)">悟道师App</button>
            </div>
            <!-- 知悟端 -->
            <div class="ppc-container" v-show="flag == 0">
                <div class="ppc-desc">科技引领创新，服务智慧生活</div>
                <img class="ppc-title" src="@/assets/img/product/bannerWords.png" />
                <div class="ppc-download">
                    <button @click="whiwuAn()">知悟安卓下载</button>
                    <button @click="whiwuiOs()">知悟iOS下载</button>
                </div>
                <div class="ppc-gif">
                    <img class="zhiwuGif" src="@/assets/vedios/zhiwu.gif" alt="">
                    <!-- <video class="ppc-comVideo" loop autoplay muted webkit-playsinline="true" playsinline="true">
                        <source src="@/assets/vedios/zhiwu.mp4" type="video/mp4">
                    </video> -->
                </div>
            </div>
            <!-- 悟道师端 -->
            <div class="ppc-container" v-show="flag == 1">
                <div class="ppc-desc">科技引领创新，服务智慧生活</div>
                <img class="ppc-title" src="@/assets/img/product/bannerWords.png" />
                <div class="ppc-download">
                    <button @click="wudaoAn()">悟道师安卓下载</button>
                    <button @click="wudaoiOs()">悟道师iOS下载</button>
                </div>
                <div>
                    <video class="ppc-comVideo" loop autoplay muted webkit-playsinline="true" playsinline="true">
                        <source src="@/assets/vedios/wudaoshi.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>

        <div class="descZhiwu" v-show="flag == 0">

            <div class="description">
                <div class="descTitle" style="color: #C2A16C;">知悟</div>
                秉承着传承和弘扬中国传统文化的理念，协同自身互联网“智享”的平台优势，结合完备的<span>传统文化知识库</span>，借力互联网飞速发展，从全面提升用户生活品质角度出发，基于<span>物联网、大数据和人工智能</span>等科技手段，实现知悟云技术平台与客户端的互联，通过品牌、用户、文化创作者等多元化主体的网络化价值链的交互协作，逐步建立「知悟」云智慧生活生态圈。实现<span>全场景智慧生活体验服务</span>，通过大数据算法为用户适配个性化生活服务，不断迭代升级知悟生活生态圈，从文化领域出发，深度结合时代科技，引领国人生活方式新趋势，创造兼具中国特色的元宇宙“智享”生活。
            </div>
            <div class="descBg"></div>
        </div>

        <div class="descZhiwu" v-show="flag == 1">

            <div class="description change-desc">
                <div class="descTitle" style="color: #5489A8;">悟道师</div>
                文化是一个国家、一个民族的灵魂，文化兴国运兴，文化强民族强。悟道师APP始终坚守中华文化立场，发展中国特色社会主义文化，不断铸就中华文化
                新辉煌。悟道师APP是悟道师针对<span>用户咨询订单、个人资料、个人动态、碎银提现</span>等功能进行管理的平台。APP功能轻便、简洁，旨在为广大悟道师提供更便捷的用户咨询订单处理服务。悟道师APP利用互联网+技术，通过资源整合的方式，打通<span>线上一对一沟通渠道，线上咨询订单智能推送、一键处理订单状态、掌握收益动态。</span>诸夏文化研究院4000名国学大师在线互动，悟道师可通过平台打造个人品牌和口碑，为不断铸就中华文化新辉煌提供坚强保障。
            </div>
            <div class="descBg"></div>
        </div>
        <!-- 要切换的内容 -->
        <div class="function-introduction-wrap">
            <!-- 头部 -->
            <div class="title-wrap" style="margin:93px 0 80px;">
                <img class="title-img" src="@/assets/img/one.png" />
                <div class="title">功能介绍</div>
                <div class="english">Function Introduction</div>
            </div>

            <!-- 切换组件 -->
            <FunctionIntro v-show="componentsFlag == 0" />
            <FunctionIntroTwo v-show="componentsFlag == 1" />
        </div>

        <!-- 展望未来 -->
        <LookingAhead />

        <!-- 上知悟，智慧生活早知悟 -->
        <div class="bottom-bg-box">
            <img src="@/assets/img/product/bottom-words.png" alt="">
        </div>
        <div class="bottom-bg-box2">
            <img src="@/assets/img/mobileWords.png" />
        </div>
        <ScrollTop />
    </div>
</template>

<script>
import { WOW } from 'wowjs'
import vueQr from 'vue-qr'
import FunctionIntro from "@/components/productCom/FunctionIntro"
import FunctionIntroTwo from "@/components/productCom/FunctionIntroTwo"
import LookingAhead from "@/components/productCom/LookingAhead"
import ScrollTop from "@/components/scrollTop/ScrollTop.vue"
export default {
    name: "ProductView",
    components: {
        FunctionIntro,
        FunctionIntroTwo,
        LookingAhead,
        vueQr,
        ScrollTop,
    },
    data() {
        return {
            zhiWuIos: "https://apps.apple.com/cn/app/%E7%9F%A5%E6%82%9F-%E4%B8%AD%E5%9B%BD%E4%BA%BA%E7%9A%84%E7%94%9F%E6%B4%BB%E6%8C%87%E5%8D%97/id1622508421",
            zhiWuAndroid: "https://prd.zhiwuapp.cn/index/index/version/type/0/app/android/jump/1",
            zhiWuCode: "https://prd.zhiwuapp.cn/qrcode/build?text=https://prd.zhiwuapp.cn/index/index/version/type/0/app/android/jump/1",
            wuDaoShiIos: "https://apps.apple.com/cn/app/%E6%82%9F%E9%81%93%E5%B8%88-%E5%BC%98%E6%89%AC%E4%BC%A0%E7%BB%9F%E6%96%87%E5%8C%96-%E4%BC%A0%E6%89%BF%E5%9B%BD%E5%AD%A6%E6%99%BA%E6%85%A7/id1634717710",
            wuDaoShiAndroid: "https://www.pgyer.com/K9TI",
            componentsFlag: 0,
            showOrHideFlag: false,
            flag: 0,
        }
    },
    methods: {
        // 下载iOS悟道师
        wudaoiOs() {
            window.location.href = "https://apps.apple.com/cn/app/%E6%82%9F%E9%81%93%E5%B8%88-%E5%BC%98%E6%89%AC%E4%BC%A0%E7%BB%9F%E6%96%87%E5%8C%96-%E4%BC%A0%E6%89%BF%E5%9B%BD%E5%AD%A6%E6%99%BA%E6%85%A7/id1634717710"
        },
        // 下载iOS知悟
        whiwuiOs() {
            window.location.href = "https://apps.apple.com/cn/app/%E7%9F%A5%E6%82%9F-%E4%B8%AD%E5%9B%BD%E4%BA%BA%E7%9A%84%E7%94%9F%E6%B4%BB%E6%8C%87%E5%8D%97/id1622508421"
        },
        // 下载安卓知悟
        whiwuAn() {
            window.location.href = "https://prd.zhiwuapp.cn/index/index/version/type/0/app/android/jump/1"
        },
        // 下载安卓悟道师
        wudaoAn() {
            window.location.href = "https://www.pgyer.com/K9TI"
        },
        goTab(index) {
            this.flag = index
        },
        //点击切换内容
        changeBox(index) {
            this.componentsFlag = index;
            if (index == 1 || index == 0) {
                this.$nextTick(() => {
                    let wow = new WOW({
                        boxClass: "wow", ///动画元件css类（默认为wow）
                        animateClass: "animated", //动画css类（默认为animated）
                        offset: 100, //到元素距离触发动画（当默认为0）
                        mobile: true, //在移动设备上触发动画（默认为true）
                        live: false, //对异步加载的内容进行操作（默认为true）
                    })
                    wow.init()
                })
            }
        },
    },
    created() {

    },

    mounted() {
        this.Timer = setInterval(() => {
            this.componentsFlag = !this.componentsFlag;
        }, 8000)
        this.$nextTick(() => {
            let wow = new WOW({
                boxClass: "wow", ///动画元件css类（默认为wow）
                animateClass: "animated", //动画css类（默认为animated）
                offset: 300, //到元素距离触发动画（当默认为0）
                mobile: true, //在移动设备上触发动画（默认为true）
                live: false, //对异步加载的内容进行操作（默认为true）
            })
            wow.init()
        })
    },
    beforeDestroy() {
        clearInterval(this.Timer);
    },
}
</script>

<style scoped lang="less">
.descZhiwu {
    display: none;
}

.bottom-bg-box2 {
    display: none;
}

.phone-prd {
    display: none;
}

#product-wrap {
    width: 100%;
    background: #FFFEFF;
}

.banner-box {
    width: 100%;

    .banner-words-wrap {
        width: 1200px;
        height: 236px;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 19px 0px #F4F4F4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -118px;
        padding: 0 50px 0 53px;
        box-sizing: border-box;

        .title {
            font-size: 36px;
            color: #C2A16C;
            text-align: center;
            width: 65px;
            height: 140px;
            border: 1px solid #C2A16C;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                margin-top: -14px;
            }

            span:first-child {
                margin-top: 0;
            }
        }

        .description {
            width: 958px;
            font-size: 16px;
            color: #55534F;

            span {
                color: #C2A16C;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .change-desc span {
            color: #5489A8
        }

    }
}

.banner-wrap {
    width: 100%;
    height: 646px;
    background: url("@/assets/img/product/bannerBg.png") no-repeat left 0;
    background-size: 100% 646px;
    padding-top: 40px;
    box-sizing: border-box;

    .banner-inner-box {
        width: 1200px;
        height: 455px;
        // background:red;
        // height:auto;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 0 51px;
        box-sizing: border-box;
        position: relative;

        .banner-inner-left-box {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;

            span {
                font-size: 20px;
                color: #C2A16C;
            }

            .words {
                width: 423px;
                height: 61px;
                margin-top: 27px;
            }

            .code-box {
                width: 243px;
                display: flex;
                margin-top: 42px;
                position: relative;

                .single-code {
                    width: 108px;
                    height: 132px;
                    background: #F3F3F3;
                    border: 1px solid #C2A16C;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    .img {
                        display: block;
                        width: 82px;
                        height: 82px;
                        margin-top: 9px;
                    }

                    .title {
                        width: 100%;
                        text-align: center;
                        padding: 6px 0px;
                        box-sizing: border-box;
                        background: #C2A16C;
                        color: #fff;
                        font-size: 14px;
                    }
                }

                .big-code {
                    width: 150px;
                    height: 150px;
                    position: absolute;
                    bottom: -160px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        .banner-inner-right-box {
            width: 491px;
            height: 455px;
            overflow: hidden;
            position: absolute;
            right: 0;
            bottom: -34px;

            img {
                width: 80%;
            }

            .comVideo {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .comVideo:focus {
                outline: none;
            }

        }
    }

    .chang-box {
        width: 300px;
        height: 3px;
        margin: 9px auto 0;
        display: flex;

        div {
            cursor: pointer;
            width: 67px;
            height: 3px;
            background: #000;
            opacity: 0.2;
        }

        .active {
            background: #C2A16C;
            opacity: 1;
        }
    }
}

.function-introduction-wrap {
    width: 100%;

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #54462F;
        font-size: 28px;

        .title {
            margin-top: 9px;
            font-weight: bold;
        }

        .english {
            font-size: 14px;
            margin-top: -6px
        }

        .title-img {
            width: 42px;
            height: 52px;
        }
    }
}

.bottom-bg-box {
    width: 100%;
    height: 280px;
    background: url("@/assets/img/product/bottom-bg.png") no-repeat left 0;
    background-size: 100% 280px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        width: 644px;
        height: 94px;
    }
}


// pad端
@media only screen and (min-width: 769px) and(max-width: 1199px) {}

// 手机端
@media only screen and (max-width: 768px) {
    .ppc-gif{
        width: 100%;
        text-align: center;
        img{
            width: 75%;
        }
    }
    // .wudaoGif {
    //     width: 92%;
    // }
    // .zhiwuGif {
    //     width: 92%;
    // }

    .bottom-bg-box {
        display: none;
    }

    .bottom-bg-box2 {
        width: 100%;
        display: block;

        img {
            width: 100%;
        }
    }

    .banner-box {
        display: none;
    }

    .phone-prd {
        width: 100%;
        height: 570px;
        background: url('../assets/img/product/phone-prd.png') no-repeat;
        background-size: cover;
        display: block !important;

        .pp-flag {
            width: 100%;
            text-align: center;
            padding-top: 25px;

            .ppFlagBtn {
                width: 100px;
                height: 30px;
                line-height: 30px;
                white-space: nowrap;
                color: #54462F;
                font-size: 15px;
                font-weight: bold;
                border: 1px solid #54462F;
                border-radius: 30px;
                background: none;
            }

            .ppFlagBtnAct {
                width: 100px;
                height: 30px;
                color: #fff;
                font-size: 15px;
                font-weight: bold;
                border-radius: 30px;
                background: #C2A16C;
                white-space: nowrap;
            }

            button {
                border: none;
                margin-right: 10px;
            }
        }

        .ppc-container {
            width: 100%;
            text-align: center;

            .ppc-desc {
                color: #C2A16C;
                font-size: 14px;
                font-weight: bold;
                margin: 35px 0 15px;
            }

            .ppc-title {
                width: 282px;
            }

            .ppc-download {
                display: flex;
                margin: 20px 0 15px;
                justify-content: center;

                button {
                    white-space: nowrap;
                    border: none;
                    width: 108px;
                    height: 30px;
                    color: #FFFFFF;
                    font-size: 12px;
                    background: #C2A16C;
                    border: 1px solid #C2A16C;
                    border-radius: 5px;
                    margin-right: 10px;
                }
            }

            .ppc-comVideo {
                width: 70%;
            }
        }
    }

    .descZhiwu {
        padding-top: 50px;
        width: 100%;
        background: #F9F9F9;
        display: inline-block;

        .descBg {
            width: 100%;
            height: 90px;
            background: #C2A16C;
            margin-top: -45px;
        }
    }

    .description {
        width: 90%;
        margin: 0 auto;
        font-size: 16px;
        color: #55534F;
        background: white;
        padding: 20px 20px 45px;
        line-height: 33px;
        position: relative;



        .descTitle {

            font-size: 28px;
            white-space: nowrap;
            display: inline;
            font-weight: bold;

        }

        span {
            color: #C2A16C;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .change-desc span {
        color: #5489A8
    }

}
</style>