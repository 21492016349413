<template>
  <div>
    
    <div style="width:100%;">
      <HeaderCom :navFlag="navFlag" />
      <div id="content">
        <router-view/>
      </div>
      <FooterCom/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCom from '@/components/footerCom/NewHeaderCom.vue'
import FooterCom from '@/components/footerCom/FooterCom.vue'
export default {
  name: 'HomeView',
  components: {
    HeaderCom,
    FooterCom,
  },
  data(){
    return {
      isShow:true,
      navFlag:null
    }
  },
  mounted(){
    
  },
  watch: {
    $route: {
        handler: function(val){
            this.navFlag = val.name;
        },
        // 深度观察监听
        deep: true
    }
  },
}
</script>
<style lang="less">
  
</style>
